import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../../../themes/const';

export const usePageTitleStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: theme.spacing(5, 0),
  },
  goBackWrap: {
    marginRight: theme.spacing(3),
  },
  title: {
    fontFamily: FONTS.medium,
    fontWeight: 500,
  },
}));
