import { makeStyles } from 'tss-react/mui';

export const useValidatorStyles = makeStyles()(theme => ({
  container: {
    marginTop: theme.spacing(20),
    paddingBottom: theme.spacing(55),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(15),
      paddingBottom: theme.spacing(30),
    },
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(10),
  },
  card: {
    padding: theme.spacing(9, 10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(9, 6),
    },
  },
  listTitle: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(4),
    },
  },
  copyBtn: {
    fontSize: 18,
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
}));
