import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { API_ENDPOINT } from '../common/const';

export const stakeApi = createApi({
  refetchOnMountOrArgChange: true,
  reducerPath: 'stakeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_ENDPOINT,
  }),
  endpoints: () => ({}),
});
