import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';

import { useGetChainDataQuery } from '../../../../../../../Bas/actions/bas';
import { IValidator } from '../../../../../../../Bas/models';
import {
  AppList,
  IAppListColumn,
} from '../../../../../../../common/components/AppList';
import { useCurrentNetwork } from '../../../../../../../common/hooks/useCurrentNetwork';
import { truncateWalletAddress } from '../../../../../../../common/utils/truncateWalletAddress';
import { t } from '../../../../../../../i18n/utils/intl';
import { useLocaleMemo } from '../../../../../../../i18n/utils/useLocaleMemo';
import { Stake } from '../../../../../../../Staking/components/Stake';
import { Unstake } from '../../../../../../../Staking/components/Unstake';
import { useIsMDDown } from '../../../../../../../themes/hooks/useTheme';
import { Button } from '../../../../../../../uiKit/Button';
import { CopyButton } from '../../../../../../../uiKit/CopyButton';
import { ValidatorRoutesConfig } from '../../../../../../../Validator/ValidatorRoutes';
import { usePendingValidatorsStyles } from './usePendingValidatorsStyles';

export function PendingValidators() {
  const { classes } = usePendingValidatorsStyles();

  const isMDDown = useIsMDDown();

  const { currentNetwork: network } = useCurrentNetwork();

  const [stakeValidator, setStakeValidator] = useState<string | null>();
  const [unstakeValidator, setUnstakeValidator] = useState<IValidator | null>();

  const { data: chainData, isLoading: isChainDataLoading } =
    useGetChainDataQuery();

  const handleStakeClick = useCallback((v: IValidator) => {
    setStakeValidator(v.address);
  }, []);

  const handleStakeClose = useCallback(() => {
    setStakeValidator(null);
  }, []);

  const handleUnstakeClick = useCallback((v: IValidator) => {
    setUnstakeValidator(v);
  }, []);

  const handleUnstakeClose = useCallback(() => {
    setUnstakeValidator(null);
  }, []);

  const columns: IAppListColumn<IValidator>[] = useLocaleMemo(
    () => [
      {
        header: <Box>{t('dashboard.validator')}</Box>,
        renderCell: item => (
          <>
            <Tooltip title={item.address}>
              <Link
                to={ValidatorRoutesConfig.Validator.generatePath({
                  address: item.address,
                  network,
                })}
              >
                {truncateWalletAddress(item.address)}
              </Link>
            </Tooltip>
            <CopyButton text={item.address} />
          </>
        ),
      },
      {
        header: <Box>{t('dashboard.total-staked')}</Box>,
        renderCell: item =>
          `${t('number.compact-fixed-0', {
            value: item.totalStakedNomination,
          })} (${t('number.percent-fixed-2', { value: item.stakedShare })})`,
      },
      {
        header: <Box>{t('dashboard.commission')}</Box>,
        renderCell: item =>
          t('number.percent-string', { value: item.commissionRate }),
      },
      {
        header: null,
        cellClassName: classes.toolbarCell,
        renderCell: item =>
          item.delegatedAmount ? (
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleUnstakeClick(item)}
                className={classes.toolbarBtn}
              >
                {t('common.unstake')}
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleStakeClick(item)}
                className={classes.toolbarBtn}
              >
                {t('common.stake-more')}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              size="small"
              onClick={() => handleStakeClick(item)}
              className={classes.toolbarBtn}
            >
              {t('common.stake')}
            </Button>
          ),
      },
    ],
    [classes, handleStakeClick, isMDDown, network],
  );

  return (
    <>
      <AppList
        items={chainData?.pendingValidators || []}
        isLoading={isChainDataLoading}
        columns={columns}
      />
      {stakeValidator && (
        <Stake onClose={handleStakeClose} validator={stakeValidator} />
      )}
      {unstakeValidator && (
        <Unstake onClose={handleUnstakeClose} validator={unstakeValidator} />
      )}
    </>
  );
}
