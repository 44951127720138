import React from 'react';
import { Tooltip } from '@mui/material';

import { UserAvatar } from '../../../uiKit/UserAvatar';
import { Network, useCurrentNetwork } from '../../hooks/useCurrentNetwork';
import { ReactComponent as DogeChainIcon } from '../Icons/DogeChainIcon.svg';
import { ReactComponent as MetaApesIcon } from '../Icons/MetaApesIcon.svg';
import { ReactComponent as MultiverseIcon } from '../Icons/MultiverseIcon.svg';
import { useNetworkNameStyles } from './useNetworkNameStyles';

interface INetworkNameProps {
  inlineView?: boolean;
  withoutText?: boolean;
  className?: string;
  avatarClassName?: string;
}

export function NetworkName({
  inlineView,
  withoutText,
  className,
  avatarClassName,
}: INetworkNameProps) {
  const { classes, cx } = useNetworkNameStyles();

  const { currentNetwork, currentNetworkName } = useCurrentNetwork();

  let icon: JSX.Element | null = null;

  if (currentNetwork) {
    switch (currentNetwork) {
      case Network.metaapes:
        icon = <MetaApesIcon className={cx(classes.avatar, avatarClassName)} />;
        break;
      case Network.dogechain:
        icon = (
          <DogeChainIcon className={cx(classes.avatar, avatarClassName)} />
        );
        break;
      case Network.multiverse:
        icon = (
          <MultiverseIcon className={cx(classes.avatar, avatarClassName)} />
        );
        break;
      default:
        icon = (
          <UserAvatar
            name={currentNetworkName}
            symbolsNum={1}
            className={cx(classes.avatar, avatarClassName)}
          />
        );
        break;
    }
  }

  return (
    <Tooltip title={withoutText ? currentNetworkName : ''}>
      <div
        className={cx(
          classes.root,
          className,
          inlineView && classes.inlineView,
        )}
      >
        {icon}
        {!withoutText && currentNetworkName}
      </div>
    </Tooltip>
  );
}
