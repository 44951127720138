import BigNumber from 'bignumber.js';

import {
  IDelegator,
  IDelegatorList,
  IStakeAsset,
  IStakeAssetList,
} from '../common/models';
import { truncateWalletAddress } from '../common/utils/truncateWalletAddress';
import {
  IApiDelegator,
  IApiDelegatorList,
  IApiStakeAsset,
  IApiStakeAssetList,
} from './models';

export const mapDelegator = (origin: IApiDelegator): IDelegator => ({
  ...origin,
  amount: new BigNumber(origin.amount ?? 0),
});

export const mapDelegatorList = (origin: IApiDelegatorList): IDelegatorList => {
  return {
    items: origin.delegators.map(mapDelegator),
  };
};

export const mapStakeAsset = (origin: IApiStakeAsset): IStakeAsset => ({
  ...origin,
  truncatedValidator: truncateWalletAddress(origin.validator),
});

export const mapStakeAssetList = (
  origin: IApiStakeAssetList,
): IStakeAssetList => {
  return {
    items: origin.map(mapStakeAsset),
  };
};
