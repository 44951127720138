import React, { useMemo } from 'react';
import { uid } from 'react-uid';
import { Box, Paper, Typography } from '@mui/material';

import { UiTag } from './UiTag';
import { useUiTagsStyles } from './useUiTagsStyles';

export function UiTags() {
  const { classes } = useUiTagsStyles();

  const tagList = useMemo(
    () => [
      { label: '# cryptogoesdown' },
      { label: '# general' },
      { label: '# stocks' },
      { label: '# investing' },
      { label: '# bitcoin', isActive: true },
      { label: '# trading' },
      { label: '# disabled', isDisabled: true },
      { label: '# disabledActive', isActive: true, isDisabled: true },
    ],
    [],
  );

  return (
    <Box mt={5} mb={40}>
      <Typography variant="h1">Tags</Typography>
      <hr />
      <Paper sx={{ padding: 5 }}>
        <ul className={classes.list}>
          {tagList.map(item => (
            <li key={uid(item)}>
              <UiTag
                label={item.label}
                isActive={item.isActive}
                isDisabled={item.isDisabled}
              />
            </li>
          ))}
        </ul>
      </Paper>
    </Box>
  );
}
