import * as React from 'react';

import { ReactComponent as SpinnerIcon } from './assets/spinner.svg';
import { useSpinnerStyles } from './useSpinnerStyles';

interface ISpinnerProps {
  centered?: boolean;
  size?: number;
  className?: string;
}

export function Spinner({
  centered = false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  size,
  className,
}: ISpinnerProps) {
  const { classes, cx } = useSpinnerStyles();

  return (
    <SpinnerIcon
      className={cx(classes.spinner, centered && classes.centered, className)}
    />
  );
}
