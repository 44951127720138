import { alpha } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { Palette } from '@mui/material/styles/createPalette';

import { FONTS, MAX_LAYOUT_WIDTH, MIN_LAYOUT_WIDTH } from '../const';
import { defaultTheme } from '../mainTheme';

export const getThemeComponents = (palette: Palette): Components => ({
  MuiCssBaseline: {
    styleOverrides: `
        html, body, #root {
          min-width: ${MIN_LAYOUT_WIDTH}px;
          height: 100%;
        }
        html {
          overflow-x: hidden;
          overflow-y: scroll;
        }
        body {
          font-size: 16px;
          color: ${palette.text.primary};
          @media (max-width: ${defaultTheme.breakpoints.values.md}px) {
            font-size: 14px;
          }
        }
        body > iframe {
          display: none;
          visibility: hidden;
          pointer-events: none;
          opacity: 0;
        }
        a {
          color: ${palette.link.main};
          font-size: inherit;
          text-decoration: none;
          transition: color ${defaultTheme.transitions.duration.short}ms;
        }
        strong, b {
          font-family: ${FONTS.semiBold};
        }
        hr {
          border-color: ${palette.divider}
        }
        h1, h2, h3, h4, h5, h6 {
          line-height: 1.25;
        }
        h1 {
          font-family: ${FONTS.semiBold};
          font-weight: 600;
          font-size: 60px;
        }
        h2 {
          font-family: ${FONTS.semiBold};
          font-weight: 600;
          font-size: 40px;
        }
        h3 {
          font-family: ${FONTS.medium};
          font-weight: 500;
          font-size: 36px;
        }
        h4 {
          font-family: ${FONTS.medium};
          font-weight: 500;
          font-size: 24px;
        }
        h5 {
          font-family: ${FONTS.medium};
          font-weight: 500;
          font-size: 14px;
        }
        h6 {
          font-family: ${FONTS.semiBold};
          font-weight: 600;
          font-size: 14px;
        }
        input[type=number] {
          -moz-appearance: textfield;
        }
        input:-webkit-autofill {
          background-color: transparent !important;
          color: ${palette.text.primary};
          border-radius: 0;
          -webkit-box-shadow: none !important;
          -webkit-text-fill-color: ${palette.text.primary};
          caret-color: ${palette.text.primary};
          transition: background-color 99999999s;
        }
        ::-webkit-outer-spin-button,
        ::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }
        ::-webkit-search-decoration,
        ::-webkit-search-cancel-button,
        ::-webkit-search-results-button,
        ::-webkit-search-results-decoration {
          display: none;
        }
        ::selection {
          background-color: ${palette.primary.main};
          color: ${palette.primary.contrastText};
          text-shadow: none;
          -webkit-text-fill-color: ${palette.primary.contrastText};
        }
        ::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }
        ::-webkit-scrollbar-corner {
          background: transparent;
        }
        ::-webkit-scrollbar-track {
          background: transparent;
          margin: 1px;
          border-radius: 10px;
        }
        ::-webkit-scrollbar-track:hover {
          background: ${alpha(palette.grey[500], 0.5)};
        }
        ::-webkit-scrollbar-thumb {
          background: ${alpha(palette.grey[700], 0.5)};
          border-radius: 10px;
          transition: background-color 0.2s;
        }
        ::-webkit-scrollbar-thumb:hover {
          background: ${alpha(palette.grey[700], 0.7)};
        }
        ::placeholder {
          color: ${palette.text.disabled};
        }
        blockquote {
          border-left: 2px solid #ddd;
          margin-left: 0;
          margin-right: 0;
          padding-left: 10px;
          color: #aaa;
          font-style: italic;
        }
        .MuiDataGrid-root {
          min-width: 800px;
        }
        .MuiDataGrid-root,
        .MuiDataGrid-root .MuiDataGrid-cell,
        .MuiDataGrid-root .MuiDataGrid-columnHeaders,
        .MuiDataGrid-root .MuiDataGrid-footerContainer {
          border: none;
        }
        .MuiDataGrid-root .MuiDataGrid-columnSeparator {
          display: none;
        }
        .MuiDataGrid-root .MuiDataGrid-columnHeaders {
          background-color: ${palette.divider};
          color: ${palette.text.disabled};
          border-radius: 24px;
        }
        .MuiDataGrid-root .MuiDataGrid-cell,
        .MuiDataGrid-root .MuiDataGrid-columnHeader {
          padding: ${defaultTheme.spacing(0, 6)};
        }
        .MuiDataGrid-root .MuiDataGrid-cell:focus,
        .MuiDataGrid-root .MuiDataGrid-columnHeader:focus {
          outline: none;
        }
        .MuiDataGrid-root .MuiDataGrid-row {
          border-radius: 24px;
        }
        .MuiDataGrid-root .MuiDataGrid-row:hover {
          background-color: ${alpha(palette.grey[300], 0.3)};
        }
        `,
  },

  MuiContainer: {
    defaultProps: {
      maxWidth: false,
    },
    styleOverrides: {
      root: {
        '&&': {
          position: 'static',
          paddingLeft: defaultTheme.spacing(30),
          paddingRight: defaultTheme.spacing(30),
          maxWidth: MAX_LAYOUT_WIDTH,
          [defaultTheme.breakpoints.down('lg')]: {
            paddingLeft: defaultTheme.spacing(25),
            paddingRight: defaultTheme.spacing(25),
          },
          [defaultTheme.breakpoints.down('md')]: {
            paddingLeft: defaultTheme.spacing(20),
            paddingRight: defaultTheme.spacing(20),
          },
          [defaultTheme.breakpoints.down('sm')]: {
            paddingLeft: defaultTheme.spacing(5),
            paddingRight: defaultTheme.spacing(5),
          },
        },
      },
    },
  },

  MuiSnackbar: {
    defaultProps: {
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    },
    styleOverrides: {
      root: {
        maxWidth: MIN_LAYOUT_WIDTH,
        '& > .MuiPaper-root': {
          borderRadius: 12,
        },
      },
    },
  },

  MuiTooltip: {
    defaultProps: {
      arrow: true,
      placement: 'top',
      enterDelay: 500,
      enterNextDelay: 500,
    },
    styleOverrides: {
      tooltip: {
        fontFamily: FONTS.medium,
        fontWeight: 500,
        backgroundColor: palette.grey[300],
        color: palette.text.primary,
        padding: defaultTheme.spacing(3),
        textAlign: 'center',
        borderRadius: 24,
      },
      popper: {
        pointerEvents: 'none',
      },
      arrow: {
        color: palette.grey[300],
      },
    },
  },

  MuiPaper: {
    defaultProps: {
      elevation: 0,
    },
    styleOverrides: {
      root: {
        backgroundColor: palette.background.paper,
        borderRadius: 12,
        '&::-webkit-scrollbar-track': {
          marginTop: 6,
          marginBottom: 6,
        },
      },
    },
  },

  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 18,
      },
    },
  },

  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        '&&.Mui-disabled': {
          color: palette.text.disabled,
        },
        '& > svg': {
          fontSize: 'inherit',
          color: 'inherit',
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      variant: 'contained',
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
        borderRadius: 12,
        fontSize: 16,
        fontFamily: FONTS.semiBold,
        fontWeight: 600,
        padding: defaultTheme.spacing(2, 10),
        minHeight: 40,
      },
      sizeSmall: {
        fontSize: 14,
        minHeight: 32,
        padding: defaultTheme.spacing(0.5, 4),
        borderRadius: 6,
      },
      sizeLarge: {
        fontSize: 16,
        minHeight: 48,
        padding: defaultTheme.spacing(2, 12),
      },
      contained: {
        color: palette.primary.contrastText,
        backgroundColor: palette.primary.dark,
        '&:hover': {
          backgroundColor: palette.primary.light,
        },
      },
      containedSecondary: {
        border: '2px solid',
        borderColor: palette.text.primary,
        backgroundColor: palette.text.primary,
        color: palette.background.default,
        '&:hover': {
          backgroundColor: palette.primary.main,
          color: palette.primary.contrastText,
        },
        '&.Mui-disabled': {
          borderColor: 'transparent',
        },
      },
      containedInherit: {
        backgroundColor: palette.background.paper,
        color: palette.text.disabled,
        '&:hover': {
          backgroundColor: palette.background.paper,
          color: palette.primary.main,
        },
        '&.Mui-disabled': {
          borderColor: 'transparent',
        },
      },
      outlined: {
        '&, &:hover, &.Mui-disabled': {
          borderWidth: 2,
        },
        '&, &:hover': {
          borderColor: 'inherit',
        },
      },
      outlinedSecondary: {
        borderColor: palette.text.disabled,
        backgroundColor: palette.background.default,
        color: palette.text.disabled,
        '&:hover': {
          borderColor: palette.common.white,
          backgroundColor: palette.background.default,
          color: palette.common.white,
        },
        '&.Mui-disabled': {
          borderColor: palette.divider,
          color: palette.divider,
        },
      },
      text: {
        textDecoration: 'none',
        backgroundColor: 'transparent',
        borderRadius: 0,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  },
  MuiIconButton: {
    styleOverrides: {
      colorPrimary: {
        color: palette.primary.main,
        '&:hover': {
          color: palette.primary.light,
          backgroundColor: palette.grey[100],
        },
      },
      colorSecondary: {
        color: palette.text.disabled,
        backgroundColor: palette.grey[300],
        '&:hover': {
          color: palette.common.white,
          backgroundColor: palette.primary.dark,
        },
      },
      colorInherit: {
        border: '1px solid',
        backgroundColor: 'transparent',
        borderColor: alpha(palette.grey[900], 0.1),
        color: alpha(palette.grey[900], 0.5),
        '&:hover': {
          backgroundColor: 'transparent',
          borderColor: alpha(palette.grey[900], 0.3),
          color: alpha(palette.grey[900], 0.7),
        },
      },
    },
  },

  MuiInputLabel: {
    styleOverrides: {
      root: {
        paddingBottom: defaultTheme.spacing(1.5),
        cursor: 'pointer',
        fontFamily: FONTS.bold,
        fontWeight: 700,
        fontSize: 14,
        color: palette.grey[900],
      },
    },
  },

  MuiFormControl: {
    defaultProps: {
      fullWidth: true,
    },
  },
  MuiFormControlLabel: {
    styleOverrides: {
      root: {
        marginLeft: 0,
      },
      label: {
        fontSize: 14,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        marginLeft: 0,
        marginRight: 0,
        color: palette.text.disabled,
        fontSize: 11,
        fontFamily: FONTS.medium,
        fontWeight: 500,
        lineHeight: 1.45,
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        width: '100%',
        backgroundColor: palette.background.paper,
        minHeight: 52,
        border: '2px solid',
        outline: 'none',
        transition: `border-color ${defaultTheme.transitions.duration.short}ms`,
        '&&': {
          borderRadius: 12,
        },
        '&.Mui-disabled': {
          pointerEvents: 'none',
          backgroundColor: alpha(palette.background.paper, 0.5),
          color: palette.text.disabled,
        },
        '& fieldset': {
          border: 'none',
        },
        '&:hover': {
          borderColor: palette.primary.main,
        },
        '&.Mui-focused': {
          borderColor: palette.primary.dark,
        },
        '&.error, &.Mui-error': {
          borderColor: palette.error.main,
        },
      },
      input: {
        '&&': {
          padding: defaultTheme.spacing(1.875, 3.5),
          fontSize: 16,
          color: palette.text.primary,
        },
      },
      sizeSmall: {
        minHeight: 32,
      },
      inputSizeSmall: {
        '&&': {
          minHeight: 32,
          fontSize: 14,
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
      adornedStart: {
        '&&': {
          paddingLeft: defaultTheme.spacing(4),
        },
      },
      adornedEnd: {
        '&&': {
          paddingRight: defaultTheme.spacing(4),
        },
      },
      inputAdornedStart: {
        '&&': {
          paddingLeft: 0,
        },
      },
      inputAdornedEnd: {
        '&&': {
          paddingRight: 0,
        },
      },
    },
  },

  MuiInputAdornment: {
    styleOverrides: {
      root: {
        color: palette.grey[500],
        fontSize: 20,
        '& > svg': {
          fontSize: 'inherit',
        },
      },
    },
  },

  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        padding: 0,
        borderColor: palette.grey[400],
      },
    },
  },
  MuiFilledInput: {
    styleOverrides: {
      root: {
        height: 'auto',
        minHeight: 52,
        borderRadius: 12,
        backgroundColor: palette.background.default,
        borderColor: palette.background.default,
        '&:hover': {
          backgroundColor: palette.background.default,
        },
        '&::before, &::after': {
          display: 'none',
        },
        '&.Mui-disabled': {
          backgroundColor: palette.background.default,
          color: palette.text.disabled,
          border: 'none',
        },
      },
      input: {
        height: 'auto',
      },
    },
  },

  MuiSelect: {
    styleOverrides: {
      outlined: {
        '&&': {
          paddingTop: defaultTheme.spacing(2),
          paddingBottom: defaultTheme.spacing(2),
          paddingRight: defaultTheme.spacing(10),
          minHeight: 52,
          boxSizing: 'border-box',
        },
      },
      icon: {
        transition: `transform ${defaultTheme.transitions.duration.short}ms`,
        color: palette.text.primary,
        fontSize: 10,
        top: 'calc(50% - 4px)',
        right: defaultTheme.spacing(4.25),
      },
      select: {
        display: 'flex',
        alignItems: 'center',
        '& > svg, & > img': {
          marginRight: defaultTheme.spacing(2),
          verticalAlign: 'middle',
        },
        '& > svg': {
          fontSize: 20,
          height: 20,
          width: 'auto',
        },
        '& > img': {
          maxWidth: 20,
          height: 'auto',
        },
      },
    },
  },

  MuiBackdrop: {
    styleOverrides: {
      root: {
        backdropFilter: 'blur(16px)',
      },
      invisible: {
        backdropFilter: 'none',
      },
    },
  },

  MuiMenu: {
    styleOverrides: {
      root: {
        marginTop: defaultTheme.spacing(1),
      },
      paper: {
        '&&': {
          borderRadius: 24,
        },
      },
      list: {
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.25)',
      },
    },
  },
  MuiMenuItem: {
    styleOverrides: {
      root: {
        fontSize: 16,
        paddingTop: defaultTheme.spacing(2),
        paddingBottom: defaultTheme.spacing(2),
        transitionProperty: 'color, background-color',
        transitionDuration: `${defaultTheme.transitions.duration.short}ms`,
        '&.Mui-selected, &.Mui-selected:hover, &:hover, &.Mui-selected.Mui-focusVisible':
          {
            backgroundColor: 'transparent',
          },
        '&.Mui-selected:hover, &:hover': {
          color: palette.primary.dark,
        },
        '& svg': {
          fontSize: 20,
          height: 20,
          width: 'auto',
        },
        '& img': {
          maxWidth: 20,
          height: 'auto',
        },
      },
    },
  },
  MuiListSubheader: {
    styleOverrides: {
      root: {
        position: 'relative',
        paddingTop: defaultTheme.spacing(4),
        paddingBottom: defaultTheme.spacing(1.5),
        lineHeight: 1.2,
        fontSize: 12,
        fontFamily: FONTS.bold,
        color: palette.grey[400],
        textTransform: 'uppercase',
        backgroundColor: 'transparent',
      },
    },
  },

  MuiCheckbox: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        color: palette.grey[400],
        '& > svg': {
          fontSize: 24,
          '&.MuiSvgIcon-fontSizeSmall': {
            fontSize: 16,
          },
        },
        '&.Mui-checked, &.MuiCheckbox-indeterminate': {
          color: palette.primary,
        },
        '&&.Mui-disabled': {
          color: palette.grey[600],
        },
      },
    },
  },

  MuiSwitch: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        width: 48,
        height: 28,
        padding: 0,
        marginRight: defaultTheme.spacing(2),
      },
      switchBase: {
        padding: 0,
        margin: 3,
        transitionDuration: `${defaultTheme.transitions.duration.short}ms`,
        '&.Mui-checked + .MuiSwitch-track': {
          backgroundColor: palette.primary.main,
          opacity: 1,
        },
        '&.Mui-disabled': {
          opacity: 0.5,
        },
      },
      thumb: {
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.5)',
        boxSizing: 'border-box',
        width: 21,
        height: 21,
        backgroundColor: palette.background.paper,
      },
      track: {
        borderRadius: 28 / 2,
        backgroundColor: palette.grey[400],
        opacity: 1,
        transition: defaultTheme.transitions.create(['background-color'], {
          duration: 250,
        }),
      },
    },
  },

  MuiChip: {
    styleOverrides: {
      root: {
        backgroundColor: palette.grey[400],
        maxWidth: '100%',
        minHeight: 26,
        fontSize: 11,
        fontWeight: 600,
        fontFamily: FONTS.semiBold,
        lineHeight: '16px',
        padding: defaultTheme.spacing(1.5, 4),
        height: defaultTheme.spacing(7),
        fontFeatureSettings: "'ss01' on, 'ss02' on, 'calt' off",
        borderRadius: 24,
        [defaultTheme.breakpoints.down('md')]: {
          height: defaultTheme.spacing(6),
          fontSize: 10,
        },
      },
      colorPrimary: {
        backgroundColor: palette.primary.main,
      },
      sizeSmall: {
        minHeight: 22,
        paddingLeft: defaultTheme.spacing(2),
        paddingRight: defaultTheme.spacing(2),
      },
    },
  },

  MuiBadge: {
    styleOverrides: {
      badge: {
        lineHeight: 0,
        whiteSpace: 'nowrap',
      },
    },
  },

  MuiTabs: {
    defaultProps: {
      variant: 'scrollable',
    },
    styleOverrides: {
      root: {
        position: 'relative',
        margin: defaultTheme.spacing(3, 0),
        minHeight: 'auto',
      },
      indicator: {
        display: 'none',
      },
    },
  },
  MuiTab: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        fontSize: 16,
        fontFamily: FONTS.medium,
        color: alpha(palette.text.primary, 0.5),
        backgroundColor: palette.grey[400],
        padding: defaultTheme.spacing(2, 3),
        minWidth: 130,
        minHeight: 'auto',
        borderRadius: [12, 12, 0, 0],
        textTransform: 'none',
        transitionProperty: 'background-color, color',
        transitionDuration: `${defaultTheme.transitions.duration.short}ms`,
        '&:hover': {
          color: palette.text.primary,
        },
        '&.Mui-selected': {
          color: palette.text.primary,
          backgroundColor: palette.grey[300],
        },
        '& + &': {
          marginLeft: defaultTheme.spacing(1),
        },
        '&&.Mui-disabled': {
          color: palette.grey[300],
        },
      },
    },
  },

  MuiListItemText: {
    styleOverrides: {
      secondary: {
        fontSize: 12,
      },
    },
  },

  MuiAvatar: {
    defaultProps: {
      variant: 'circular',
    },
    styleOverrides: {
      root: {
        backgroundColor: palette.grey[300],
        color: palette.grey[700],
        position: 'relative',
        height: 'auto',
        textAlign: 'center',
        lineHeight: 0,
        '&::before': {
          display: 'inline-block',
          content: '""',
          paddingBottom: '100%',
          verticalAlign: 'text-bottom',
        },
      },
      img: {
        width: 'calc(100% + 2px)',
        height: 'calc(100% + 2px)',
        position: 'absolute',
        top: -1,
        left: -1,
        objectFit: 'contain',
      },
    },
  },
  MuiAvatarGroup: {
    defaultProps: {},
    styleOverrides: {
      root: {
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap',
      },
      avatar: {
        border: 'none',
        '&:hover': {
          zIndex: 1,
        },
      },
    },
  },

  MuiDialog: {
    styleOverrides: {
      paper: {
        [defaultTheme.breakpoints.down('md')]: {
          margin: defaultTheme.spacing(4),
          width: `calc(100% - ${defaultTheme.spacing(4)})`,
          maxHeight: `calc(100% - ${defaultTheme.spacing(4)})`,
        },
      },
    },
  },

  MuiBreadcrumbs: {
    styleOverrides: {
      root: {
        fontSize: 20,
        marginBottom: defaultTheme.spacing(2),
        color: palette.text.primary,
        [defaultTheme.breakpoints.down('sm')]: {
          fontSize: 14,
        },
      },
      li: {
        '& > a': {
          display: 'flex',
          alignItems: 'center',
          gap: defaultTheme.spacing(3),
          color: palette.text.primary,
          '&:hover': {
            color: palette.link.main,
          },
        },
      },
    },
  },

  MuiLinearProgress: {
    styleOverrides: {
      root: {
        height: 8,
        backgroundColor: palette.background.default,
        borderRadius: 8,
      },
      bar: {
        backgroundColor: palette.primary.dark,
        borderRadius: 8,
      },
    },
  },

  MuiSkeleton: {
    defaultProps: {
      animation: 'wave',
    },
  },

  MuiTablePagination: {
    styleOverrides: {
      root: {
        overflow: 'visible',
      },
      selectRoot: {
        width: 100,
        minHeight: 'auto',
        height: 40,
        borderRadius: 10,
      },
    },
  },

  MuiLink: {
    styleOverrides: {
      root: {
        textDecoration: 'none',
      },
    },
  },
});
