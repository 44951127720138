import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../../../themes/const';

export const useAccountModalStyles = makeStyles()(theme => ({
  title: {
    position: 'relative',
    fontFamily: FONTS.bold,
    fontWeight: 700,
    fontSize: 30,
    padding: theme.spacing(10, 10, 5),
  },
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(5),
  },
  content: {
    padding: theme.spacing(0, 10, 10),
  },
  walletWrap: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    marginBottom: theme.spacing(6),
  },
  wallet: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    gap: theme.spacing(2),
    fontSize: 14,
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(2, 3),
    borderRadius: 12,
    minHeight: 48,
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.background.default,
    },
  },
  copyBtn: {
    width: 48,
    height: 48,
  },
  disconnectBtn: {
    width: '100%',
  },
}));
