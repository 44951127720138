import { Box, Container, Typography } from '@mui/material';

import { YourBalance } from '../../../../../common/components/YourBalance';
import { t } from '../../../../../i18n/utils/intl';
import { HomeSectionType } from '../../../../common';
import { useHomeTopStyles } from './useHomeTopStyles';

interface TopProps {
  activeItem: string;
  onChange: (v: HomeSectionType) => void;
}

export function HomeTop({ activeItem, onChange }: TopProps) {
  const { classes, cx } = useHomeTopStyles();

  return (
    <Box className={classes.root}>
      <Container className={classes.container}>
        <Box className={classes.left}>
          <Typography
            variant="h6"
            className={cx(
              classes.menuItem,
              activeItem === HomeSectionType.Dashboard &&
                classes.menuItemActive,
            )}
            onClick={() => onChange(HomeSectionType.Dashboard)}
          >
            {t('dashboard.dashboard')}
          </Typography>
          <Typography
            variant="h6"
            className={cx(
              classes.menuItem,
              activeItem === HomeSectionType.Staking && classes.menuItemActive,
            )}
            onClick={() => onChange(HomeSectionType.Staking)}
          >
            {t('dashboard.staking')}
          </Typography>
        </Box>
        <Box className={classes.right}>
          <YourBalance />
        </Box>
      </Container>
    </Box>
  );
}
