import { forwardRef, useCallback } from 'react';
import { Button as ButtonComponent, ButtonProps } from '@mui/material';

import { QueryLoading } from 'modules/common/components/QueryLoading/QueryLoading';

import { useButtonStyles } from './useButtonStyles';

interface IButtonProps extends ButtonProps {
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
}

/**
 * The main api is here - [Button API](https://material-ui.com/api/button/)
 */
export const Button = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      className,
      loading = false,
      disabled = false,
      children,
      onClick,
      ...props
    },
    ref,
  ) => {
    const { classes, cx } = useButtonStyles();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onLoadingClick = useCallback((e: any) => {
      e.preventDefault();
    }, []);

    return (
      <ButtonComponent
        ref={ref}
        className={cx(className, classes.root, loading && classes.loading)}
        disabled={disabled || loading}
        {...props}
        onClick={loading ? onLoadingClick : onClick}
      >
        {children}

        {loading && (
          <i className={classes.loadingIcon}>
            <QueryLoading />
          </i>
        )}
      </ButtonComponent>
    );
  },
);

Button.displayName = 'Button';
