import { makeStyles } from 'tss-react/mui';

export const useStakedAssetsStyles = makeStyles()(theme => ({
  validatorColumn: {
    flex: 3,
  },
  toolbarColumn: {
    flex: 2,
  },
  toolbarCell: {
    display: 'flex',
    columnGap: theme.spacing(2),
    flex: 2,
  },
  toolbarBtn: {
    flex: 1,
  },
}));
