import { API_ENDPOINT_PREFIX } from '../common/const';
import { IDelegatorList } from '../common/models';
import { Address } from '../common/types';
import { mapDelegatorList } from './mappers';
import { IApiDelegatorList } from './models';
import { stakeApi } from './stakeApi';

export const { useGetDelegatorListQuery } = stakeApi.injectEndpoints({
  endpoints: build => ({
    getDelegatorList: build.query<IDelegatorList | undefined, Address>({
      query: validatorHash =>
        `${API_ENDPOINT_PREFIX}/staking/delegators/${validatorHash}`,
      transformResponse: (data: IApiDelegatorList) => mapDelegatorList(data),
    }),
  }),
});
