import { makeStyles } from 'tss-react/mui';

import { FONTS } from 'modules/themes/const';

export const useValidatorsStyles = makeStyles()(theme => ({
  root: {
    marginTop: theme.spacing(10),
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      alignItems: 'initial',
      gap: theme.spacing(5),
    },
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 18,
    padding: theme.spacing(5),
  },
  items: {},
  item: {},
  itemTop: {},
  itemBottom: {},
  left: {
    display: 'flex',
    columnGap: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      columnGap: theme.spacing(3),
    },
  },
  right: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    cursor: 'pointer',
    fontFamily: FONTS.bold,
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.grey['500'],
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  menuItemActive: {
    color: theme.palette.grey['900'],
  },
  listWrapper: {
    marginTop: theme.spacing(5),
  },
  registerBtn: {
    textDecoration: 'none',
    paddingRight: 0,
    paddingLeft: 0,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
