import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../../../themes/const';

export const useInfoBlockStyles = makeStyles()(theme => ({
  root: {
    fontSize: 14,
  },
  row: {
    padding: theme.spacing(4, 0),
    borderBottom: '1px solid #E2E8F3',
    '&:last-child': {
      borderBottom: 'none',
    },
    display: 'flex',
    gap: theme.spacing(3),
    flexDirection: 'column',
  },
  rowItem: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  left: {
    fontWeight: 700,
    fontFamily: FONTS.bold,
    color: theme.palette.grey['500'],
  },
  right: {
    color: theme.palette.grey['900'],
    wordBreak: 'break-word',
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
}));
