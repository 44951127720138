import React, { useCallback, useMemo, useRef } from 'react';
import { FieldRenderProps } from 'react-final-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { uid } from 'react-uid';
import {
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputBase,
  InputBaseProps,
  InputLabel,
  TextFieldProps,
} from '@mui/material';

import { ReactComponent as MinusIcon } from '../../../common/components/Icons/MinusIcon.svg';
import { ReactComponent as PlusIcon } from '../../../common/components/Icons/PlusIcon.svg';
import {
  FORM_ITEMS_MARGIN,
  MAX_INTEGER,
  MIN_INTEGER,
} from '../../../common/const';
import { getLocaleSeparator } from '../../../i18n/utils/getLocaleSeparator';
import { t } from '../../../i18n/utils/intl';
import { useLocale } from '../../../i18n/utils/useLocale';
import { useLocaleMemo } from '../../../i18n/utils/useLocaleMemo';
import { Button } from '../../../uiKit/Button';
import { IconButton } from '../../../uiKit/IconButton';
import { getErrorText } from '../../utils/getErrorText';
import { useNumberFormatFieldStyles } from './useNumberFormatFieldStyles';

interface IFieldProps extends FieldRenderProps<string> {
  showLimitCounter?: boolean;
}

export function NumberFormatField({
  input: { name, onChange, value },
  meta,
  showLimitCounter = false,
  label,
  id = uid(`${name}-number`),
  withBottomMargin = true,
  withStepButtons = false,
  withMaxButton = false,
  step = 1,
  helperText,
  wrapperClassName,
  className,
  inputType = 'text',
  allowLeadingZeros = false,
  autoFocus = false,
  allowNegative = true,
  maxLength,
  minValue = MIN_INTEGER,
  maxValue = MAX_INTEGER,
  disabled = false,
  isNumericString = true,
  decimalScale,
  fixedDecimalScale = false,
  inputMode = 'numeric',
  enterKeyHint = 'done',
  placeholder,
  tabIndex = 0,
  prefix = '',
  suffix = '',
  variant,
  sideHint,
}: IFieldProps & TextFieldProps) {
  const { classes, cx } = useNumberFormatFieldStyles();

  const { locale } = useLocale();

  const groupStyle = useMemo(() => {
    switch (locale) {
      case 'zh-CN':
        return 'wan';
      default:
        return 'thousand';
    }
  }, [locale]);

  const errorText = useMemo(() => {
    return getErrorText(meta);
  }, [meta]);

  const getHelperString = useLocaleMemo(() => {
    if (showLimitCounter && !!maxLength) {
      return t('form.limit-counter', {
        value: value.length ?? 0,
        maxLimit: maxLength,
      });
    }
    return helperText;
  }, [value, maxLength, showLimitCounter, helperText]);

  const isAllowed = useCallback(
    (floatValue: NumberFormatValues['floatValue']) => {
      // FALSE if value less than minValue (or negative when allowNegative is disabled)
      // FALSE if value more than maxValue
      return !(
        floatValue &&
        (((minValue || minValue === 0) && floatValue < minValue) ||
          (!allowNegative && floatValue < 0) ||
          ((maxValue || maxValue === 0) && floatValue > maxValue))
      );
    },
    [minValue, maxValue, allowNegative],
  );

  const onValueChange = useCallback(
    (floatValue: NumberFormatValues['floatValue']) => {
      valueRef.current = floatValue;
      onChange(floatValue);
    },
    [onChange],
  );

  const valueRef = useRef<NumberFormatValues['floatValue']>(Number(value));

  const onStepClick = useCallback(
    (isNegative = false) => {
      const direction = isNegative ? -1 : 1;
      const currentValue = valueRef.current ?? 0;
      let newValue = currentValue + step * direction;
      if (!isAllowed(newValue)) {
        newValue = isNegative ? minValue : maxValue;
      }
      onChange(newValue);
    },
    [isAllowed, maxValue, minValue, onChange, step],
  );

  const RenderedStepButtons = useMemo(() => {
    return (
      <>
        <IconButton
          onClick={() => onStepClick(true)}
          color="secondary"
          className={cx(classes.controlButton, classes.stepButton)}
        >
          <MinusIcon />
        </IconButton>
        <IconButton
          onClick={() => onStepClick(false)}
          color="secondary"
          className={cx(classes.controlButton, classes.stepButton)}
        >
          <PlusIcon />
        </IconButton>
      </>
    );
  }, [cx, classes, onStepClick]);

  const RenderedMaxButton = useMemo(() => {
    return (
      <Button
        onClick={() => onChange(maxValue)}
        className={cx(classes.controlButton, classes.maxButton)}
        size="small"
      >
        {t('common.max')}
      </Button>
    );
  }, [cx, classes, maxValue, onChange]);

  const renderedField = useCallback(
    (props: InputBaseProps) => (
      <InputBase
        {...props}
        endAdornment={
          (withStepButtons || withMaxButton) && (
            <InputAdornment position="end">
              <Box className={classes.controlButtons}>
                {withStepButtons && RenderedStepButtons}
                {withMaxButton && RenderedMaxButton}
              </Box>
            </InputAdornment>
          )
        }
        inputProps={{
          ...props.inputProps,
          autoComplete: 'off',
          enterKeyHint,
          inputMode: 'numeric',
          step,
        }}
      />
    ),
    [
      RenderedMaxButton,
      RenderedStepButtons,
      classes.controlButtons,
      enterKeyHint,
      step,
      withMaxButton,
      withStepButtons,
    ],
  );

  return (
    <Box
      mb={withBottomMargin ? FORM_ITEMS_MARGIN : 0}
      className={cx(wrapperClassName, classes.wrap)}
    >
      <Box className={classes.labelBox}>
        {label && (
          <InputLabel error={!!errorText} htmlFor={id}>
            {label}
          </InputLabel>
        )}
        {sideHint}
      </Box>
      <FormControl variant={variant}>
        <NumberFormat
          customInput={renderedField}
          id={id}
          value={value}
          name={name}
          displayType="input"
          allowNegative={allowNegative}
          allowLeadingZeros={allowLeadingZeros}
          className={cx(
            className,
            classes.input,
            variant === 'filled' && classes.filled,
            !!errorText && 'error',
          )}
          type={inputType}
          autoFocus={autoFocus}
          disabled={disabled}
          decimalSeparator={getLocaleSeparator(locale, 'decimal')}
          thousandSeparator={getLocaleSeparator(locale)}
          thousandsGroupStyle={groupStyle}
          decimalScale={decimalScale}
          fixedDecimalScale={fixedDecimalScale}
          isNumericString={isNumericString}
          placeholder={placeholder}
          inputMode={inputMode}
          onValueChange={({ floatValue }) => onValueChange(floatValue)}
          tabIndex={tabIndex}
          prefix={prefix}
          suffix={suffix}
          isAllowed={({ floatValue }) => isAllowed(floatValue)}
        />
      </FormControl>
      {!!errorText && <FormHelperText error>{errorText}</FormHelperText>}
      {!!getHelperString && <FormHelperText>{getHelperString}</FormHelperText>}
    </Box>
  );
}
