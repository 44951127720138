import React from 'react';
import {
  AvatarGroup,
  Badge,
  Box,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';

import { UserAvatar } from '../../../uiKit/UserAvatar';

export function UiAvatars() {
  return (
    <Box mt={5} mb={40}>
      <Typography variant="h1">Avatars</Typography>
      <hr />
      <Grid container>
        <Grid item xs={12} sm={6}>
          <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <ListItem>
              <ListItemAvatar>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot"
                  color="error"
                >
                  <UserAvatar
                    name="Remy Sharp"
                    image="/static/images/avatar/1.jpg"
                    withTooltip
                  />
                </Badge>
              </ListItemAvatar>
              <ListItemText primary="Remy Sharp" secondary="Offline" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot"
                  color="success"
                >
                  <UserAvatar
                    name="John"
                    image="https://descargas.ams3.digitaloceanspaces.com/images/4391/john-wick-wallpaper_icon_android.png"
                    withTooltip
                  />
                </Badge>
              </ListItemAvatar>
              <ListItemText primary="John" secondary="Online" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <UserAvatar
                  name="John"
                  image="/static/images/avatar/1.jpg"
                  withTooltip
                  variant="rounded"
                />
              </ListItemAvatar>
              <ListItemText primary="James Smith" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <UserAvatar
                  name="T800"
                  image="https://illusion0flife.files.wordpress.com/2015/07/terminator_genisys-0-0.jpg?w=256&h=256&crop=1"
                  withTooltip
                  variant="square"
                />
              </ListItemAvatar>
              <ListItemText primary="T800" />
            </ListItem>
            <ListItem>
              <ListItemAvatar>
                <UserAvatar image="/static/images/avatar/1.jpg" withTooltip />
              </ListItemAvatar>
              <ListItemText primary="Unknown" />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={6}>
          <AvatarGroup>
            <UserAvatar />
            <UserAvatar image="https://assets.ankr.com/charts/icon-only/ethereum.svg" />
            <UserAvatar image="https://jobapplications.net/wp-content/uploads/gamestop-logo-icon.png" />
            <UserAvatar image="https://assets.ankr.com/charts/icon-only/Avalanche.svg" />
            <UserAvatar image="https://assets.ankr.com/charts/icon-only/fandom.svg" />
            <UserAvatar image="https://cdn.worldvectorlogo.com/logos/tesla-motors.svg" />
            <UserAvatar image="https://assets.ankr.com/charts/icon-only/heco.svg" />
          </AvatarGroup>
        </Grid>
      </Grid>
    </Box>
  );
}
