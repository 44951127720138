import React, { useCallback, useState } from 'react';
import { uid } from 'react-uid';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';

import { t } from '../../../i18n/utils/intl';
import { useLocaleMemo } from '../../../i18n/utils/useLocaleMemo';
import { Network, useCurrentNetwork } from '../../hooks/useCurrentNetwork';
import { ReactComponent as ChevronDownIcon } from '../Icons/ChevronDownIcon.svg';
import { ReactComponent as CompleteIcon } from '../Icons/CompleteIcon.svg';
import { ReactComponent as DogeChainIcon } from '../Icons/DogeChainIcon.svg';
import { ReactComponent as MetaApesIcon } from '../Icons/MetaApesIcon.svg';
import { ReactComponent as MultiverseIcon } from '../Icons/MultiverseIcon.svg';
import { useNetworkSwitcherStyles } from './useNetworkSwitcherStyles';

interface INetworkSwitcherProps {
  compactView?: boolean;
}

export function NetworkSwitcher({ compactView }: INetworkSwitcherProps) {
  const { classes, cx } = useNetworkSwitcherStyles();

  const { currentNetwork, setCurrentNetwork } = useCurrentNetwork();

  const [value, setValue] = useState(currentNetwork);

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as Network;
    setValue(value);
    setCurrentNetwork(value);
  };

  const networkOptions = useLocaleMemo(
    () => [
      {
        label: t('networks.default'),
        value: Network.default,
      },
      {
        label: t('networks.metaapes'),
        value: Network.metaapes,
        icon: <MetaApesIcon />,
      },
      {
        label: t('networks.dogechain'),
        value: Network.dogechain,
        icon: <DogeChainIcon />,
      },
      {
        label: t('networks.multiverse'),
        value: Network.multiverse,
        icon: <MultiverseIcon />,
      },
    ],
    [],
  );

  const renderValue = useCallback(() => {
    const currentOption = networkOptions.find(o => o.value === value);
    return (
      <span className={classes.itemContent}>
        {currentOption?.icon}
        {currentOption?.label}
      </span>
    );
  }, [classes, networkOptions, value]);

  return (
    <Select
      className={cx(classes.root, compactView && classes.compact)}
      renderValue={renderValue}
      value={value}
      onChange={handleChange}
      IconComponent={ChevronDownIcon}
      size={compactView ? 'small' : 'medium'}
    >
      {networkOptions.map(item => {
        const selected = item.value === value;
        return (
          <MenuItem key={uid(item)} value={item.value} className={classes.item}>
            <span className={classes.itemContent}>
              {item.icon}
              {item.label}
            </span>
            {selected && <CompleteIcon className={classes.checkedIcon} />}
          </MenuItem>
        );
      })}
    </Select>
  );
}
