import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../../../themes/const';

export const useStakingModalStyles = makeStyles()(theme => ({
  root: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: FONTS.regular,
  },
  title: {
    fontSize: 34,
    fontWeight: 700,
    fontFamily: FONTS.bold,
    color: theme.palette.grey['900'],
    textAlign: 'center',
  },
  topHint: {
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  body: {
    // marginTop: theme.spacing(10),
  },
  bottomHint: {
    fontSize: 14,
    fontWeight: 400,
    borderLeft: '2px solid',
    borderLeftColor: theme.palette.primary.dark,
    paddingLeft: theme.spacing(1),
    color: theme.palette.grey['900'],
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(4),
  },
  btn: {
    marginTop: theme.spacing(6),
  },
}));
