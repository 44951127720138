import { ReactNode } from 'react';
import { uid } from 'react-uid';
import { Box } from '@mui/material';

import { useInfoBlockStyles } from './useInfoBlockStyles';

export interface IInfoBlockItem {
  left: ReactNode;
  right: ReactNode;
}

type InfoBlockRow = IInfoBlockItem[];

interface InfoBlockProps {
  items: InfoBlockRow[];
}

export function InfoBlock({ items }: InfoBlockProps) {
  const { classes } = useInfoBlockStyles();

  return (
    <Box>
      {items.map(row => (
        <Box key={uid(row)} className={classes.row}>
          {row.map(item => (
            <Box key={uid(item)} className={classes.rowItem}>
              <Box className={classes.left}>{item.left}</Box>
              <Box className={classes.right}>{item.right}</Box>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}
