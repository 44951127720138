import React, { useCallback, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Switch,
  Typography,
} from '@mui/material';

import { LoupeIcon } from '../../../common/components/Icons/LoupeIcon';
import { InputField } from '../../../form/components/InputField';
import { SelectField } from '../../../form/components/SelectField';
import { FormErrors } from '../../../form/utils/FormErrors';
import { t } from '../../../i18n/utils/intl';
import { Button } from '../../../uiKit/Button';

interface IUiFormControlsPayload {
  field1: string;
  field2: string;
  select1: string;
}

const validate = (payload: IUiFormControlsPayload) => {
  const errors: FormErrors<IUiFormControlsPayload> = {};

  if (!payload.field1) {
    errors.field1 = t('validation.required');
  }

  if (!payload.field2) {
    errors.field2 = t('validation.required');
  }

  if (!payload.select1) {
    errors.select1 = t('validation.required');
  }

  return errors;
};

export function UiFormControls() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = useCallback((payload: any) => {
    // eslint-disable-next-line no-console
    console.log(payload);
  }, []);

  const typeOptions = useMemo(
    () => [
      {
        label: 'Value 1',
        value: 1,
      },
      {
        label: 'Group header',
        isSubheader: true,
      },
      {
        label: (
          <>
            <LoupeIcon /> Value 2 w/ icon
          </>
        ),
        value: 2,
      },
      {
        label: 'Value 3 disabled',
        value: 3,
        disabled: true,
      },
    ],
    [],
  );

  const renderForm = ({
    handleSubmit,
  }: FormRenderProps<IUiFormControlsPayload>) => {
    return (
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Field
              component={InputField}
              name="field1"
              type="text"
              label="Text field"
              placeholder="Text field"
              helperText="Helper text"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={InputField}
              name="search1"
              type="search"
              label="Search field"
              placeholder="Search field"
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LoupeIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={InputField}
              name="number1"
              type="number"
              label="Numeric field"
              placeholder="Numeric field"
              fullWidth
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                step: 'any',
                min: '0',
                inputMode: 'decimal',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={InputField}
              name="field2"
              type="text"
              placeholder="Text field no label"
              color="primary"
              fullWidth
              showLimitCounter
              inputProps={{
                maxLength: 30,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={InputField}
              name="fieldDisabled"
              type="text"
              label="Text field disabled"
              placeholder="Text field disabled"
              color="primary"
              disabled
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={InputField}
              name="fieldReadOnly"
              type="text"
              label="Text field read only"
              placeholder="Text field read only"
              color="primary"
              InputProps={{ readOnly: true, value: 'Readonly' }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={InputField}
              name="fieldSmall"
              type="text"
              label="Text field small"
              placeholder="Text field small"
              color="primary"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={InputField}
              name="password"
              type="password"
              label="Password"
              placeholder="Password"
              color="primary"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={InputField}
              name="textarea"
              type="text"
              label="Text area"
              placeholder="Text area"
              multiline
              minRows={4}
              color="primary"
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Field
              component={SelectField}
              name="select1"
              label="Select box"
              options={typeOptions}
              helperText="Helper text"
              placeholder="Choose one! (placeholder)"
            />
          </Grid>
          <Grid item xs={12} sm={6} mt={3}>
            <FormControlLabel control={<Switch />} label="Switch" />
          </Grid>
          <Grid item xs={12} sm={6} mt={3}>
            <FormControlLabel
              control={<Switch />}
              label="Switch disabled"
              disabled
            />
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Switch disabled"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6} mt={3}>
            <FormControlLabel control={<Checkbox />} label="Checkbox" />
            <FormControlLabel
              control={<Checkbox indeterminate />}
              label="Checkbox indeterminate"
            />
            <FormControlLabel
              control={<Checkbox size="small" />}
              label="Checkbox small"
            />
          </Grid>
          <Grid item xs={12} sm={6} mt={3}>
            <FormControlLabel
              disabled
              control={<Checkbox />}
              label="Checkbox disabled"
            />
            <FormControlLabel
              disabled
              control={<Checkbox defaultChecked />}
              label="Checkbox disabled"
            />
          </Grid>
        </Grid>
        <Box mt={3}>
          <Button type="submit" variant="contained" color="primary">
            Submit
          </Button>
        </Box>
      </Box>
    );
  };

  return (
    <Box mt={5} mb={40}>
      <Typography variant="h1">Form controls</Typography>
      <hr />
      <Form onSubmit={handleSubmit} render={renderForm} validate={validate} />
    </Box>
  );
}
