import { makeStyles } from 'tss-react/mui';

export const useToggleStyles = makeStyles<
  void,
  'lineTop' | 'lineMiddle' | 'lineBottom'
>()((theme, _params, classes) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'space-evenly',
    width: 24,
    height: 24,
    minWidth: 0,
    padding: 0,
    transitionTimingFunction: 'step-end',
    '&:hover, &&': {
      backgroundColor: 'transparent',
      outline: 'none',
    },
  },

  active: {
    transitionDuration: '0s',
    [`& .${classes.lineTop}`]: {
      transform: 'translateY(4px) rotate(45deg)',
      marginTop: 5,
    },
    [`& .${classes.lineMiddle}`]: {
      opacity: 0,
    },
    [`& .${classes.lineBottom}`]: {
      transform: 'translateY(-4px) rotate(-45deg)',
      marginBottom: 5,
    },
  },

  line: {
    width: 20,
    height: 2,
    transition: 'transform 0.2s, opacity 0.2s, margin 0.2s',
    transformOrigin: 'center',
    backgroundColor: theme.palette.text.primary,
  },

  lineTop: {},
  lineMiddle: {},
  lineBottom: {},
}));
