import React from 'react';
import { uid } from 'react-uid';

import {
  HeaderNavigationBtn,
  IHeaderNavigationBtn,
} from '../HeaderNavigationBtn';
import { useHeaderNavigationStyles } from './useHeaderNavigationStyles';

interface IHeaderNavigationProps {
  buttons?: IHeaderNavigationBtn[];
}

export function HeaderNavigation({ buttons }: IHeaderNavigationProps) {
  const { classes } = useHeaderNavigationStyles();

  return (
    <div className={classes.root}>
      {buttons &&
        buttons.map((button: IHeaderNavigationBtn) => {
          return (
            !!button && (
              <HeaderNavigationBtn
                key={uid(button)}
                path={button.path}
                title={button.title}
              />
            )
          );
        })}
    </div>
  );
}
