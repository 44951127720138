import React from 'react';
import { Box, Divider, Typography } from '@mui/material';

import { ReactComponent as CrossIcon } from '../../../common/components/Icons/CrossIcon.svg';
import { HomeIcon } from '../../../common/components/Icons/HomeIcon';
import { Button } from '../../../uiKit/Button';
import { IconButton } from '../../../uiKit/IconButton';

export function UiButtons() {
  return (
    <Box mt={5} mb={40}>
      <Typography variant="h1">Buttons</Typography>
      <hr />
      <Button>Contained primary</Button>
      <Button disabled>Contained primary disabled</Button>
      <Button loading>Contained primary loading</Button>
      <br />
      <Button size="small">Contained primary small</Button>
      <Button size="large">Contained primary large</Button>
      <Divider sx={{ margin: 5 }} />
      <Button color="secondary">Contained secondary</Button>
      <Button color="secondary" disabled>
        Contained secondary disabled
      </Button>
      <Divider sx={{ margin: 5 }} />
      <Button variant="outlined">Outlined primary</Button>
      <Button variant="outlined" disabled>
        Outlined primary disabled
      </Button>
      <Divider sx={{ margin: 5 }} />
      <Button variant="outlined" color="secondary">
        Outlined secondary
      </Button>
      <Button variant="outlined" color="secondary" disabled>
        Outlined secondary disabled
      </Button>
      <Divider sx={{ margin: 5 }} />
      <Button color="inherit">Contained (download)</Button>
      <Button color="inherit" disabled>
        Contained (download) disabled
      </Button>
      <Divider sx={{ margin: 5 }} />
      <IconButton color="primary">
        <HomeIcon />
      </IconButton>
      <IconButton color="primary" size="small">
        <HomeIcon />
      </IconButton>
      <IconButton color="primary" size="large">
        <HomeIcon />
      </IconButton>
      <IconButton color="primary" disabled>
        <HomeIcon />
      </IconButton>
      <IconButton color="primary" loading>
        <HomeIcon />
      </IconButton>
      <IconButton color="primary" loading size="small">
        <HomeIcon />
      </IconButton>

      <IconButton color="secondary">
        <HomeIcon />
      </IconButton>
      <IconButton color="secondary" size="small">
        <HomeIcon />
      </IconButton>

      <IconButton color="inherit" size="large">
        <CrossIcon />
      </IconButton>
    </Box>
  );
}
