import { Box, Container, Typography } from '@mui/material';

import { useQueryParam } from '../../../../../../../common/hooks/useQueryParam';
import { t } from '../../../../../../../i18n/utils/intl';
import { AssetsType, HomeUrlQueryParams } from '../../../../../../common';
import { ClaimableAssets } from '../ClaimableAssets';
import { History } from '../History';
import { StakedAssets } from '../StakedAssets';
import { useStakingTabsStyles } from './useStakingTabsStyles';

export function StakingTabs() {
  const { classes, cx } = useStakingTabsStyles();

  const [activeTab, setActiveTab] = useQueryParam(
    HomeUrlQueryParams.Assets,
    AssetsType.Staked,
  );

  return (
    <Box className={classes.root}>
      <Container>
        <Box className={classes.top}>
          <Box className={classes.left}>
            <Typography
              variant="subtitle1"
              className={cx(
                classes.menuItem,
                activeTab === AssetsType.Staked && classes.menuItemActive,
              )}
              onClick={() => setActiveTab(AssetsType.Staked)}
            >
              {t('dashboard.staked-assets')}
            </Typography>
            <Typography
              variant="subtitle1"
              className={cx(
                classes.menuItem,
                activeTab === AssetsType.Claimable && classes.menuItemActive,
              )}
              onClick={() => setActiveTab(AssetsType.Claimable)}
            >
              {t('dashboard.claimable-assets')}
            </Typography>
            <Typography
              variant="subtitle1"
              className={cx(
                classes.menuItem,
                activeTab === AssetsType.History && classes.menuItemActive,
              )}
              onClick={() => setActiveTab(AssetsType.History)}
            >
              {t('dashboard.history')}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.listWrapper}>
          {activeTab === AssetsType.Staked && <StakedAssets />}
          {activeTab === AssetsType.Claimable && <ClaimableAssets />}
          {activeTab === AssetsType.History && <History />}
        </Box>
      </Container>
    </Box>
  );
}
