import React from 'react';
import { Box, Container } from '@mui/material';

import { NewValidatorForm } from './components/NewValidatorForm';
import { NewValidatorInfo } from './components/NewValidatorInfo';
import { useNewValidatorStyles } from './useNewValidatorStyles';

export function NewValidator() {
  const { classes, cx } = useNewValidatorStyles();

  return (
    <Container className={classes.container}>
      <Box className={classes.grid}>
        <Box className={cx(classes.gridCell, classes.infoWrap)}>
          <NewValidatorInfo />
        </Box>
        <Box className={cx(classes.gridCell, classes.formWrap)}>
          <NewValidatorForm />
        </Box>
      </Box>
    </Container>
  );
}
