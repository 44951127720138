import type { Middleware } from '@reduxjs/toolkit';
import { isRejectedWithValue } from '@reduxjs/toolkit';

import { extractError } from '../../common/utils/extractError';
import { notification } from '../components';

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = () => next => action => {
  if (isRejectedWithValue(action) || action.error?.name === 'Error') {
    notification({
      message: extractError(action.error),
      type: 'error',
    });
  }

  return next(action);
};
