import { makeStyles } from 'tss-react/mui';

import {
  FONTS,
  HEADER_HEIGHT,
  MAIN_BLOCK_PADDING_BOTTOM,
} from '../../../themes/const';

export const useConnectWalletStyles = makeStyles()(theme => ({
  container: {
    minHeight: `calc(100vh - ${HEADER_HEIGHT + MAIN_BLOCK_PADDING_BOTTOM}px)`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    width: 700,
    maxWidth: '100%',
    padding: theme.spacing(13, 15, 10),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(8, 8, 6),
    },
  },
  title: {
    fontFamily: FONTS.bold,
    fontWeight: 700,
    fontSize: 30,
    maxWidth: 420,
    margin: theme.spacing(0, 'auto', 5),
  },
  hint: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '0.25em',
    marginBottom: theme.spacing(10),
  },
  connectBtn: {
    width: '100%',
    fontFamily: FONTS.semiBold,
    fontWeight: 600,
    fontSize: 16,
    minHeight: 48,
  },
}));
