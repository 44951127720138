import BigNumber from 'bignumber.js';
import { EventData } from 'web3-eth-contract';

export type Address = string;
export type TxId = string;
export type BlockHash = string;
export type BlockNumber = string;
export type Web3Uint256 = string;

export enum HashType {
  Address,
  Tx,
  Block,
}

export type TValidatorStatus =
  | 'NOT_FOUND'
  | 'ACTIVE'
  | 'PENDING'
  | 'JAILED'
  | 'UNKNOWN';

export interface IVotingPower {
  votingSupply: number;
  votingPower: number;
}

export enum TGovernanceProposalStatus {
  Pending,
  Active,
  Cancelled,
  Defeated,
  Succeeded,
  Queued,
  Expired,
  Executed,
}

export type TGovernanceVoteType = 'AGAINST' | 'FOR' | 'ABSTAIN';

export interface IGovernanceVote {
  type: TGovernanceVoteType;
  blockNumber: number;
  reason: string;
  voterAddress: Address;
  weight: BigNumber;
  transactionHash: string;
}

export interface IGovernanceProposal {
  id: string;
  proposer: string;
  status: TGovernanceProposalStatus;
  targets: string[];
  values: string[];
  signatures: string[];
  inputs: string[];
  startBlock: string;
  endBlock: string;
  desc: string;
  votes: IGovernanceVote[];
  quorumRequired: BigNumber;
  totalPower: BigNumber;
  voteDistribution: Record<TGovernanceVoteType, BigNumber>;
}

export type IEventData = EventData;

export interface IDelegatorDelegation {
  event?: IEventData;
  validator: Address;
  staker: Address;
  amount: Web3Uint256;
  epoch: number;
}

export interface IDelegatorUnDelegation {
  event?: IEventData;
  validator: Address;
  staker: Address;
  amount: Web3Uint256;
  epoch: number;
}

export interface IDelegatorClaim {
  event?: IEventData;
  validator: Address;
  staker: Address;
  amount: Web3Uint256;
  epoch: number;
}

export interface IDelegatorOneOfEvent {
  delegation?: IDelegatorDelegation;
  undelegation?: IDelegatorUnDelegation;
  claim?: IDelegatorClaim;
}
