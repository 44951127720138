import intl from 'react-intl-universal';
import { shallowEqual } from 'react-redux';

import { locales } from '../index';

// small polyfill for intl
// @typescript/ignore-next-line
// eslint-disable-next-line
const format = (key: string, vari?: any): string => {
  const str = key.split('.').reduce((acc, val) => acc?.[val], locales['en-US']);
  if (!vari || !str) {
    return key;
  }
  return Object.entries(vari).reduce(
    (acc, [key, value]) => acc.replace(`{${key}}`, value),
    str,
  );
};
const cache = Object.create(null);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function t(key: string, variables?: any): string {
  const memo = cache[key];
  if (memo && shallowEqual(memo.variables, variables)) {
    return memo.value;
  }
  const value = intl.get(key, variables) || key;

  cache[key] = {
    variables,
    value,
  };

  return value;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function tHTML(key: string, variables?: any): string {
  return intl.getHTML(key, variables) || key;
}
