import { makeStyles } from 'tss-react/mui';

export const useYourBalanceStyles = makeStyles()(theme => ({
  root: {
    fontSize: 14,
  },
  smallView: {
    fontSize: 12,
  },
  content: {
    display: 'flex',
    alignItems: 'center',
  },
  balanceText: {
    color: theme.palette.grey['500'],
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  avatarClassName: {
    width: 17,
    height: 17,
  },
  balanceValue: {
    color: theme.palette.grey['900'],
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  getBtn: {
    '&&': {
      fontSize: 'inherit',
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
      minWidth: 'auto',
    },
  },
}));
