import { Box, Container } from '@mui/material';

import { StakingStats } from './components/StakingStats';
import { StakingTabs } from './components/StakingTabs';
import { useStakingStyles } from './useStakingStyles';

export function Staking() {
  const { classes } = useStakingStyles();

  return (
    <Box className={classes.root}>
      <Container>
        <StakingStats />
      </Container>
      <StakingTabs />
    </Box>
  );
}
