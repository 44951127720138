import React from 'react';
import { EEthereumNetworkId } from '@ankr.com/provider';
import { Card, Container } from '@mui/material';
import Typography from '@mui/material/Typography';

import { t } from '../../../i18n/utils/intl';
import { Button } from '../../../uiKit/Button';
import { getChainIcon } from '../../utils';
import { useUnsupportedNetworkStyles } from './useUnsupportedNetworkStyles';

interface IUnsupportedNetworkProps {
  currentChainId: EEthereumNetworkId;
  requiredChainId: EEthereumNetworkId;
  onSwitch: () => void;
}

export function UnsupportedNetwork({
  currentChainId,
  requiredChainId,
  onSwitch,
}: IUnsupportedNetworkProps): JSX.Element {
  const { classes, cx } = useUnsupportedNetworkStyles();

  return (
    <Container className={classes.container}>
      <Card className={classes.content}>
        <Typography variant="h2" className={classes.title}>
          {t('wrong-network.title')}
        </Typography>
        <Typography
          variant="body2"
          className={cx(classes.hint, classes.hintFirst)}
        >
          {t('wrong-network.hint', {
            value: t(`chain.${currentChainId}`),
          })}
        </Typography>
        <Typography variant="body2" className={classes.hint}>
          {t('wrong-network.switch-to')}
          {getChainIcon(requiredChainId)}
          {t(`chain.${requiredChainId}`)}
        </Typography>
        <Button className={classes.btn} onClick={onSwitch}>
          {t('common.switch-network')}
        </Button>
      </Card>
    </Container>
  );
}
