import { makeStyles } from 'tss-react/mui';

export const useInputFieldStyles = makeStyles()(theme => ({
  labelBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  infoBtn: {
    marginLeft: theme.spacing(2),
    color: theme.palette.primary.main,
    width: theme.spacing(5),
    height: theme.spacing(5),
    verticalAlign: 'baseline',
    '&:focus': {
      outline: 'none',
      backgroundColor: theme.palette.grey[700],
      color: theme.palette.primary.main,
    },
    '&:hover': {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.primary.main,
    },
  },
  infoIcon: {
    width: theme.spacing(2.5),
    height: theme.spacing(2.5),
  },
  adornment: {
    position: 'relative',
    width: 25,
  },
  loadingIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 18,
    color: theme.palette.text.primary,
    '& > svg': {
      display: 'block',
      width: '1em',
      height: '1em',
    },
  },
}));
