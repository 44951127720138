import { useCallback, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useNavigate } from 'react-router-dom';
import { Box, Card, Typography } from '@mui/material';

import {
  useGetAccountQuery,
  useGetBalanceQuery,
  useRegisterValidatorMutation,
} from '../../../../../Bas/actions/bas';
import { ReactComponent as CrossIcon } from '../../../../../common/components/Icons/CrossIcon.svg';
import { YourBalance } from '../../../../../common/components/YourBalance';
import {
  VALIDATOR_MAX_COMMISSION_RATE,
  VALIDATOR_MIN_COMMISSION_RATE,
  ZERO,
} from '../../../../../common/const';
import { useCurrentNetwork } from '../../../../../common/hooks/useCurrentNetwork';
import { Address } from '../../../../../common/types';
import {
  isNumber,
  isValidETHAddress,
} from '../../../../../common/utils/validation';
import { InputField } from '../../../../../form/components/InputField';
import { NumberFormatField } from '../../../../../form/components/NumberFormatField';
import { FormErrors } from '../../../../../form/utils/FormErrors';
import { HomeRoutesConfig } from '../../../../../Home/HomeRoutes';
import { t } from '../../../../../i18n/utils/intl';
import { Button } from '../../../../../uiKit/Button';
import { IconButton } from '../../../../../uiKit/IconButton';
import { NewValidatorCompleteModal } from '../NewValidatorCompleteModal';
import { useNewValidatorFormStyles } from './useNewValidatorFormStyles';

interface INewValidatorFormPayload {
  address: Address;
  commissionRate: number;
  initialStake: number;
}

export function NewValidatorForm() {
  const { classes } = useNewValidatorFormStyles();

  const { data: balance } = useGetBalanceQuery();
  const { data: accountAddress } = useGetAccountQuery();

  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const { currentNetwork: network } = useCurrentNetwork();

  const handleBack = useCallback(() => {
    if (window.history.state) {
      navigate(-1);
    } else {
      navigate(HomeRoutesConfig.Home.generatePath(network));
    }
  }, [navigate, network]);

  const [registerValidator, { isLoading }] = useRegisterValidatorMutation();

  const handleSubmit = useCallback(
    ({ address, commissionRate, initialStake }: INewValidatorFormPayload) => {
      if (address && commissionRate && initialStake) {
        void registerValidator({
          validator: address,
          commissionRate,
          initialStake,
        }).then(data => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          if (!data.error?.message) {
            setModalOpen(true);
          }
        });
      }
    },
    [registerValidator],
  );

  const validateForm = useCallback(
    (payload: INewValidatorFormPayload) => {
      const errors: FormErrors<INewValidatorFormPayload> = {};

      if (!payload.address) {
        errors.address = t('validation.required');
      } else if (!isValidETHAddress(payload.address)) {
        errors.address = t('validation.invalid-address');
      }

      if (!payload.commissionRate) {
        errors.commissionRate = t('validation.required');
      } else if (!isNumber(Number(payload.commissionRate))) {
        errors.commissionRate = t('validation.require-number');
      } else if (
        Number(payload.commissionRate) < VALIDATOR_MIN_COMMISSION_RATE
      ) {
        errors.commissionRate = t('validation.require-positive');
      } else if (
        Number(payload.commissionRate) > VALIDATOR_MAX_COMMISSION_RATE
      ) {
        errors.commissionRate = t('validation.less-than', {
          value: VALIDATOR_MAX_COMMISSION_RATE,
        });
      }

      if (!payload.initialStake) {
        errors.initialStake = t('validation.required');
      } else if (!isNumber(Number(payload.initialStake))) {
        errors.initialStake = t('validation.require-number');
      } else if (Number(payload.initialStake) > Number(balance)) {
        errors.initialStake = t('validation.less-than-balance');
      } else if (Number(payload.initialStake) < ZERO) {
        errors.initialStake = t('validation.require-positive');
      }

      return errors;
    },
    [balance],
  );

  const renderForm = ({
    handleSubmit,
  }: FormRenderProps<INewValidatorFormPayload>) => {
    return (
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Field
          component={InputField}
          name="address"
          label={t('new-validator.form.address')}
          placeholder={
            accountAddress ?? t('new-validator.form.address-placeholder')
          }
          helperText={t('new-validator.form.address-hint')}
          fullWidth
          autoFocus
          variant="filled"
          className={classes.input}
        />

        <Field
          component={NumberFormatField}
          name="commissionRate"
          label={t('new-validator.form.commission-rate')}
          placeholder={t('new-validator.form.commission-rate-placeholder')}
          helperText={t('new-validator.form.commission-rate-hint', {
            minCommissionRate: VALIDATOR_MIN_COMMISSION_RATE,
            maxCommissionRate: VALIDATOR_MAX_COMMISSION_RATE,
          })}
          minValue={VALIDATOR_MIN_COMMISSION_RATE}
          maxValue={VALIDATOR_MAX_COMMISSION_RATE}
          withStepButtons
          fullWidth
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale
          suffix={t('common.percent-symbol')}
          variant="filled"
          className={classes.input}
        />

        <Field
          component={NumberFormatField}
          name="initialStake"
          label={t('new-validator.form.initial-stake')}
          placeholder={t('new-validator.form.initial-stake-placeholder')}
          helperText={t('new-validator.form.initial-stake-hint')}
          minValue={ZERO}
          maxValue={balance ? balance.toNumber() : ZERO}
          withMaxButton
          fullWidth
          allowNegative={false}
          enterKeyHint="done"
          variant="filled"
          className={classes.input}
          sideHint={<YourBalance buttonVariant="text" smallView />}
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          size="large"
          loading={isLoading}
        >
          {t('new-validator.form.submit-btn')}
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Card className={classes.root}>
        <IconButton
          aria-label="close"
          onClick={handleBack}
          className={classes.closeBtn}
          color="inherit"
          size="large"
        >
          <CrossIcon />
        </IconButton>
        <Typography variant="h3" className={classes.title}>
          {t('new-validator.form.title')}
        </Typography>

        <Form
          onSubmit={handleSubmit}
          render={renderForm}
          validate={validateForm}
        />
      </Card>
      <NewValidatorCompleteModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      />
    </>
  );
}
