import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Tooltip } from '@mui/material';

import { ReactComponent as CopyIcon } from '../../common/components/Icons/CopyIcon.svg';
import { DoneIcon } from '../../common/components/Icons/DoneIcon';
import { Milliseconds } from '../../common/types/unit';
import { t } from '../../i18n/utils/intl';
import { IconButton } from '../IconButton';
import { useCopyButtonStyles } from './useCopyButtonStyles';

const COPIED_TIMEOUT: Milliseconds = 1000;

interface ICopyButtonProps {
  text?: string;
  disabled?: boolean;
  className?: string;
  color?: string;
  size?: string;
}

export function CopyButton({
  text,
  className,
  disabled,
  color = 'primary',
  size = 'medium',
}: ICopyButtonProps) {
  const { classes, cx } = useCopyButtonStyles();
  const [isCopied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    if (isCopied) {
      setTimeout(() => {
        setCopied(false);
        (document.activeElement as HTMLElement)?.blur();
      }, COPIED_TIMEOUT);
    }
  }, [isCopied]);

  if (!text) {
    return null;
  }

  return (
    <CopyToClipboard text={text} onCopy={() => setCopied(true)}>
      <Tooltip
        title={
          isCopied ? (
            <span className={classes.copiedTooltip}>{t('common.copied')}</span>
          ) : (
            t('common.copy-to-clipboard')
          )
        }
        arrow
        enterNextDelay={0}
      >
        <IconButton
          disabled={disabled}
          color={color}
          size={size}
          className={cx(classes.root, className)}
        >
          {isCopied ? (
            <DoneIcon className={cx(classes.icon, classes.doneIcon)} />
          ) : (
            <CopyIcon className={cx(classes.icon, classes.copyIcon)} />
          )}
        </IconButton>
      </Tooltip>
    </CopyToClipboard>
  );
}
