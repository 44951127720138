import React from 'react';
import { Typography } from '@mui/material';

import { GoBack } from '../GoBack';
import { usePageTitleStyles } from './usePageTitleStyles';

interface IPageTitleProps {
  title: string;
  withGoBackButton?: boolean;
}

export function PageTitle({ title, withGoBackButton = true }: IPageTitleProps) {
  const { classes } = usePageTitleStyles();

  return (
    <div className={classes.root}>
      {withGoBackButton && (
        <div className={classes.goBackWrap}>
          <GoBack />
        </div>
      )}
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
    </div>
  );
}
