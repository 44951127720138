import { uid } from 'react-uid';
import { Box, Typography } from '@mui/material';

import { VALIDATOR_MAX_COMMISSION_RATE } from '../../../../../common/const';
import { useCurrentNetwork } from '../../../../../common/hooks/useCurrentNetwork';
import { t, tHTML } from '../../../../../i18n/utils/intl';
import { useLocaleMemo } from '../../../../../i18n/utils/useLocaleMemo';
import { useNewValidatorInfoStyles } from './useNewValidatorInfoStyles';

export function NewValidatorInfo() {
  const { classes } = useNewValidatorInfoStyles();

  const { currentNetworkName } = useCurrentNetwork();

  const steps = useLocaleMemo(
    () => [
      {
        title: t('new-validator.info.ensure-compatibility'),
        desc: t('new-validator.info.ensure-compatibility-desc'),
      },
      {
        title: t('new-validator.info.understand-role'),
        desc: t('new-validator.info.understand-role-desc', {
          network: currentNetworkName,
        }),
      },
      {
        title: t('new-validator.info.required-fields'),
        desc: tHTML('new-validator.info.required-fields-desc', {
          maxCommissionRate: VALIDATOR_MAX_COMMISSION_RATE,
        }),
      },
      {
        title: t('new-validator.info.register-node'),
        desc: t('new-validator.info.register-node-desc'),
      },
    ],
    [currentNetworkName],
  );

  return (
    <Box className={classes.root}>
      <Typography variant="subtitle2" className={classes.title}>
        {t('new-validator.info.title')}
      </Typography>
      <Typography variant="body2" className={classes.text}>
        {t('new-validator.info.hint', { network: currentNetworkName })}
      </Typography>
      <ul className={classes.steps}>
        {steps.map((item, index) => (
          <li className={classes.step} key={uid(item)}>
            <Box className={classes.stepNum}>{index + 1}</Box>
            <Box className={classes.stepContent}>
              <Typography variant="subtitle1" className={classes.subTitle}>
                {item.title}
              </Typography>
              <Typography variant="body2" className={classes.text}>
                {item.desc}
              </Typography>
            </Box>
          </li>
        ))}
      </ul>
    </Box>
  );
}
