import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import { ReactComponent as AngleLeftIcon } from '../../../common/components/Icons/AngleLeftIcon.svg';
import { useCurrentNetwork } from '../../../common/hooks/useCurrentNetwork';
import { HomeRoutesConfig } from '../../../Home/HomeRoutes';
import { t } from '../../../i18n/utils/intl';
import { IconButton } from '../../../uiKit/IconButton';
import { useGoBackStyles } from './useGoBackStyles';

interface IGoBackProps {
  className?: string;
  backTo?: string;
}

export function GoBack({ className, backTo }: IGoBackProps) {
  const { classes, cx } = useGoBackStyles();
  const navigate = useNavigate();

  const { currentNetwork: network } = useCurrentNetwork();

  const handleBack = useCallback(() => {
    if (backTo) {
      navigate(backTo);
    } else if (window.history.state) {
      navigate(-1);
    } else {
      navigate(HomeRoutesConfig.Home.generatePath(network));
    }
  }, [navigate, backTo, network]);

  return (
    <Tooltip title={t('go-back.action')}>
      <IconButton
        color="primary"
        size="small"
        className={cx(classes.button, className)}
        onClick={handleBack}
      >
        <AngleLeftIcon className={classes.icon} />
      </IconButton>
    </Tooltip>
  );
}
