import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { QueryLoadingAbsolute } from './modules/common/components/QueryLoading';
import { ScrollToTop } from './modules/common/components/ScrollToTop';
import { AppBase } from './modules/layout/components/AppBase';
import { Routes } from './Routes';
import { persistor, store } from './store';

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<QueryLoadingAbsolute />} persistor={persistor}>
        <BrowserRouter>
          <AppBase>
            <ScrollToTop />
            <Routes />
          </AppBase>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
