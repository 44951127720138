import React, { ReactNode, useEffect, useState } from 'react';
import intl from 'react-intl-universal';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Box, CssBaseline, ThemeProvider } from '@mui/material';

import '../../../../assets/fonts/style.css';

import { useInitBasQuery } from '../../../Bas/actions/bas';
import { BasError } from '../../../Bas/types';
import { QueryLoadingAbsolute } from '../../../common/components/QueryLoading';
import { useCurrentNetwork } from '../../../common/hooks/useCurrentNetwork';
import { locales } from '../../../i18n';
import { useLocale } from '../../../i18n/utils/useLocale';
import { Notifications } from '../../../Notifications/components';
import { mainTheme } from '../../../themes/mainTheme';

interface IAppBaseProps {
  children: ReactNode;
}

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

export function AppBase({ children }: IAppBaseProps) {
  const [localeReady, setLocaleReady] = useState(false);

  const { currentNetwork } = useCurrentNetwork();

  const { data: basConfig, error: basConfigError } =
    useInitBasQuery(currentNetwork);

  const { locale } = useLocale();

  useEffect(() => {
    setLocaleReady(false);
    void intl
      .init({
        currentLocale: locale,
        locales,
        fallbackLocale: 'en-US',
      })
      .then(() => {
        setLocaleReady(true);
      });
  }, [locale]);

  // TODO: add design
  if (basConfigError === BasError.BasNotFound) {
    return <Box>BAS not found</Box>;
  }

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={mainTheme}>
        <>
          <CssBaseline />
          {!localeReady || !basConfig ? <QueryLoadingAbsolute /> : children}
          <Notifications />
        </>
      </ThemeProvider>
    </CacheProvider>
  );
}
