import React, { ReactNode, useEffect } from 'react';
import { ThemeProvider } from '@mui/material';

import { useCurrentNetwork } from '../../../common/hooks/useCurrentNetwork';
import { usePageTitle } from '../../../common/hooks/usePageTitle';
import { mainTheme } from '../../../themes/mainTheme';
import { Header } from '../Header';
import { useLayoutDefaultStyles } from './useLayoutDefaultStyles';

export interface ILayoutDefaultProps {
  children?: ReactNode;
  title?: string;
}

export function LayoutDefault({ children, title }: ILayoutDefaultProps) {
  const { classes } = useLayoutDefaultStyles();

  const { checkAndRedirect } = useCurrentNetwork();

  useEffect(() => {
    checkAndRedirect();
  }, [checkAndRedirect]);

  usePageTitle(title);

  return (
    <ThemeProvider theme={mainTheme}>
      <div className={classes.root}>
        <Header />
        <main className={classes.main}>{children}</main>
      </div>
    </ThemeProvider>
  );
}
