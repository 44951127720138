import { ReactNode } from 'react';
import { EEthereumNetworkId } from '@ankr.com/provider';

import { ReactComponent as BinanceChainIcon } from '../../common/components/Icons/BinanceIcon.svg';

export function getChainIcon(chainId: EEthereumNetworkId): ReactNode {
  switch (chainId) {
    case EEthereumNetworkId.smartchain:
    case EEthereumNetworkId.smartchainTestnet:
      return <BinanceChainIcon />;
    default:
      return null;
  }
}
