import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { TypeOptions } from 'react-toastify/dist/types';
import { NoSsr } from '@mui/material';

import 'react-toastify/dist/ReactToastify.min.css';

import { Milliseconds } from '../../common/types/unit';
import { t } from '../../i18n/utils/intl';
import { useThemeMode } from '../../themes/hooks/useTheme';
import { useNotificationsStyles } from './useNotificationsStyles';

export interface INotificationProps {
  key?: string | number;
  message: string;
  type: TypeOptions;
  date?: string;
  withoutTitle?: boolean;
  title?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  autoClose?: Milliseconds | false;
  // eslint-disable-next-line react/no-unused-prop-types
  closeOnClick?: boolean;
  readMoreLink?: string;
}

function NotificationRender({
  key,
  message,
  type,
  date,
  withoutTitle,
  title,
  readMoreLink,
}: INotificationProps) {
  const { classes } = useNotificationsStyles();

  return (
    <div className={classes.content} key={key}>
      {!!date && <div className={classes.date}>{date}</div>}
      {!withoutTitle && (
        <div className={classes.title}>
          {title || (type !== 'default' ? t(`alert.${type}`) : '')}
        </div>
      )}
      <div className={classes.message}>{message}</div>
      {!!readMoreLink && (
        <a
          className={classes.readMoreLink}
          href={readMoreLink}
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('common.read-more')}
        </a>
      )}
    </div>
  );
}

export const notification = ({
  key,
  message,
  type = 'default',
  date,
  withoutTitle = false,
  title,
  autoClose = 7000,
  closeOnClick = true,
  readMoreLink,
}: INotificationProps) => {
  const options = {
    type,
    autoClose,
    closeOnClick,
  };

  toast(
    <NotificationRender
      key={key}
      message={message}
      type={type}
      date={date}
      withoutTitle={withoutTitle}
      title={title}
      readMoreLink={readMoreLink}
    />,
    options,
  );
};

export function Notifications() {
  const { classes } = useNotificationsStyles();

  const themeMode = useThemeMode();

  return (
    <NoSsr>
      <ToastContainer
        className={classes.root}
        position="bottom-left"
        theme={themeMode}
        newestOnTop
        toastClassName={classes.toast}
        bodyClassName={classes.toastBody}
      />
    </NoSsr>
  );
}
