import { Link } from 'react-router-dom';
import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';

import { useCurrentNetwork } from '../../../../../common/hooks/useCurrentNetwork';
import { HomeRoutesConfig } from '../../../../../Home/HomeRoutes';
import { t } from '../../../../../i18n/utils/intl';
import { Button } from '../../../../../uiKit/Button';
import { useNewValidatorCompleteModalStyles } from './useNewValidatorCompleteModalStyles';

interface INewValidatorCompleteModalProps {
  onClose: () => void;
  open?: boolean;
}

export function NewValidatorCompleteModal({
  onClose,
  open = false,
}: INewValidatorCompleteModalProps) {
  const { classes } = useNewValidatorCompleteModalStyles();

  const { currentNetwork } = useCurrentNetwork();

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      classes={{ paper: classes.paper }}
    >
      <DialogTitle className={classes.title}>
        {t('new-validator.modal.title')}
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Typography variant="body2" className={classes.text}>
          {t('new-validator.modal.text')}
        </Typography>
        <Button
          fullWidth
          component={Link}
          to={HomeRoutesConfig.Home.generatePath(currentNetwork)}
          size="large"
          onClick={onClose}
        >
          {t('new-validator.modal.button')}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
