import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../../../../../themes/const';

export const useHeaderNavigationBtnStyles = makeStyles()(theme => ({
  btn: {
    fontSize: 16,
    fontFamily: FONTS.medium,
    fontWeight: 500,
    color: theme.palette.text.disabled,
    textDecoration: 'none',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    '&:hover': {
      color: theme.palette.text.primary,
    },
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
      alignItems: 'center',
      padding: 0,
      fontSize: 20,
      fontFamily: FONTS.bold,
      fontWeight: 700,
    },
  },
  btnActive: {
    color: theme.palette.text.primary,
  },
}));
