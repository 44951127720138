import { Locale } from '../types/locale';

interface ISeparatorsCache {
  [key: string]: string;
}

const separatorsCache: ISeparatorsCache = {};

export const getLocaleSeparator = (locale: Locale, separatorType = 'group') => {
  const cacheKey = `${locale}_${separatorType}`;
  if (!separatorsCache[cacheKey]) {
    const separator = new Intl.NumberFormat(locale)
      ?.formatToParts(100000.1)
      .find(part => part.type === separatorType)?.value;
    if (separator) {
      separatorsCache[cacheKey] = separator;
    }
  }
  return separatorsCache[cacheKey];
};
