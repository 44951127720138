import Web3 from 'web3';
import { TransactionConfig } from 'web3-core';
import { numberToHex } from 'web3-utils';

import { Address } from '../../common/types';
import { IBasAppConfig, IPendingTx } from '../models';
import { BasError } from '../types';

export const waitForExpectedNetworkOrThrow = async (
  web3: Web3,
  config: {
    chainId: number;
    chainName: string;
    rpcUrl: string;
  },
): Promise<void> => {
  if (!web3.givenProvider.request) {
    throw new Error(
      `Wallet doesn't support switching to the ${config.chainName} network, please switch it manually`,
    );
  }
  try {
    await web3.givenProvider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: numberToHex(config.chainId) }],
    });
  } catch (error) {
    console.error('FAILED TO ADD');
    console.error(error);
    // This error code indicates that the chain has not been added to MetaMask.
    if ((error as any).code === 4902) {
      if (await tryAddMetaMaskNetwork(web3, config)) {
        return;
      }
      throw new Error(
        `Network for ${config.chainName} is not configured in your MetaMask`,
      );
    }
    throw new Error(`Unable to switch network to ${config.chainName}`);
  }
};

export const tryAddMetaMaskNetwork = async (
  web3: Web3,
  config: {
    chainId: number;
    chainName: string;
    rpcUrl: string;
  },
): Promise<boolean> => {
  try {
    console.log(`Trying to switch MetaMask network to: ${config.chainId}`);
    await web3.givenProvider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: numberToHex(config.chainId) }],
    });
    return true;
  } catch (switchError) {
    if ((switchError as any).code !== 4902) {
      console.error(switchError);
      return false;
    }
  }
  try {
    console.log(`Trying to add MetaMask network to: ${config.chainId}`);
    await web3.givenProvider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: numberToHex(config.chainId),
          chainName: config.chainName,
          rpcUrls: [config.rpcUrl],
        },
      ],
    });
    return true;
  } catch (addError) {
    console.error(addError);
  }
  return false;
};

const switchWeb3Network = (web3: Web3, chainId: number) => {
  web3.givenProvider.request({
    method: 'wallet_switchEthereumChain',
    params: [{ chainId: numberToHex(chainId) }],
  });
};

// const remoteChainId = await web3.eth.getChainId();
// if (remoteChainId !== this.config.chainId) {
//   await waitForExpectedNetworkOrThrow(web3, this.config);
// }

export const sendWeb3Tx = async (
  web3: Web3 | undefined,
  sendOptions: {
    from: Address;
    to: Address;
    data?: string;
    gasLimit?: string;
    value?: string;
    nonce?: number;
  },
): Promise<IPendingTx> => {
  if (!web3) {
    throw BasError.NoWeb3;
  }
  const gasPrice = await web3.eth.getGasPrice();
  let { nonce } = sendOptions;
  if (!nonce) {
    nonce = await web3.eth.getTransactionCount(sendOptions.from);
  }
  const chainId = await web3.eth.getChainId();

  const tx: TransactionConfig = {
    from: sendOptions.from,
    to: sendOptions.to,
    value: numberToHex(sendOptions.value || '0'),
    gas: numberToHex(sendOptions.gasLimit || '1000000'),
    gasPrice,
    data: sendOptions.data,
    nonce,
    chainId,
  };

  const gasEstimation = await web3.eth.estimateGas(tx);

  if (
    sendOptions.gasLimit &&
    Number(gasEstimation) > Number(sendOptions.gasLimit)
  ) {
    throw new Error(
      `Gas estimation exceeds possible limit (${Number(
        gasEstimation,
      )} > ${Number(sendOptions.gasLimit)})`,
    );
  }
  return new Promise((resolve, reject) => {
    const promise = web3.eth.sendTransaction(tx);
    promise
      .once('transactionHash', async (transactionHash: string) => {
        console.log(`Just signed transaction has is: ${transactionHash}`);
        const rawTx = await web3.eth.getTransaction(transactionHash);
        console.log(
          `Found transaction in node: `,
          JSON.stringify(rawTx, null, 2),
        );
        resolve({ transactionHash, receipt: promise });
      })
      .catch(reject);
  });
};
export const addExpectedNetwork = async (
  web3: Web3,
  appConfig: IBasAppConfig,
) => {
  try {
    return await web3.givenProvider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: numberToHex(appConfig.chainId) }],
    });
  } catch (e) {
    if ((e as any).code !== 4902) {
      throw BasError.ChainSwitching;
    }
  }

  try {
    return await web3.givenProvider.request({
      method: 'wallet_addEthereumChain',
      params: [
        {
          chainId: numberToHex(appConfig.chainId),
          chainName: appConfig.chainName,
          rpcUrls: [appConfig.rpcUrl],
        },
      ],
    });
  } catch (e) {
    throw BasError.ChainAdd;
  }
};
export const changeNetwork = async (web3: Web3, appConfig: IBasAppConfig) => {
  if (!web3.givenProvider.request) {
    throw BasError.NoSwitching;
  }

  try {
    await web3.givenProvider.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: numberToHex(appConfig.chainId) }],
    });
  } catch (error) {
    // This error code indicates that the chain has not been added to MetaMask.
    if ((error as any).code === 4902) {
      await addExpectedNetwork(web3, appConfig);
    }
    throw BasError.UnableToSwitchNetwork;
  }
};

export const getAccounts = async (web3: Web3 | undefined) => {
  if (!web3) {
    throw BasError.NoWeb3;
  }
  let accounts: string[];
  try {
    accounts = await web3.eth.requestAccounts();
  } catch (e) {
    throw BasError.AccountsAccess;
  }
  if (!accounts.length || !accounts[0]) {
    throw BasError.AccountsDetection;
  }
  return accounts;
};
