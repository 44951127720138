import { t } from '../../i18n/utils/intl';
import { isString } from './validation';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function extractError(error: any) {
  if (isString(error)) {
    return error.toString();
  }

  if (error instanceof Error) {
    return error.toString();
  }

  if (error.message) {
    try {
      return JSON.parse(error.message);
    } catch (_error) {
      return error.message.toString();
    }
  }

  if (error.errors[0].message) {
    return error.errors[0].message.toString();
  }

  if (!error) {
    return t('error.unexpected');
  }

  return t('error.unexpected');
}
