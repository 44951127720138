import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';

import { useGetChainDataQuery } from '../../../../../../../Bas/actions/bas';
import { IValidator } from '../../../../../../../Bas/models';
import {
  AppList,
  IAppListColumn,
} from '../../../../../../../common/components/AppList';
import { useCurrentNetwork } from '../../../../../../../common/hooks/useCurrentNetwork';
import { t } from '../../../../../../../i18n/utils/intl';
import { useLocaleMemo } from '../../../../../../../i18n/utils/useLocaleMemo';
import { Stake } from '../../../../../../../Staking/components/Stake';
import { Unstake } from '../../../../../../../Staking/components/Unstake';
import { useIsMDDown } from '../../../../../../../themes/hooks/useTheme';
import { Button } from '../../../../../../../uiKit/Button';
import { CopyButton } from '../../../../../../../uiKit/CopyButton';
import { ValidatorRoutesConfig } from '../../../../../../../Validator/ValidatorRoutes';
import { useStakedAssetsStyles } from './useStakedAssetsStyles';

export function StakedAssets() {
  const { classes } = useStakedAssetsStyles();

  const isMDDown = useIsMDDown();

  const { currentNetwork: network } = useCurrentNetwork();

  const { data: chainData, isLoading } = useGetChainDataQuery();

  const [stakeValidator, setStakeValidator] = useState<string | null>();
  const [unstakeValidator, setUnstakeValidator] = useState<IValidator | null>();

  const handleUnstakeClick = useCallback((v: IValidator) => {
    setUnstakeValidator(v);
  }, []);

  const handleUnstakeClose = useCallback(() => {
    setUnstakeValidator(null);
  }, []);

  const handleStakeMoreClick = useCallback((v: IValidator) => {
    setStakeValidator(v.address);
  }, []);

  const handleStakeMoreClose = useCallback(() => {
    setStakeValidator(null);
  }, []);

  const columns: IAppListColumn<IValidator>[] = useLocaleMemo(
    () => [
      {
        header: <Box>{t('dashboard.amount')}</Box>,
        renderCell: item =>
          t('number.accurate', { value: item.delegatedAmountNomination }),
      },
      {
        header: <Box>{t('dashboard.validator')}</Box>,
        className: classes.validatorColumn,
        renderCell: item => (
          <>
            <Tooltip title={item.address}>
              <Link
                to={ValidatorRoutesConfig.Validator.generatePath({
                  address: item.address,
                  network,
                })}
              >
                {isMDDown ? item.truncatedAddress : item.address}
              </Link>
            </Tooltip>
            <CopyButton text={item.address} />
          </>
        ),
      },
      {
        header: <Box>{t('dashboard.apy')}</Box>,
        // TODO: need data
        renderCell: item => 0,
      },
      {
        header: null,
        className: classes.toolbarColumn,
        cellClassName: classes.toolbarCell,
        renderCell: item =>
          // TODO: need condition
          false ? (
            <Button
              variant="text"
              size="small"
              className={classes.toolbarBtn}
              disabled
            >
              {t('dashboard.pending')}
            </Button>
          ) : (
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={() => handleUnstakeClick(item)}
                className={classes.toolbarBtn}
              >
                {t('common.unstake')}
              </Button>
              <Button
                variant="contained"
                size="small"
                onClick={() => handleStakeMoreClick(item)}
                className={classes.toolbarBtn}
              >
                {t('common.stake-more')}
              </Button>
            </>
          ),
      },
    ],
    [classes, handleUnstakeClick, handleStakeMoreClick, isMDDown, network],
  );

  return (
    <>
      <AppList
        items={chainData?.delegatedValidators || []}
        isLoading={isLoading}
        columns={columns}
      />
      {stakeValidator && (
        <Stake onClose={handleStakeMoreClose} validator={stakeValidator} />
      )}
      {unstakeValidator && (
        <Unstake onClose={handleUnstakeClose} validator={unstakeValidator} />
      )}
    </>
  );
}
