import { Link } from 'react-router-dom';
import { Box, Container, Typography } from '@mui/material';

import { useCurrentNetwork } from '../../../../../common/hooks/useCurrentNetwork';
import { useQueryParam } from '../../../../../common/hooks/useQueryParam';
import { t } from '../../../../../i18n/utils/intl';
import { useIsSMDown } from '../../../../../themes/hooks/useTheme';
import { Button } from '../../../../../uiKit/Button';
import { ValidatorRoutesConfig } from '../../../../../Validator/ValidatorRoutes';
import { HomeUrlQueryParams, ValidatorsType } from '../../../../common';
import { ActiveValidators } from './components/ActiveValidators';
import { PendingValidators } from './components/PendingValidators';
import { useValidatorsStyles } from './useValidatorsStyles';

export function Validators() {
  const { classes, cx } = useValidatorsStyles();

  const { currentNetwork: network } = useCurrentNetwork();

  const isSMDown = useIsSMDown();

  const [validatorType, setValidatorType] = useQueryParam(
    HomeUrlQueryParams.Validators,
    ValidatorsType.Active,
  );

  return (
    <Box className={classes.root}>
      <Container>
        <Box className={classes.top}>
          <Box className={classes.left}>
            <Typography
              variant="subtitle1"
              className={cx(
                classes.menuItem,
                validatorType === ValidatorsType.Active &&
                  classes.menuItemActive,
              )}
              onClick={() => setValidatorType(ValidatorsType.Active)}
            >
              {t('dashboard.active-validators')}
            </Typography>
            <Typography
              variant="subtitle1"
              className={cx(
                classes.menuItem,
                validatorType === ValidatorsType.Pending &&
                  classes.menuItemActive,
              )}
              onClick={() => setValidatorType(ValidatorsType.Pending)}
            >
              {t('dashboard.pending-validators')}
            </Typography>
          </Box>
          <Box className={classes.right}>
            <Button
              variant={isSMDown ? 'contained' : 'text'}
              component={Link}
              className={classes.registerBtn}
              to={ValidatorRoutesConfig.ValidatorNew.generatePath(network)}
            >
              {t('dashboard.register-validator')}
            </Button>
          </Box>
        </Box>
        <Box className={classes.listWrapper}>
          {validatorType === ValidatorsType.Active && <ActiveValidators />}
          {validatorType === ValidatorsType.Pending && <PendingValidators />}
        </Box>
      </Container>
    </Box>
  );
}
