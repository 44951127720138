import * as React from 'react';
import { Paper } from '@mui/material';

import { t } from '../../../i18n/utils/intl';
import { useQueryEmptyStyles } from './useQueryEmptyStyles';

export function QueryEmpty() {
  const { classes } = useQueryEmptyStyles();

  return <Paper className={classes.root}>{t('common.no-data')}</Paper>;
}
