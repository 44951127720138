export enum BasError {
  NoWeb3 = 'NoWeb3',
  NoWallet = 'NoWallet',
  NoProvider = 'NoProvider',
  NoAccount = 'NoAccount',
  RequestAccount = 'RequestAccount',
  NoSwitching = 'NoSwitching',
  ChainSwitching = 'ChainSwitching',
  ChainAdd = 'ChainAdd',
  UnableToSwitchNetwork = 'UnableToSwitchNetwork',
  NotConfiguredNetwork = 'NotConfiguredNetwork',
  AccountsAccess = 'AccountsAccess',
  AccountsDetection = 'AccountsDetection',
  Connection = 'Connection',
  BasNotFound = 'BasNotFound',
}

export enum BasAction {
  Delegate = 'Delegate',
  UnDelegate = 'UnDelegate',
  Claim = 'Claim',
}

export enum BasEvent {
  Claimed = 'Claimed',
  Delegated = 'Delegated',
  Undelegated = 'Undelegated',
}

export enum ValidatorStatus {
  NotFound = '0',
  Active = '1',
  Pending = '2',
  Jailed = '3',
}
