import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../../../../../../../themes/const';

export const useStakingStatsStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    columnGap: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(4),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
    },
  },
  statItem: {
    padding: theme.spacing(6, 5),
    backgroundColor: theme.palette.common.white,
    borderRadius: 18,
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 4),
    },
  },
  statFirst: {},
  statSecond: {},
  statTop: {
    fontSize: 16,
    fontWeight: 500,
    fontFamily: FONTS.medium,
    color: theme.palette.grey['500'],
  },
  statBottom: {
    marginTop: theme.spacing(24),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(8.5),
    },
  },
  statValue: {
    fontSize: 34,
    fontWeight: 700,
    fontFamily: FONTS.bold,
    color: theme.palette.grey['900'],
  },
  statHint: {
    marginTop: theme.spacing(2.5),
    fontSize: 14,
    fontWeight: 500,
    fontFamily: FONTS.medium,
    color: theme.palette.grey['500'],
  },
}));
