import Dialog from '@mui/material/Dialog';
import { DialogProps } from '@mui/material/Dialog/Dialog';

import { useAppModalStyles } from './useAppModalStyles';

export function AppModal({ children, ...props }: DialogProps) {
  const { classes } = useAppModalStyles();
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      PaperProps={{ className: classes.root }}
      {...props}
    >
      <>
        {/* <IconButton></IconButton> */}
        {children}
      </>
    </Dialog>
  );
}
