import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as LogoFullIcon } from '../../../common/components/Icons/LogoFullIcon.svg';
import { useCurrentNetwork } from '../../../common/hooks/useCurrentNetwork';
import { HomeRoutesConfig } from '../../../Home/HomeRoutes';
import { useLogoStyles } from './useLogoStyles';

export function Logo() {
  const { classes } = useLogoStyles();

  const { currentNetwork: network } = useCurrentNetwork();

  return (
    <Link
      to={HomeRoutesConfig.Home.generatePath(network)}
      className={classes.root}
    >
      <LogoFullIcon className={classes.img} />
    </Link>
  );
}
