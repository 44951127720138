import { createSlice } from '@reduxjs/toolkit';

import { RootState } from 'store';

export const LAYOUT_STATE_NAME = 'layout';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ILayoutState {}

const initialState: ILayoutState = {};

export const layoutSlice = createSlice({
  name: LAYOUT_STATE_NAME,
  initialState,
  reducers: {},
});

export const layoutActions = {
  ...layoutSlice.actions,
};

export const layoutReducer = layoutSlice.reducer;

const selectLayout = (state: RootState) => state[LAYOUT_STATE_NAME];

export const layoutSelectors = {
  [LAYOUT_STATE_NAME]: selectLayout,
};
