import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';

import { useGetChainDataQuery } from '../../../../../../../Bas/actions/bas';
import { IValidator } from '../../../../../../../Bas/models';
import {
  AppList,
  IAppListColumn,
} from '../../../../../../../common/components/AppList';
import { useCurrentNetwork } from '../../../../../../../common/hooks/useCurrentNetwork';
import { t } from '../../../../../../../i18n/utils/intl';
import { useLocaleMemo } from '../../../../../../../i18n/utils/useLocaleMemo';
import { Claim } from '../../../../../../../Staking/components/Claim';
import { Button } from '../../../../../../../uiKit/Button';
import { CopyButton } from '../../../../../../../uiKit/CopyButton';
import { ValidatorRoutesConfig } from '../../../../../../../Validator/ValidatorRoutes';
import { useClaimableAssetsStyles } from './useClaimableAssetsStyles';

export function ClaimableAssets() {
  const { classes } = useClaimableAssetsStyles();

  const [restakeValidator, setRestakeValidator] = useState<string | null>();
  const [claimFrom, setClaimFrom] = useState<IValidator | null>();
  const [claimAll, setClaimAll] = useState<boolean>(false);

  const { currentNetwork: network } = useCurrentNetwork();

  const { data: chainData, isLoading } = useGetChainDataQuery();

  // const handleRestakeClick = useCallback((v: IValidator) => {
  //   setRestakeValidator(v.address);
  // }, []);

  // const handleRestakeClose = useCallback(() => {
  //   setRestakeValidator(null);
  // }, []);

  const handleClaimClick = useCallback((v: IValidator) => {
    setClaimFrom(v);
  }, []);

  const handleClaimClose = useCallback(() => {
    setClaimFrom(null);
  }, []);

  // const handleClaimAllClick = useCallback(() => {
  //   setClaimAll(true);
  // }, []);

  // const handleClaimAllClose = useCallback(() => {
  //   setClaimAll(false);
  // }, []);

  const columns: IAppListColumn<IValidator>[] = useLocaleMemo(
    () => [
      {
        header: <Box>{t('dashboard.staking-rewards')}</Box>,
        cellClassName: classes.withBtn,
        renderCell: (item: IValidator) => (
          <>
            <Box>
              {t('number.accurate', { value: item.rewardAmountNomination })}
            </Box>
            {/* <Button
              variant="outlined"
              size="small"
              onClick={() => handleRestakeClick(item)}
            >
              {t('common.restake')}
            </Button> */}
          </>
        ),
      },
      {
        header: <Box>{t('dashboard.pending-rewards')}</Box>,
        renderCell: item =>
          t('number.accurate', { value: item.pendingFeeNomination }),
      },
      // {
      //   header: <Box>{t('dashboard.pending-unstakes')}</Box>,
      //   renderCell: item =>
      //     t('number.accurate', { value: item.pendingUnstakes }),
      // },
      // {
      //   header: <Box>{t('dashboard.claimable-unstakes')}</Box>,
      //   cellClassName: classes.withBtn,
      //   renderCell: item => (
      //     <>
      //       <Box>{t('number.accurate', { value: item.claimableUnstakes })}</Box>
      //       <Button
      //         variant="outlined"
      //         size="small"
      //         onClick={() => handleClaimClick(item)}
      //       >
      //         {t('common.claim')}
      //       </Button>
      //     </>
      //   ),
      // },
      {
        header: <Box>{t('dashboard.validator')}</Box>,
        renderCell: item => (
          <>
            <Tooltip title={item.address}>
              <Link
                to={ValidatorRoutesConfig.Validator.generatePath({
                  address: item.address,
                  network,
                })}
              >
                {item.truncatedAddress}
              </Link>
            </Tooltip>
            <CopyButton text={item.address} />
          </>
        ),
      },
      {
        header: null,
        cellClassName: classes.toolbarCell,
        className: classes.toolbarColumn,
        renderCell: item => (
          <Button
            variant="contained"
            size="small"
            onClick={() => handleClaimClick(item)}
            className={classes.toolbarBtn}
          >
            {t('common.claim')}
          </Button>
        ),
      },
    ],
    [classes, handleClaimClick, network],
  );

  return (
    <>
      <AppList
        items={chainData?.claimableValidators || []}
        isLoading={isLoading}
        columns={columns}
      />
      {/* {restakeValidator && ( */}
      {/*  <Restake onClose={handleRestakeClose} validator={restakeValidator} /> */}
      {/* )} */}
      {claimFrom && <Claim onClose={handleClaimClose} validator={claimFrom} />}
    </>
  );
}
