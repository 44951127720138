import { getUniqueId } from '../../common/utils/getUniqueId';

export const CacheTags = {
  balance: getUniqueId(),
  initBas: getUniqueId(),
  initWallet: getUniqueId(),
  account: getUniqueId(),
  chainData: getUniqueId(),
  history: getUniqueId(),
};
