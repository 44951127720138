import { useCallback, useMemo, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { Box } from '@mui/material';

import { t } from 'modules/i18n/utils/intl';

import { useUnStakeMutation } from '../../../Bas/actions/bas';
import { IValidator } from '../../../Bas/models';
import { YourBalance } from '../../../common/components/YourBalance';
import { APP_CHAIN_LOCK_PERIOD, ZERO } from '../../../common/const';
import { useCurrentNetwork } from '../../../common/hooks/useCurrentNetwork';
import { Address } from '../../../common/types';
import { isNumber, isValidETHAddress } from '../../../common/utils/validation';
import { InputField } from '../../../form/components/InputField';
import { NumberFormatField } from '../../../form/components/NumberFormatField';
import { FormErrors } from '../../../form/utils/FormErrors';
import { useLocaleMemo } from '../../../i18n/utils/useLocaleMemo';
import { Button } from '../../../uiKit/Button';
import { InfoHelperBlock } from '../../../uiKit/InfoBlock';
import { ProgressStatus } from '../../types';
import { InfoBlock } from '../InfoBlock';
import {
  IStakeModalContent,
  IStakeModalRenderer,
  StakingModal,
} from '../StakingModal';

interface IUnstakeForm {
  validator: Address;
  amount: number;
}

interface UnstakeProps {
  validator: IValidator;
  onClose: () => void;
}

export function Unstake({ validator, onClose }: UnstakeProps) {
  const { currentNetworkName } = useCurrentNetwork();

  const [status, setStatus] = useState<ProgressStatus>(ProgressStatus.Start);

  const [amount, setAmount] = useState(0);

  const [unStake, { isLoading, data: unStakeData }] = useUnStakeMutation();

  const handleSubmit = (v: IUnstakeForm) => {
    setAmount(v.amount);
    setStatus(ProgressStatus.Pending);
    void unStake({ validator: validator.address, amount: v.amount })
      .then(v => {
        setStatus(ProgressStatus.Success);
      })
      .catch(e => {
        setStatus(ProgressStatus.Failed);
      });
  };

  const validateForm = useCallback(
    (payload: IUnstakeForm) => {
      const errors: FormErrors<IUnstakeForm> = {};

      if (!payload.validator) {
        errors.validator = t('validation.required');
      } else if (!isValidETHAddress(payload.validator)) {
        errors.validator = t('validation.invalid-address');
      }

      if (!payload.amount) {
        errors.amount = t('validation.required');
      } else if (!isNumber(Number(payload.amount))) {
        errors.amount = t('validation.require-number');
      } else if (Number(payload.amount) < ZERO) {
        errors.amount = t('validation.require-positive');
      } else if (
        Number(payload.amount) > validator.delegatedAmountNomination!.toNumber()
      ) {
        errors.amount = t('less-than', {
          value: validator.delegatedAmountNomination,
        });
      }

      return errors;
    },
    [validator],
  );

  const renderForm = ({ handleSubmit }: FormRenderProps<IUnstakeForm>) => {
    return (
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Field
          component={NumberFormatField}
          name="amount"
          label={t('staking.enter-unstaking-amount')}
          helperText={t('staking.max-unstaking-with-value', {
            value: validator.delegatedAmountNomination,
          })}
          fullWidth
          autoFocus
          variant="filled"
          allowNegative={false}
          decimalScale={2}
          enterKeyHint="done"
          fixedDecimalScale
          maxValue={validator.delegatedAmountNomination?.toNumber()}
          sideHint={<YourBalance buttonVariant="text" smallView />}
        />

        <Field
          component={InputField}
          name="validator"
          label={t('staking.validator')}
          placeholder={t('new-validator.form.address-placeholder')}
          initialValue={validator.address}
          variant="filled"
          disabled
          fullWidth
        />

        <InfoHelperBlock withMarginBottom>
          {t('staking.unstake-hint-with-value', {
            name: currentNetworkName,
            value: t('time-unit.day', { value: APP_CHAIN_LOCK_PERIOD }),
          })}
        </InfoHelperBlock>

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          size="large"
        >
          {t('common.unstake')}
        </Button>
      </Box>
    );
  };

  const startContent: IStakeModalContent = useLocaleMemo(
    () => ({
      title: t('staking.unstake-title'),
      body: (
        <Form
          onSubmit={handleSubmit}
          render={renderForm}
          validate={validateForm}
        />
      ),
    }),
    [renderForm],
  );

  const pendingContent: IStakeModalContent = useLocaleMemo(
    () => ({
      title: t('staking.unstake-progress'),
      topHint: t('staking.pending-hint'),
      body: (
        <InfoBlock
          items={[
            [
              {
                left: t('staking.amount-label'),
                right: t('number.accurate', { value: amount }),
              },
            ],
            [{ left: t('staking.validator-label'), right: validator.address }],
          ]}
        />
      ),
      btnText: t('staking.go-dashboard'),
      onBtnClick: onClose,
    }),
    [onClose, amount],
  );

  const successContent: IStakeModalContent = useLocaleMemo(
    () => ({
      title: t('staking.unstake-successful'),
      body: (
        <InfoBlock
          items={[
            [
              {
                left: t('staking.amount-label'),
                right: t('number.accurate', { value: amount }),
              },
            ],
            [{ left: t('staking.validator-label'), right: validator.address }],
            [
              {
                left: t('staking.tx-label'),
                right: unStakeData,
              },
            ],
          ]}
        />
      ),
      btnText: t('staking.go-dashboard'),
      onBtnClick: onClose,
    }),
    [onClose, amount, unStakeData],
  );

  const failedContent: IStakeModalContent = useLocaleMemo(
    () => ({
      title: t('staking.stake-pending'),
      topHint: t('staking.pending-hint'),
      body: <Box>Failed</Box>,
      bottomHint: t('staking.unstake-hint-with-value', {
        name: currentNetworkName,
        value: t('time-unit.day', { value: APP_CHAIN_LOCK_PERIOD }),
      }),
      btnText: t('staking.go-dashboard'),
      onBtnClick: onClose,
    }),
    [onClose, currentNetworkName],
  );

  const renderer: IStakeModalRenderer = useMemo(
    () => ({
      [ProgressStatus.Start]: startContent,
      [ProgressStatus.Pending]: pendingContent,
      [ProgressStatus.Success]: successContent,
      [ProgressStatus.Failed]: failedContent,
    }),
    [startContent, pendingContent, successContent, failedContent],
  );

  return (
    <StakingModal open status={status} renderer={renderer} onClose={onClose} />
  );
}
