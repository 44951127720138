import BigNumber from 'bignumber.js';
import { EventData } from 'web3-eth-contract';

import { ETH_EXPONENT } from '../../common/const';
import { Address } from '../../common/types';
import { truncateWalletAddress } from '../../common/utils/truncateWalletAddress';
import {
  IDelegation,
  IHistory,
  IValidator,
  IWeb3Delegation,
  IWeb3Validator,
} from '../models';

export const mapHistory = (origin: EventData): IHistory => {
  return {
    amount: new BigNumber(origin.returnValues.amount).dividedBy(ETH_EXPONENT),
    epoch: +origin.returnValues.epoch,
    validator: origin.returnValues.validator,
    event: origin.event,
    blockNumber: origin.blockNumber,
    transactionHash: origin.transactionHash,
  };
};

export const mapHistoryList = (list: EventData[]): IHistory[] => {
  return list.map(item => mapHistory(item));
};

export const mapValidator = (
  origin: IWeb3Validator,
  address: Address,
): IValidator => {
  const totalDelegated = new BigNumber(origin.totalDelegated);
  return {
    ownerAddress: origin.ownerAddress,
    slashesCount: origin.slashesCount,
    status: origin.status,
    commissionRate: origin.commissionRate,
    jailedBefore: origin.jailedBefore,
    changedAt: new Date(origin.changedAt),
    claimedAt: new Date(origin.claimedAt),
    totalDelegated,
    totalRewards: new BigNumber(origin.totalRewards),
    address,
    truncatedAddress: truncateWalletAddress(address),
    totalStakedNomination: totalDelegated.dividedBy(ETH_EXPONENT),
  };
};

export const mapDelegation = (origin: IWeb3Delegation): IDelegation => ({
  delegatedAmount: new BigNumber(origin.delegatedAmount),
  atEpoch: Number.parseInt(origin.atEpoch, 10),
});
