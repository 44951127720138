import { EXPLORER_URL } from '../const';
import { HashType } from '../types';

const typeToPath: Record<HashType, string> = {
  [HashType.Tx]: 'tx',
  [HashType.Address]: 'address',
  [HashType.Block]: 'blocks',
};

export const generateExplorerPath = (
  network: string,
  type: HashType,
  value: string | number,
) => {
  return `${EXPLORER_URL}/${network}/${typeToPath[type]}/${value}`;
};
