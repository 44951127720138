import { EWalletId } from '@ankr.com/provider';
import { createApi, fakeBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import BigNumber from 'bignumber.js';

import { BAS } from '../index';
import { IBasAppConfig, IChainData, IHistory } from '../models';
import { BasError } from '../types';
import { CacheTags } from '../utils/cacheTags';

export const {
  reducer,
  reducerPath,
  middleware,
  useInitBasQuery,
  useGetAppConfigQuery,
  useGetAccountQuery,
  useInitWalletQuery,
  useConnectWalletMutation,
  useRegisterValidatorMutation,
  useStakeMutation,
  useUnStakeMutation,
  useClaimFeeMutation,
  useGetChainDataQuery,
  useGetHistoryQuery,
  useDisconnectWalletMutation,
  useGetBalanceQuery,
} = createApi({
  refetchOnMountOrArgChange: true,
  reducerPath: 'basApi',
  baseQuery: fakeBaseQuery<BasError>(),
  tagTypes: [...Object.values(CacheTags)],
  endpoints: build => ({
    initBas: build.query<boolean, string>({
      providesTags: [CacheTags.initBas],
      queryFn: async (network: string) => {
        await BAS.initApp(network);
        return { data: true };
      },
    }),
    initWallet: build.query<number, void>({
      providesTags: [CacheTags.initWallet],
      queryFn: async () => {
        try {
          await BAS.initWallet();
        } catch (e) {
          return { error: e as BasError };
        }
        return { data: Date.now() };
      },
    }),
    getAppConfig: build.query<IBasAppConfig | undefined, void>({
      queryFn: () => {
        return { data: BAS.appConfig };
      },
    }),
    getAccount: build.query<string | undefined, void>({
      providesTags: [CacheTags.account],
      queryFn: () => {
        return { data: BAS.account };
      },
    }),
    getBalance: build.query<BigNumber, void>({
      providesTags: [CacheTags.balance],
      queryFn: async () => {
        const data = await BAS.getBalance();
        return { data };
      },
    }),
    connectWallet: build.mutation<boolean, EWalletId>({
      queryFn: async (walletID: EWalletId) => {
        await BAS.connectWallet(walletID);
        return { data: true };
      },
      invalidatesTags: [CacheTags.initWallet],
    }),
    disconnectWallet: build.mutation<boolean, void>({
      queryFn: () => {
        BAS.disconnectWallet();
        return { data: true };
      },
      invalidatesTags: [
        CacheTags.initWallet,
        CacheTags.balance,
        CacheTags.account,
      ],
    }),
    getChainData: build.query<IChainData, void>({
      providesTags: [CacheTags.chainData],
      queryFn: async () => {
        const res = await BAS.getChainData();
        return { data: res };
      },
    }),
    getHistory: build.query<IHistory[], void>({
      providesTags: [CacheTags.history],
      queryFn: async () => {
        const res = await BAS.getHistory();
        return { data: res };
      },
    }),
    registerValidator: build.mutation({
      queryFn: async ({ validator, commissionRate, initialStake }) => {
        const res = await BAS.registerValidator({
          validator,
          commissionRate,
          initialStake,
        });
        return { data: res };
      },
      invalidatesTags: [
        CacheTags.balance,
        CacheTags.history,
        CacheTags.chainData,
      ],
    }),
    stake: build.mutation({
      queryFn: async ({ validator, amount }) => {
        const transactionHash = await BAS.delegate(validator, amount);

        return { data: { transactionHash } };
      },
      invalidatesTags: [CacheTags.balance, CacheTags.chainData],
    }),
    unStake: build.mutation({
      queryFn: async ({ validator, amount }) => {
        const res = await BAS.unDelegate(validator, amount);
        return { data: res };
      },
      invalidatesTags: [CacheTags.balance, CacheTags.chainData],
    }),
    claimFee: build.mutation({
      queryFn: async ({ validator }) => {
        console.log('claimFee 1');
        const res = await BAS.claimDelegatorFee(validator);
        console.log('claimFee 2');
        return { data: res };
      },
      invalidatesTags: [CacheTags.balance, CacheTags.chainData],
    }),
    getMinStakingAmount: build.query<BigNumber, void>({
      queryFn: async () => {
        const res = await BAS.getMinStakingAmount();
        return { data: res };
      },
    }),
  }),
});
