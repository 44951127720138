import { useCallback } from 'react';
import { uid } from 'react-uid';
import { EWalletId } from '@ankr.com/provider';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import { isMobile } from 'web3modal';

import { useConnectWalletMutation } from '../../../Bas/actions/bas';
import { ReactComponent as CrossIcon } from '../../../common/components/Icons/CrossIcon.svg';
import { ReactComponent as HuobiIcon } from '../../../common/components/Icons/HuobiIcon.svg';
import { ReactComponent as ImTokenIcon } from '../../../common/components/Icons/ImTokenIcon.svg';
import { ReactComponent as MathWalletIcon } from '../../../common/components/Icons/MathWalletIcon.svg';
import { ReactComponent as MetamaskIcon } from '../../../common/components/Icons/MetamaskIcon.svg';
import { ReactComponent as TrustWalletIcon } from '../../../common/components/Icons/TrustWalletIcon.svg';
import { ReactComponent as WalletConnectIcon } from '../../../common/components/Icons/WalletConnectIcon.svg';
import { t } from '../../../i18n/utils/intl';
import { IconButton } from '../../../uiKit/IconButton';
import { useAuthModalStyles } from './useAuthModalStyles';

interface Wallet {
  title: string;
  icon: JSX.Element;
  walletId: EWalletId;
  isHidden: boolean;
}

const availableWallets: Wallet[] = [
  {
    title: 'MetaMask',
    icon: <MetamaskIcon />,
    walletId: EWalletId.injected,
    isHidden: isMobile(),
  },
  {
    title: 'WalletConnect',
    icon: <WalletConnectIcon />,
    walletId: EWalletId.walletconnect,
    isHidden: false,
  },
  {
    title: 'imToken',
    icon: <ImTokenIcon />,
    walletId: EWalletId.imtoken,
    isHidden: false,
  },
  {
    title: 'Math Wallet',
    icon: <MathWalletIcon />,
    walletId: EWalletId.math,
    isHidden: false,
  },
  {
    title: 'Trust Wallet',
    icon: <TrustWalletIcon />,
    walletId: EWalletId.trust,
    isHidden: false,
  },
  {
    title: 'Huobi Wallet',
    icon: <HuobiIcon />,
    walletId: EWalletId.huobi,
    isHidden: false,
  },
];

interface IAuthModalProps {
  onClose: () => void;
  open?: boolean;
}

export function AuthModal({
  onClose,
  open = false,
}: IAuthModalProps): JSX.Element {
  const { classes } = useAuthModalStyles();
  const [connectWallet, { isLoading }] = useConnectWalletMutation();

  const handleConnect = useCallback(
    async (walletId: EWalletId) => {
      if (walletId) {
        await connectWallet(walletId);
      }
    },
    [connectWallet],
  );

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle className={classes.title}>
        {t('common.connect-wallet')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeBtn}
          color="inherit"
          size="large"
        >
          <CrossIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Box className={classes.list}>
          {availableWallets.map(item => {
            return item.isHidden ? null : (
              <Button
                key={uid(item)}
                type="button"
                onClick={() => handleConnect(item.walletId)}
                className={classes.button}
                disabled={isLoading}
                variant="outlined"
                color="secondary"
                startIcon={item.icon}
              >
                {item.title}
              </Button>
            );
          })}
        </Box>
      </DialogContent>
    </Dialog>
  );
}
