import Web3 from 'web3';

export const isValidEmail = (email: string): boolean => {
  const emailRegex =
    /^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(String(email).toLowerCase());
};

export const isValidWebsiteUrl = (url: string): boolean => {
  const urlStr = String(url).toLowerCase();
  const urlRegex =
    /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w.-]+)+[\w\-._~:/?#[\]@!$&'()*+,;=]+$/;
  return urlStr.length < 2083 && urlRegex.test(urlStr);
};

export const isValidUsername = (username: string): boolean => {
  const usernameRegex = /^[a-zA-Z\d]+[a-zA-Z\d_.-]*$/;
  return usernameRegex.test(String(username).toLowerCase());
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isInteger = (value: any) => {
  return /^\d+$/i.test(value);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isIntegerPositive = (value: any) => {
  return isInteger(value) && value > 0;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isNumber = function isNumber(value: any) {
  // eslint-disable-next-line no-restricted-globals
  return typeof value === 'number' && isFinite(value);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isString = (string: any) => {
  return typeof string === 'string' || string instanceof String;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isHexString = (string: any) => {
  return typeof string === 'string' && string.startsWith('0x');
};

export const isEmptyObject = (object = {}) => {
  return !Object.keys(object).length;
};

export const isValidETHTransaction = (str: string): boolean => {
  return /^0x([A-Fa-f\d]{64})$/.test(str);
};

export const isValidETHAddress = (address: string): boolean => {
  return Web3.utils.isAddress(address);
};
