import { makeStyles } from 'tss-react/mui';

import { defaultTheme } from '../../../themes/mainTheme';

export const useNetworkNameStyles = makeStyles<void, 'inlineView'>()(
  (theme, _params, classes) => ({
    root: {
      color: theme.palette.text.primary,
      backgroundColor: theme.palette.common.white,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.background.default,
      height: 40,
      fontSize: 14,
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2, 3),
      borderRadius: 12,
      [theme.breakpoints.down('md')]: {
        width: '100%',
        border: 0,
      },
    },

    inlineView: {
      backgroundColor: 'transparent',
      border: 'none',
      width: 'auto',
      height: 'auto',
      padding: 0,
      borderRadius: 0,
    },

    avatar: {
      width: 24,
      height: 24,
      marginRight: defaultTheme.spacing(2),
      verticalAlign: 'middle',
      [`.${classes.inlineView} &`]: {
        marginRight: 0,
      },
    },
  }),
);
