import { Box, Container, Typography } from '@mui/material';

import { useGetChainDataQuery } from '../../../../../Bas/actions/bas';
import { QueryEmpty } from '../../../../../common/components/QueryEmpty';
import { QueryLoadingCentered } from '../../../../../common/components/QueryLoading';
import { ETH_EXPONENT } from '../../../../../common/const';
import { t } from '../../../../../i18n/utils/intl';
import { useStatisticStyles } from './useStatisticStyles';

export function Statistic() {
  const { classes } = useStatisticStyles();

  const { data: chainData, isLoading: isChainDataLoading } =
    useGetChainDataQuery();

  if (isChainDataLoading || isChainDataLoading) {
    return <QueryLoadingCentered />;
  }

  if (!chainData) {
    return <QueryEmpty />;
  }

  return (
    <Box className={classes.root}>
      <Container>
        <Box className={classes.card}>
          <Box className={classes.progressTop}>
            {/* <Box>{t('dashboard.epoch', { value: chainData.currentEpoch })}</Box> */}
            {/* <Box className={classes.epochTxt}>
              {t('dashboard.slots', {
                active: 5,
                total: 12,
              })}
            </Box> */}
          </Box>
          {/* <LinearProgress
            variant="determinate"
            value={40}
            className={classes.progress}
          /> */}
          <Box className={classes.items}>
            <Box className={classes.item}>
              <Box className={classes.itemTop}>
                <Typography variant="inherit">
                  {t('dashboard.total-staked')}
                </Typography>
                {/* <Typography variant="inherit">
                  {t('dashboard.current-slot')}
                </Typography> */}
              </Box>
              <Box className={classes.itemBottom}>
                <Typography variant="inherit">
                  {/* {t('dashboard.total-staked-value', {
                    active: data.activeStaked,
                    total: data.totalStaked,
                  })} */}
                  {t('number.styled', {
                    value: chainData.totalDelegation.dividedBy(ETH_EXPONENT),
                  })}
                </Typography>
                {/* <Typography variant="inherit">
                  {t('number.styled', { value: 7 })}
                </Typography> */}
              </Box>
            </Box>
            <Box className={classes.item}>
              <Box className={classes.itemTop}>
                <Typography variant="inherit">
                  {t('dashboard.active-validators')}
                </Typography>
                <Typography variant="inherit">
                  {t('dashboard.pending-validators')}
                </Typography>
              </Box>
              <Box className={classes.itemBottom}>
                <Typography variant="inherit">
                  {t('number.styled', {
                    value: chainData.activeValidatorsCount,
                  })}
                </Typography>
                <Typography variant="inherit">
                  {t('number.styled', {
                    value: chainData.pendingValidatorsCount,
                  })}
                </Typography>
              </Box>
            </Box>
            {/* <Box className={classes.item}>
              <Box className={classes.itemTop}>
                <Typography variant="inherit">
                  {t('dashboard.staked-with-symbol', {
                    value: t('currency.eth'),
                  })}
                </Typography>
                <Typography variant="inherit">
                  {t('dashboard.average-balance')}
                </Typography>
              </Box>
              <Box className={classes.itemBottom}>
                <Typography variant="inherit">
                  {t('number.with-currency', {
                    value: data.staked,
                    currency: t('currency.eth'),
                  })}
                </Typography>
                <Typography variant="inherit">
                  {t('number.styled', { value: data.avgBalance })}
                </Typography>
              </Box>
            </Box> */}
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
