import { makeStyles } from 'tss-react/mui';

export const useSelectFieldStyles = makeStyles()(theme => ({
  root: {},
  labelBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  loading: {
    '& svg': {
      position: 'absolute',
      right: 20,
      width: 20,
      height: 20,
    },
  },
  placeholder: {
    opacity: 0.42,
  },
  item: {
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  itemContent: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  checkedIcon: {
    '&&': {
      width: 16,
      margin: 0,
      color: theme.palette.primary.dark,
    },
  },
}));
