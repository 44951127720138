import { makeStyles } from 'tss-react/mui';

export const useClaimableAssetsStyles = makeStyles()(theme => ({
  toolbarColumn: {
    // flex: 0.5,
  },
  withBtn: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(3),
  },
  toolbarCell: {
    display: 'flex',
    // columnGap: theme.spacing(2),
    // flex: 0.5,
  },
  toolbarBtn: {
    flex: 1,
  },
}));
