import React from 'react';
import { generatePath, Route } from 'react-router-dom';

import { createRouteConfig } from '../../utils/createRouteConfig';
import { Network } from '../common/hooks/useCurrentNetwork';
import { t } from '../i18n/utils/intl';
import { LayoutDefault } from '../layout/components/LayoutDefault';
import { HomeSectionType, HomeUrlQueryParams, ValidatorsType } from './common';
import { Home } from './screens/Home';

const PATH_HOME = '';

const DEFAULT_PARAMS = new URLSearchParams({
  [HomeUrlQueryParams.Section]: HomeSectionType.Dashboard,
  [HomeUrlQueryParams.Validators]: ValidatorsType.Active,
}).toString();

export const HomeRoutesConfig = createRouteConfig(
  {
    Home: {
      path: PATH_HOME,
      generatePath: (network: Network | string) =>
        generatePath(`/${network}?${DEFAULT_PARAMS}`),
    },
  },
  PATH_HOME,
);

export function getHomeRoutes(): JSX.Element {
  return (
    <Route
      path={HomeRoutesConfig.root}
      element={
        <LayoutDefault title={t('page-title.home')}>
          <Home />
        </LayoutDefault>
      }
    />
  );
}
