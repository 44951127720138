import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../../../themes/const';

export const useAppListStyles = makeStyles()(theme => ({
  root: {
    fontSize: 14,
    fontWeight: 400,
    fontFamily: FONTS.regular,
  },
  header: {
    borderRadius: 18,
    backgroundColor: theme.palette.grey[200],
    height: theme.spacing(11.5),
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6),
    color: theme.palette.text.disabled,
  },
  column: {
    flex: 1,
  },
  row: {
    marginTop: theme.spacing(4),
    minHeight: theme.spacing(20),
    padding: theme.spacing(5, 6),
    borderRadius: 18,
    backgroundColor: theme.palette.common.white,
    display: 'flex',
    alignItems: 'center',
  },
  rowCompact: {
    marginTop: 0,
    borderRadius: 0,
    minHeight: 'auto',
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    borderBottom: '1px solid',
    borderColor: theme.palette.grey[200],
    '&:last-child': {
      borderBottom: 'none',
    },
  },
  cardBody: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
  },
  rowList: {},
  cardList: {},
  card: {
    padding: theme.spacing(4),
    borderRadius: 12,
    border: '1px solid',
    borderColor: theme.palette.grey['200'],
    backgroundColor: theme.palette.common.white,
    marginBottom: theme.spacing(4),
  },
  cardRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  cardLeft: {
    color: theme.palette.text.disabled,
  },
  cardRight: {},
}));
