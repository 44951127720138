import React, { useCallback, useState } from 'react';
import { Chip } from '@mui/material';

interface IUiTagProps {
  label: string;
  isActive?: boolean;
  smallSize?: boolean;
  isDisabled?: boolean;
}

export function UiTag({
  label,
  isActive = false,
  smallSize = true,
  isDisabled = false,
}: IUiTagProps) {
  const [active, setActive] = useState(isActive);

  const handleClick = useCallback(() => {
    setActive(active => !active);
  }, []);

  return (
    <Chip
      size={smallSize ? 'small' : 'medium'}
      label={label}
      onClick={handleClick}
      color={active ? 'primary' : 'default'}
      disabled={isDisabled}
    />
  );
}
