import { forwardRef, MouseEventHandler, useCallback } from 'react';
import { ButtonProps, IconButton as ButtonComponent } from '@mui/material';

import { QueryLoading } from 'modules/common/components/QueryLoading/QueryLoading';

import { useIconButtonStyles } from './useIconButtonStyles';

interface IButtonProps extends ButtonProps {
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  [x: string]: unknown;
}

/**
 * The main api is here - [Button API](https://material-ui.com/api/button/)
 */
export const IconButton = forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      className,
      loading = false,
      disabled = false,
      children,
      onClick,
      ...props
    },
    ref,
  ) => {
    const { classes, cx } = useIconButtonStyles();

    const onLoadingClick: MouseEventHandler<HTMLButtonElement> = useCallback(
      e => {
        e.preventDefault();
      },
      [],
    );

    return (
      <ButtonComponent
        ref={ref}
        className={cx(classes.root, loading && classes.loading, className)}
        disabled={disabled || loading}
        {...props}
        onClick={loading ? onLoadingClick : onClick}
      >
        {children}
        {loading && (
          <i className={classes.loadingIcon}>
            <QueryLoading />
          </i>
        )}
      </ButtonComponent>
    );
  },
);

IconButton.displayName = 'IconButton';
