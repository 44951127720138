import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { persistReducer, persistStore } from 'redux-persist';

import { stakeApi } from '../modules/api/stakeApi';
import {
  middleware as basMiddleware,
  reducer as basReducer,
  reducerPath as basReducerPath,
} from '../modules/Bas/actions/bas';
import { i18nSlice } from '../modules/i18n/i18nSlice';
import {
  LAYOUT_STATE_NAME,
  layoutReducer,
} from '../modules/layout/store/layout';
import { rtkQueryErrorLogger } from '../modules/Notifications/store/rtkQueryErrorLogger';
import { i18nPersistConfig } from './webStorageConfigs';

const rootReducer = combineReducers({
  i18n: persistReducer(i18nPersistConfig, i18nSlice.reducer),
  [stakeApi.reducerPath]: stakeApi.reducer,
  [basReducerPath]: basReducer,
  [LAYOUT_STATE_NAME]: layoutReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      // TODO: fix serializable error
      // https://redux-toolkit.js.org/usage/usage-guide#working-with-non-serializable-data
      serializableCheck: false,
    })
      .concat(rtkQueryErrorLogger)
      .concat([stakeApi.middleware, basMiddleware]),
});

export const persistor = persistStore(store);

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
