import React, { useCallback, useState } from 'react';
import { Box, Chip, Typography } from '@mui/material';

export function UiChips() {
  const [active, setActive] = useState(true);

  const handleClick = useCallback(() => {
    setActive(active => !active);
  }, []);

  return (
    <Box mt={5} mb={40}>
      <Typography variant="h1">Chips</Typography>
      <hr />
      <Chip
        label="Chip interactive"
        onClick={handleClick}
        color={active ? 'primary' : 'default'}
      />
      <Chip size="small" label="Chip small" color="default" />
      <Chip size="small" label="Chip small selected" color="primary" />
      <Chip
        label="Chip disabled"
        onClick={handleClick}
        color="default"
        disabled
      />
    </Box>
  );
}
