import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../../../themes/const';

export const useAuthModalStyles = makeStyles()(theme => ({
  title: {
    position: 'relative',
    fontFamily: FONTS.bold,
    fontWeight: 700,
    fontSize: 30,
    textAlign: 'center',
    padding: theme.spacing(10, 10, 5),
  },
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(5),
  },
  content: {
    padding: theme.spacing(0, 10, 10),
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    maxWidth: 520,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  button: {
    padding: theme.spacing(4, 5.5),
    borderColor: theme.palette.background.default,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    justifyContent: 'flex-start',
    borderRadius: 16,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.text.primary,
    },
    '& svg': {
      height: 30,
      width: 30,
    },
  },
}));
