import { EEthereumNetworkId } from '@ankr.com/provider';

import { throwErrorIfNotDefined } from '../../utils/throwErrorIfNotDefined';
import { Days, Milliseconds } from './types/unit';

export const LOCATION_ORIGIN =
  window.location.origin ??
  `${window.location.protocol}//${window.location.hostname}`;

export const IS_DEV = !process.env.REACT_APP_ENV;

export const SENTRY_RELEASE = process.env.REACT_APP_RELEASE;

export const SENTRY_ENABLE: boolean =
  process.env.REACT_APP_SENTRY_ENABLE === 'true';
export const SENTRY_DSN: string = process.env.REACT_APP_SENTRY_DSN ?? '';

export const DEV_FEATURES_ENABLE: boolean =
  process.env.REACT_APP_DEV_FEATURES_ENABLE === 'true';

export const API_ENDPOINT = throwErrorIfNotDefined(
  process.env.REACT_APP_API_ENDPOINT,
  new Error('REACT_APP_API_ENDPOINT id must be initialized'),
);

export const CHAIN_NAME = process.env.REACT_APP_CHAIN_NAME || 'BAS devnet';

export const API_ENDPOINT_PREFIX = '/v1alpha';

export const EXPLORER_URL = throwErrorIfNotDefined(
  process.env.REACT_APP_EXPLORER_URL,
  new Error('REACT_APP_EXPLORER_URL must be initialized'),
);

export const METAMASK_WEBSITE = 'https://metamask.io/';

export const CHAIN_ID = (() => {
  const chainId = parseInt(
    process.env.REACT_APP_CHAIN_ID ?? '',
    10,
  ) as EEthereumNetworkId;
  if (!chainId) {
    throw new Error('Chain id must be initialized');
  }

  return chainId;
})();

export const INFINITE_SCROLL_DELAY: Milliseconds = 500;

export const ZERO = 0;
export const MIN_INTEGER = -999999999999999;
export const MAX_INTEGER = 999999999999999;

export const VALIDATOR_MIN_COMMISSION_RATE = 0;
export const VALIDATOR_MAX_COMMISSION_RATE = 30;

export const GET_TOKENS_URL = '/';

// TODO: get it from chain props?
export const APP_CHAIN_LOCK_PERIOD: Days = 10;

export const FORM_ITEMS_MARGIN = 10;

// TODO: at the end all features should be activated
export const featuresConfig = {};

export const ETH_DECIMALS = 18;

export const ETH_EXPONENT = 10 ** ETH_DECIMALS;
