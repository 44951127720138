import createPalette from '@mui/material/styles/createPalette';

import { Modes } from '../types/types';

export const MAIN_PALETTE = createPalette({
  mode: Modes.light,

  primary: {
    dark: '#356DF3',
    main: '#5A87F2',
    light: '#487AF3',
    contrastText: '#ffffff',
  },
  highlight: {
    main: '#F4E9D0',
    dark: '#FFA030',
  },
  error: {
    light: '#FAE6EA',
    main: '#D22C54',
  },
  warning: {
    dark: '#FF7B3E',
    main: '#EEA941',
    light: '#F4E9D0',
  },
  success: {
    light: '#E1F6EE',
    main: '#3AC090',
  },
  background: {
    default: '#F2F5FA',
    paper: '#ffffff',
  },

  grey: {
    50: '#ffffff',
    100: '#EFEFEF',
    200: '#E7EBF3',
    300: '#DFE3EB',
    400: '#BFC6D0',
    500: '#9AA1B0',
    600: '#82899A',
    700: '#35383C',
    800: '#2E343C',
    900: '#1F2226',
  },

  link: {
    main: '#356DF3',
  },

  text: {
    primary: '#2E343C',
    secondary: '#82899A',
    disabled: '#82899A',
  },

  divider: '#DFE3EB',
});
