import { createTheme, PaletteOptions } from '@mui/material/styles';

import { FONTS } from './const';
import { MAIN_PALETTE } from './palettes/mainPalette';
import { getThemeComponents } from './utils/getThemeComponents';

export const defaultTheme = createTheme({
  spacing: 4,
  palette: MAIN_PALETTE as PaletteOptions,
  breakpoints: {
    values: {
      xs: 0,
      sm: 840,
      md: 1080,
      lg: 1280,
      xl: 1440,
    },
  },
});

export const mainTheme = createTheme({
  spacing: defaultTheme.spacing,
  palette: defaultTheme.palette,
  transitions: defaultTheme.transitions,
  breakpoints: defaultTheme.breakpoints,

  typography: {
    fontFamily: FONTS.regular,
    fontSize: 16,
    [defaultTheme.breakpoints.down('md')]: {
      fontSize: 14,
    },
    h1: {
      fontFamily: FONTS.bold,
      fontSize: 45,
      lineHeight: 1.25,
      fontWeight: 700,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: 34,
      },
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 27,
      },
    },
    h2: {
      fontFamily: FONTS.semiBold,
      fontSize: 40,
      lineHeight: 1.25,
      fontWeight: 600,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 24,
      },
    },
    h3: {
      fontFamily: FONTS.medium,
      fontSize: 36,
      lineHeight: 1.25,
      fontWeight: 500,
    },
    h4: {
      fontFamily: FONTS.medium,
      fontSize: 24,
      lineHeight: 1.25,
      fontWeight: 500,
    },
    h5: {
      fontFamily: FONTS.medium,
      fontSize: 14,
      lineHeight: 1.25,
      fontWeight: 500,
    },
    h6: {
      fontFamily: FONTS.semiBold,
      fontSize: 14,
      lineHeight: 1.25,
      fontWeight: 600,
    },
    subtitle1: {
      fontFamily: FONTS.semiBold,
      fontSize: 32,
      lineHeight: 1.25,
      fontWeight: 600,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: 28,
      },
    },
    subtitle2: {
      fontFamily: FONTS.bold,
      fontSize: 27,
      lineHeight: 1.25,
      fontWeight: 700,
    },
    body1: {
      fontFamily: FONTS.regular,
      fontSize: 24,
      lineHeight: 1.25,
      fontWeight: 400,
    },
    body2: {
      fontFamily: FONTS.medium,
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 500,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: 14,
      },
    },
    button: {
      fontFamily: FONTS.semiBold,
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 600,
      [defaultTheme.breakpoints.down('md')]: {
        fontSize: 14,
      },
    },
    caption: {
      fontFamily: FONTS.medium,
      fontSize: 12,
      lineHeight: 1.25,
      fontWeight: 500,
    },
  },
  components: getThemeComponents(MAIN_PALETTE),
});

export const FONT_FEATURE = {
  header: {
    'font-feature-settings': '"ss01" on',
  },
  first: {
    'font-feature-settings': '"ss01" on, "ss02" on, "calt" off',
  },
  third: {
    'font-feature-settings': '"ss02" on, "ss01" on',
  },
};
