import { Link, Tooltip } from '@mui/material';

import { CopyButton } from '../../../uiKit/CopyButton';
import { useCurrentNetwork } from '../../hooks/useCurrentNetwork';
import { HashType } from '../../types';
import { generateExplorerPath } from '../../utils/generateExplorerPath';
import { truncateWalletAddress } from '../../utils/truncateWalletAddress';

interface BlockchainLinkProps {
  value: string;
  type: HashType;
  trim?: boolean;
  withCopy?: boolean;
}

export function BlockchainLink({
  value,
  trim = false,
  type,
  withCopy,
}: BlockchainLinkProps) {
  const { currentNetwork } = useCurrentNetwork();

  return (
    <>
      <Tooltip title={value}>
        <Link
          href={generateExplorerPath(currentNetwork, type, value)}
          target="_blank"
          rel="noopener noreferrer"
        >
          {trim ? truncateWalletAddress(value) : value}
        </Link>
      </Tooltip>
      {withCopy && <CopyButton text={value} />}
    </>
  );
}
