import { makeStyles } from 'tss-react/mui';

import { FONTS } from 'modules/themes/const';

export const useStakingTabsStyles = makeStyles()(theme => ({
  root: {
    marginTop: theme.spacing(10),
  },
  top: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 18,
    padding: theme.spacing(5),
  },
  items: {},
  item: {},
  itemTop: {},
  itemBottom: {},
  left: {
    display: 'flex',
    columnGap: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      columnGap: theme.spacing(3),
    },
  },
  right: {
    display: 'flex',
    alignItems: 'center',
  },
  menuItem: {
    cursor: 'pointer',
    fontFamily: FONTS.bold,
    fontSize: 20,
    fontWeight: 700,
    color: theme.palette.grey['500'],
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
  },
  menuItemActive: {
    color: theme.palette.grey['900'],
  },
  balanceText: {
    fontFamily: FONTS.regular,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey['500'],
    marginRight: theme.spacing(2),
  },
  networkIcon: {
    height: theme.spacing(6),
    width: theme.spacing(6),
  },
  balanceValue: {
    fontFamily: FONTS.regular,
    fontSize: 14,
    fontWeight: 400,
    color: theme.palette.grey['900'],
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  getBtn: {
    fontFamily: FONTS.medium,
    fontSize: 14,
    fontWeight: 500,
    padding: theme.spacing(1.5, 3.5),
  },
  listWrapper: {
    marginTop: theme.spacing(5),
  },
}));
