import React from 'react';
import { Card, Container } from '@mui/material';
import Typography from '@mui/material/Typography';

import { METAMASK_WEBSITE } from '../../../common/const';
import { t } from '../../../i18n/utils/intl';
import { Button } from '../../../uiKit/Button';
import { useWalletNotFoundStyles } from './useWalletNotFoundStyles';

export function WalletNotFound(): JSX.Element {
  const { classes } = useWalletNotFoundStyles();

  return (
    <Container className={classes.container}>
      <Card className={classes.content}>
        <Typography variant="h2" className={classes.title}>
          {t('wallet-not-found.title')}
        </Typography>
        <Typography variant="body2" className={classes.hint}>
          {t('wallet-not-found.hint')}
        </Typography>
        <Button
          className={classes.btn}
          rel="noopener noreferrer"
          target="_blank"
          href={METAMASK_WEBSITE}
        >
          {t('wallet-not-found.button')}
        </Button>
      </Card>
    </Container>
  );
}
