import React from 'react';
import { EEthereumNetworkId } from '@ankr.com/provider';
import { Card, Container } from '@mui/material';
import Typography from '@mui/material/Typography';

import { getChainIcon } from 'modules/auth/utils/getChainIcon';

import { t } from '../../../i18n/utils/intl';
import { ConnectWalletButton } from '../ConnectWalletButton';
import { useConnectWalletStyles } from './useConnectWalletStyles';

interface IConnectWalletProps {
  requiredChainId: EEthereumNetworkId;
}

export function ConnectWallet({
  requiredChainId,
}: IConnectWalletProps): JSX.Element {
  const { classes } = useConnectWalletStyles();

  return (
    <Container className={classes.container}>
      <Card className={classes.content}>
        <Typography variant="h2" className={classes.title}>
          {t('connect-wallet.title')}
        </Typography>
        <Typography variant="body2" className={classes.hint}>
          {t('connect-wallet.hint')}
          {getChainIcon(requiredChainId)}
          {t(`chain.${requiredChainId}`)}
        </Typography>
        <ConnectWalletButton className={classes.connectBtn} />
      </Card>
    </Container>
  );
}
