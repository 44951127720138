import React, { useCallback } from 'react';
import { Box, Typography } from '@mui/material';

import {
  INotificationProps,
  notification,
} from '../../../Notifications/components';
import { Button } from '../../../uiKit/Button';

export function UiNotifications() {
  const handleNotify = useCallback((props: INotificationProps) => {
    notification(props);
  }, []);

  return (
    <Box mt={5} mb={40}>
      <Typography variant="h1">Notifications</Typography>
      <hr />
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleNotify({ message: 'Info message', type: 'info' })}
      >
        Info message
      </Button>{' '}
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          handleNotify({
            message: 'Success message',
            type: 'success',
            title: 'Custom title',
          })
        }
      >
        Success message with custom title
      </Button>{' '}
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          handleNotify({
            message: 'Error message',
            type: 'error',
            withoutTitle: true,
            autoClose: false,
          })
        }
      >
        Error message without header without autoClose
      </Button>{' '}
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          handleNotify({
            message: 'Warning message',
            type: 'warning',
          })
        }
      >
        Warning message
      </Button>{' '}
      <Button
        variant="contained"
        color="primary"
        onClick={() =>
          handleNotify({
            message:
              'In case you missed it, Bitcoin is still in freefall after ending the week down 25% on the back of global regulatory obstacles.',
            type: 'default',
            date: 'May 23, 2021',
            title: 'Elon rejects bitcoin due to environmental strain',
            autoClose: false,
            readMoreLink: 'https://google.com',
            closeOnClick: false,
          })
        }
      >
        News or price alert
      </Button>
    </Box>
  );
}
