import { ProviderManager } from '@ankr.com/provider';
import { ThemeColors } from 'web3modal';

import { API_ENDPOINT, CHAIN_ID, CHAIN_NAME } from '../common/const';
import { IBasAppConfig, IBasContractAddressRec } from './models';

const web3ModalTheme: ThemeColors = {
  background: 'rgb(255,255,255)',
  main: 'rgb(137, 137, 137)',
  secondary: 'rgb(137, 137, 137)',
  border: 'rgba(195,195,195,0.14)',
  hover: 'rgb(239, 239, 239)',
};

export const providerManager = new ProviderManager(web3ModalTheme);

export const VALIDATOR_STATUS_MAPPING = {
  '0': 'NOT_FOUND',
  '1': 'ACTIVE',
  '2': 'PENDING',
  '3': 'JAILED',
};

export const CONTRACT_ADDRESS: IBasContractAddressRec = {
  staking: '0x0000000000000000000000000000000000001000',
  slashingIndicator: '0x0000000000000000000000000000000000001001',
  systemReward: '0x0000000000000000000000000000000000001002',
  stakingPool: '0x0000000000000000000000000000000000007001',
  governance: '0x0000000000000000000000000000000000007002',
  chainConfig: '0x0000000000000000000000000000000000007003',
  runtimeUpgrade: '0x0000000000000000000000000000000000007004',
  deployerProxy: '0x0000000000000000000000000000000000007005',
};

export const DEFAULT_BAS_CONFIG: IBasAppConfig = {
  chainId: CHAIN_ID,
  rpcUrl: API_ENDPOINT,
  chainName: CHAIN_NAME,
  addressRec: CONTRACT_ADDRESS,
};
