import { makeStyles } from 'tss-react/mui';

export const useUserAvatarStyles = makeStyles()(theme => ({
  hasColor: {
    color: theme.palette.text.primary,
  },
  adaptiveContainer: {
    width: '100%',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',

    alignItems: 'center',
    justifyContent: 'center',
  },
}));
