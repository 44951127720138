import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../../../../../themes/const';

export const useStatisticStyles = makeStyles()(theme => ({
  root: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(5),
    },
  },
  card: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: 18,
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(5, 4),
    },
  },
  progressTop: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 14,
    fontWeight: 400,
  },
  epochTxt: {
    color: theme.palette.text.secondary,
  },
  progress: {
    marginTop: theme.spacing(2),
  },
  items: {
    marginTop: theme.spacing(6),
    display: 'flex',
    columnGap: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  },
  item: {
    border: '1px solid',
    borderColor: theme.palette.grey['200'],
    padding: theme.spacing(4, 5),
    flex: 1,
    borderRadius: 12,
  },
  itemTop: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 14,
    color: theme.palette.text.secondary,
  },
  itemBottom: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(5),
    fontSize: 16,
    fontWeight: 700,
    fontFamily: FONTS.bold,
    color: theme.palette.grey['900'],
  },
}));
