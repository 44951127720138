import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Box, Breadcrumbs, Card, Container, Typography } from '@mui/material';

import { useGetDelegatorListQuery } from '../../../api/getDelegatorList';
import { AppList, IAppListColumn } from '../../../common/components/AppList';
import { BlockchainLink } from '../../../common/components/BlockchainLink';
import { ReactComponent as ArrowLeft } from '../../../common/components/Icons/ArrowLeftIcon.svg';
import { QueryEmpty } from '../../../common/components/QueryEmpty';
import { useCurrentNetwork } from '../../../common/hooks/useCurrentNetwork';
import { IDelegator } from '../../../common/models';
import { Address, HashType } from '../../../common/types';
import { truncateWalletAddress } from '../../../common/utils/truncateWalletAddress';
import { isValidETHAddress } from '../../../common/utils/validation';
import { HomeRoutesConfig } from '../../../Home/HomeRoutes';
import { t } from '../../../i18n/utils/intl';
import { useLocaleMemo } from '../../../i18n/utils/useLocaleMemo';
import { useIsMDDown } from '../../../themes/hooks/useTheme';
import { Button } from '../../../uiKit/Button';
import { CopyButton } from '../../../uiKit/CopyButton';
import { ValidatorRoutesConfig } from '../../ValidatorRoutes';
import { useValidatorStyles } from './useValidatorStyles';

export function Validator() {
  const { classes } = useValidatorStyles();

  const { currentNetwork } = useCurrentNetwork();
  const { address } = ValidatorRoutesConfig.Validator.useParams();

  return (
    <Container className={classes.container}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to={HomeRoutesConfig.Home.generatePath(currentNetwork)}>
          <ArrowLeft />
          {t('dashboard.dashboard')}
        </Link>
        <span>{t('validator.title')}</span>
      </Breadcrumbs>

      {!address || !isValidETHAddress(address) ? (
        <Card className={classes.card}>
          <QueryEmpty />
        </Card>
      ) : (
        <Content address={address} />
      )}
    </Container>
  );
}

interface ContentProps {
  address: Address;
}

function Content({ address }: ContentProps) {
  const { classes } = useValidatorStyles();

  const isMDDown = useIsMDDown();

  // TODO (Infinity Scroll): for Delegators
  const { data: validatorListData, isLoading } =
    useGetDelegatorListQuery(address);

  const itemsLength = useMemo(() => {
    return validatorListData?.items && Array.isArray(validatorListData.items)
      ? validatorListData.items.length
      : 0;
  }, [validatorListData?.items]);

  const columns: IAppListColumn<IDelegator>[] = useLocaleMemo(
    () => [
      {
        header: <Box>{t('common.address')}</Box>,
        renderCell: item => (
          <BlockchainLink
            value={item.address}
            type={HashType.Address}
            withCopy
            trim={isMDDown}
          />
        ),
      },
      {
        header: <Box>{t('common.amount')}</Box>,
        renderCell: item => t('number.accurate', { value: item.amount }),
      },
    ],
    [isMDDown],
  );

  return (
    <>
      <Box className={classes.titleBox}>
        <Typography variant="subtitle2">
          {isMDDown
            ? truncateWalletAddress(address, {
                firstPartLength: 6,
                lastPartLength: 6,
              })
            : address}
          <CopyButton text={address} className={classes.copyBtn} />
        </Typography>
        {/* TODO: need connect buttons component */}
        <Button size="large">{t('common.stake')}</Button>
      </Box>
      <Card className={classes.card}>
        {!!itemsLength && (
          <Typography variant="subtitle2" className={classes.listTitle}>
            {t('validator.delegator-s', {
              value: itemsLength,
            })}
          </Typography>
        )}
        <AppList
          items={validatorListData?.items || []}
          isLoading={isLoading}
          columns={columns}
          compactView
        />
      </Card>
    </>
  );
}
