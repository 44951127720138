import { ReactNode } from 'react';
import { Box } from '@mui/material';

import { AppModal } from '../../../common/components/AppModal';
import { Button } from '../../../uiKit/Button';
import { ProgressStatus } from '../../types';
import { useStakingModalStyles } from './useStakingModalStyles';

export interface IStakeModalContent {
  title: ReactNode;
  topHint?: ReactNode;
  body: ReactNode;
  bottomHint?: ReactNode;
  btnText?: ReactNode;
  onBtnClick?: () => void;
  loading?: boolean;
}

export type IStakeModalRenderer = Record<ProgressStatus, IStakeModalContent>;

export function StakeModalContent({
  title,
  topHint,
  body,
  bottomHint,
  btnText,
  onBtnClick,
  loading,
}: IStakeModalContent) {
  const { classes } = useStakingModalStyles();
  return (
    <>
      <Box className={classes.title}>{title}</Box>
      {topHint && <Box className={classes.topHint}>{topHint}</Box>}
      <Box className={classes.body}>{body}</Box>
      {bottomHint && <Box className={classes.bottomHint}>{bottomHint}</Box>}
      {btnText && onBtnClick && (
        <Button
          className={classes.btn}
          variant="contained"
          onClick={onBtnClick}
          loading={loading}
        >
          {btnText}
        </Button>
      )}
    </>
  );
}

interface StakeModalProps {
  status: ProgressStatus;
  renderer: IStakeModalRenderer;
  open: boolean;
  onClose: () => void;
}

export function StakingModal({
  status,
  renderer,
  open,
  onClose,
}: StakeModalProps) {
  return (
    <AppModal open={open} onClose={onClose}>
      <StakeModalContent {...renderer[status]} />
    </AppModal>
  );
}
