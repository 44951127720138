import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

export function UiTypography() {
  return (
    <Box mt={5} mb={40}>
      <Typography variant="h1">Typography</Typography>
      <hr />

      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="h1">Heading 1</Typography>
          <Typography variant="h2">Heading 2</Typography>
          <Typography variant="h3">Heading 3</Typography>
          <Typography variant="h4">Heading 4</Typography>
          <Typography variant="h5">Heading 5</Typography>
          <Typography variant="h6">Heading 6</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle1">Subtitle 1</Typography>
          <Typography variant="subtitle2">Subtitle 2</Typography>
          <Typography variant="body1">
            Body 1 / <strong>Bold</strong>
          </Typography>
          <Typography variant="body2">
            Body 2 / <strong>Bold</strong>
          </Typography>
          <Typography variant="caption">Caption</Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
