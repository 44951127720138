import React, { ReactNode, useRef } from 'react';
import { Avatar, Tooltip } from '@mui/material';

import { useUserAvatarStyles } from './useUserAvatarStyles';

interface IUserAvatarProps {
  name?: string;
  color?: string;
  image?: string;
  className?: string;
  withTooltip?: boolean;
  variant?: 'circular' | 'rounded' | 'square';
  symbolsNum?: number;
}

export function UserAvatar({
  name = '?',
  color,
  image = undefined,
  className,
  withTooltip = false,
  variant = 'circular',
  symbolsNum = 2,
}: IUserAvatarProps) {
  const { classes, cx } = useUserAvatarStyles();

  return (
    <Tooltip title={withTooltip ? name : ''}>
      <Avatar
        alt={name}
        src={image}
        className={cx(className, !!color && classes.hasColor)}
        variant={variant}
        {...(color ? { sx: { bgcolor: color } } : {})}
      >
        <AdaptiveFontSizeRelativeContainer>
          {name.substring(0, symbolsNum)}
        </AdaptiveFontSizeRelativeContainer>
      </Avatar>
    </Tooltip>
  );
}

function AdaptiveFontSizeRelativeContainer({
  children,
}: {
  children: ReactNode;
}) {
  const { classes } = useUserAvatarStyles();
  const refResizeObserver = useRef<ResizeObserver | null>(null);

  return (
    <div
      className={classes.adaptiveContainer}
      ref={container => {
        if (container === null) {
          refResizeObserver.current?.disconnect();
        } else {
          refResizeObserver.current = new ResizeObserver(([element]) => {
            // eslint-disable-next-line no-param-reassign
            container.style.fontSize = `${element.contentRect.width / 1.9}px`;
          });
          refResizeObserver.current.observe(container);
        }
      }}
    >
      {children}
    </div>
  );
}
