import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export function HomeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 17 17">
      <path
        d="M14.1667 14.1667C14.1667 14.3545 14.092 14.5347 13.9592 14.6675C13.8264 14.8004 13.6462 14.875 13.4583 14.875H3.54166C3.3538 14.875 3.17363 14.8004 3.04079 14.6675C2.90796 14.5347 2.83333 14.3545 2.83333 14.1667V7.79165H0.708328L8.02329 1.14182C8.1537 1.02316 8.32368 0.957397 8.49999 0.957397C8.67631 0.957397 8.84629 1.02316 8.9767 1.14182L16.2917 7.79165H14.1667V14.1667ZM7.79166 9.20832V13.4583H9.20833V9.20832H7.79166Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
