import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../../../../../themes/const';

export const useNewValidatorInfoStyles = makeStyles()(theme => ({
  root: {},
  title: {
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
      marginBottom: theme.spacing(1),
    },
  },
  subTitle: {
    fontSize: 16,
    fontFamily: FONTS.bold,
    fontWeight: 700,
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
      marginBottom: theme.spacing(1.5),
    },
  },
  text: {
    fontSize: 14,
    lineHeight: 1.428,
    fontFamily: FONTS.regular,
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      fontSize: 11,
    },
    '& .fields-list': {
      listStyleType: 'disc',
      paddingTop: '0.4em',
      paddingLeft: theme.spacing(7),
    },
  },
  steps: {
    padding: 0,
    margin: theme.spacing(6, 0, 0),
    listStyle: 'none',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(4),
    },
  },
  step: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(2.5),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(3.5),
      paddingBottom: theme.spacing(4),
    },
  },
  stepNum: {
    minWidth: 28,
    minHeight: 28,
    borderRadius: 28,
    backgroundColor: theme.palette.common.white,
    fontFamily: FONTS.bold,
    fontWeight: 700,
    fontSize: 14,
    color: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      minWidth: 24,
      minHeight: 24,
    },
  },
  stepContent: {},
}));
