import { PaletteColor, SimplePaletteColorOptions } from '@mui/material';
import { Theme } from '@mui/material/styles';

export enum Modes {
  light = 'light',
  dark = 'dark',
}

declare module '@mui/material/styles' {
  type DefaultTheme = Theme;
}

declare module '@mui/material/styles/createPalette' {
  interface Palette {
    link: PaletteColor;
    highlight: PaletteColor;
  }
  interface PaletteOptions {
    link?: SimplePaletteColorOptions;
    highlight?: SimplePaletteColorOptions;
  }
}
