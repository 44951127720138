import { useCallback, useMemo, useState } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { Box } from '@mui/material';

import { t } from 'modules/i18n/utils/intl';

import { useClaimFeeMutation } from '../../../Bas/actions/bas';
import { IValidator } from '../../../Bas/models';
import { APP_CHAIN_LOCK_PERIOD } from '../../../common/const';
import { Address } from '../../../common/types';
import { isValidETHAddress } from '../../../common/utils/validation';
import { InputField } from '../../../form/components/InputField';
import { NumberFormatField } from '../../../form/components/NumberFormatField';
import { FormErrors } from '../../../form/utils/FormErrors';
import { useLocaleMemo } from '../../../i18n/utils/useLocaleMemo';
import { Button } from '../../../uiKit/Button';
import { ProgressStatus } from '../../types';
import { InfoBlock } from '../InfoBlock';
import {
  IStakeModalContent,
  IStakeModalRenderer,
  StakingModal,
} from '../StakingModal';

interface IClaimForm {
  validator: Address;
  amount: number;
}

interface ClaimProps {
  validator: IValidator;
  onClose: () => void;
}

export function Claim({ validator, onClose }: ClaimProps) {
  const [status, setStatus] = useState<ProgressStatus>(ProgressStatus.Start);

  const [claim, { data: claimTx }] = useClaimFeeMutation();

  const handleSubmit = (v: IClaimForm) => {
    setStatus(ProgressStatus.Pending);
    void claim({ validator: validator.address })
      .then(v => {
        setStatus(ProgressStatus.Success);
      })
      .catch(e => {
        setStatus(ProgressStatus.Failed);
      });
  };

  const validateForm = useCallback((payload: IClaimForm) => {
    const errors: FormErrors<IClaimForm> = {};

    if (!payload.validator) {
      errors.validator = t('validation.required');
    } else if (!isValidETHAddress(payload.validator)) {
      errors.validator = t('validation.invalid-address');
    }
    return errors;
  }, []);

  const renderForm = ({ handleSubmit }: FormRenderProps<IClaimForm>) => {
    return (
      <Box component="form" onSubmit={handleSubmit} noValidate>
        <Field
          component={NumberFormatField}
          name="amount"
          label={t('staking.claimable-amount')}
          fullWidth
          autoFocus
          variant="filled"
          allowNegative={false}
          decimalScale={2}
          enterKeyHint="done"
          fixedDecimalScale
          disabled
          placeholder={validator.rewardAmountNomination}
        />

        <Field
          component={InputField}
          name="validator"
          label={t('staking.validator')}
          placeholder={t('new-validator.form.address-placeholder')}
          initialValue={validator.address}
          variant="filled"
          disabled
          fullWidth
        />

        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          size="large"
        >
          {t('common.claim')}
        </Button>
      </Box>
    );
  };

  const startContent: IStakeModalContent = useLocaleMemo(
    () => ({
      title: t('staking.claim-title'),
      body: (
        <Form
          onSubmit={handleSubmit}
          render={renderForm}
          validate={validateForm}
        />
      ),
    }),
    [renderForm],
  );

  const pendingContent: IStakeModalContent = useLocaleMemo(
    () => ({
      title: t('staking.claim-pending'),
      topHint: t('staking.pending-hint'),
      body: (
        <InfoBlock
          items={[
            [
              {
                left: t('staking.amount-label'),
                right: t('number.accurate', {
                  value: validator.rewardAmountNomination,
                }),
              },
            ],
            [{ left: t('staking.validator-label'), right: validator.address }],
          ]}
        />
      ),
      btnText: t('staking.go-dashboard'),
      onBtnClick: onClose,
    }),
    [onClose, validator],
  );

  const successContent: IStakeModalContent = useLocaleMemo(
    () => ({
      title: t('staking.claim-success'),
      body: (
        <InfoBlock
          items={[
            [
              {
                left: t('staking.amount-label'),
                right: t('number.accurate', {
                  value: validator.rewardAmountNomination,
                }),
              },
            ],
            [{ left: t('staking.validator-label'), right: validator.address }],
            [{ left: t('staking.tx-label'), right: claimTx }],
          ]}
        />
      ),
      btnText: t('staking.go-dashboard'),
      onBtnClick: onClose,
    }),
    [onClose, validator, claimTx],
  );

  const failedContent: IStakeModalContent = useLocaleMemo(
    () => ({
      title: t('staking.stake-pending'),
      topHint: t('staking.pending-hint'),
      body: <Box>Failed</Box>,
      bottomHint: t('staking.stake-hint-with-value', {
        value: t('time-unit.day', { value: APP_CHAIN_LOCK_PERIOD }),
      }),
      btnText: t('staking.go-dashboard'),
      onBtnClick: onClose,
    }),
    [onClose],
  );

  const renderer: IStakeModalRenderer = useMemo(
    () => ({
      [ProgressStatus.Start]: startContent,
      [ProgressStatus.Pending]: pendingContent,
      [ProgressStatus.Success]: successContent,
      [ProgressStatus.Failed]: failedContent,
    }),
    [startContent, pendingContent, successContent, failedContent],
  );

  return (
    <StakingModal open status={status} renderer={renderer} onClose={onClose} />
  );
}
