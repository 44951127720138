import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../../../themes/const';

export const useConnectWalletButtonStyles = makeStyles()(theme => ({
  btn: {
    fontFamily: FONTS.regular,
    fontWeight: 400,
    fontSize: 14,
    paddingRight: theme.spacing(8),
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  },
  btnConnect: {},
  btnConnected: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.default,
    paddingRight: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
}));
