import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';

const DEFAULT_SIZE = 40;

export const useSpinnerStyles = makeStyles()(() => ({
  spinner: {
    animation: `${keyframes`
      100% {
        transform: rotate(360deg);
      }`} 1s infinite linear`,
    willChange: 'transform',
    margin: 'auto',
    width: DEFAULT_SIZE,
    height: DEFAULT_SIZE,
  },
  centered: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
  },
}));
