import { t } from '../../i18n/utils/intl';

export const truncateWalletAddress = (
  address: string | undefined,
  { minLengthForTruncate = 12, firstPartLength = 5, lastPartLength = 5 } = {},
): string => {
  if (address) {
    if (address.length <= minLengthForTruncate) {
      return address;
    }
    const addressSymbols = address.split('');
    const firstPart = addressSymbols.slice(0, firstPartLength).join('');
    const lastPart = addressSymbols
      .slice(addressSymbols.length - lastPartLength, addressSymbols.length)
      .join('');
    return t('common.truncate-wallet', { firstPart, lastPart });
  }
  return '';
};
