import { makeStyles } from 'tss-react/mui';

export const useNewValidatorStyles = makeStyles()(theme => ({
  container: {
    '&&': {
      marginTop: theme.spacing(13),
      paddingBottom: theme.spacing(16),
      [theme.breakpoints.down('md')]: {
        maxWidth: 860,
      },
      [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(10),
        maxWidth: 720,
      },
    },
  },
  grid: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    gap: theme.spacing(14),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column-reverse',
      gap: theme.spacing(10),
    },
  },
  gridCell: {
    flexGrow: 1,
    width: '50%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
  },
  infoWrap: {},
  formWrap: {},
}));
