import { alpha } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../../themes/const';

export const useNotificationsStyles = makeStyles()(theme => ({
  root: {
    left: theme.spacing(3),
    right: theme.spacing(3),
    bottom: theme.spacing(3),
    width: 'auto',
    maxWidth: 'var(--toastify-toast-width)',
  },

  toast: {
    backgroundColor: alpha(theme.palette.grey[400], 0.8),
    backdropFilter: 'blur(16px)',
    borderRadius: 12,
    cursor: 'default',
    marginBottom: 0,
    '& + &': {
      marginTop: theme.spacing(2),
    },
  },

  toastBody: {
    wordBreak: 'break-word',
  },

  content: {
    color: theme.palette.text.primary,
  },

  date: {
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing(2),
    fontSize: 14,
  },

  title: {
    fontFamily: FONTS.semiBold,
    marginBottom: theme.spacing(2),
  },

  message: {
    fontSize: 14,
  },

  readMoreLink: {
    display: 'inline-block',
    marginTop: theme.spacing(2),
    color: theme.palette.link.main,
    fontSize: 14,
  },
}));
