import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../themes/const';

export const usePageNotFoundStyles = makeStyles()(theme => ({
  wrapper: {
    marginTop: theme.spacing(20),
    paddingBottom: theme.spacing(55),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(15),
      paddingBottom: theme.spacing(30),
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(9, 10),
    minHeight: 480,
    [theme.breakpoints.down('lg')]: {
      minHeight: 460,
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 420,
    },
    [theme.breakpoints.down('sm')]: {
      minHeight: 400,
      padding: theme.spacing(9, 6),
    },
  },
  title: {
    fontFamily: FONTS.bold,
    fontWeight: 700,
    lineHeight: 0.75,
    fontSize: 120,
    color: theme.palette.grey[400],
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      fontSize: 96,
    },
  },
  hint: {
    marginBottom: theme.spacing(10),
  },
}));
