import { makeStyles } from 'tss-react/mui';

import { FORM_ITEMS_MARGIN } from '../../common/const';

export const useInfoHelperBlockStyles = makeStyles()(theme => ({
  root: {
    position: 'relative',
    fontSize: 14,
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
    '&::before': {
      display: 'block',
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      borderRadius: 3,
      width: 3,
      backgroundColor: theme.palette.primary.dark,
    },
  },
  withMarginBottom: {
    marginBottom: theme.spacing(FORM_ITEMS_MARGIN),
  },
}));
