import React, { useCallback } from 'react';
import { AppBar, Container, Drawer, Slide, useTheme } from '@mui/material';

import { ConnectWalletButton } from '../../../auth/components/ConnectWalletButton';
import { NetworkName } from '../../../common/components/NetworkName';
import { useLocaleMemo } from '../../../i18n/utils/useLocaleMemo';
import { useIsMDDown } from '../../../themes/hooks/useTheme';
import { IS_REACT_SNAP } from '../../../uiKit/NoReactSnap';
import { Logo } from '../Logo';
import { HeaderNavigation } from './components/HeaderNavigation';
import { Toggle } from './components/Toggle';
import { useHeader } from './useHeader';
import { useHeaderStyles } from './useHeaderStyles';

export function Header() {
  const { classes, cx } = useHeaderStyles();

  const { mobileNavShowed, toggleNav, trigger, SLIDE_TIMEOUT, triggerTouched } =
    useHeader();

  const isMDDown = useIsMDDown();

  const buttons = useLocaleMemo(() => [], []);

  const renderMenus = useCallback(() => {
    return (
      <div className={cx(classes.navigation, classes.navigationDesktop)}>
        <HeaderNavigation buttons={buttons} />
        <NetworkName />
      </div>
    );
  }, [buttons, cx, classes]);

  const renderMobileMenus = useCallback(() => {
    return (
      <div className={classes.navigation}>
        <HeaderNavigation buttons={buttons} />
      </div>
    );
  }, [buttons, classes]);

  const theme = useTheme();

  const renderMobile = useCallback(() => {
    return (
      <div className={classes.mobileMenu}>
        <Drawer
          anchor="left"
          open={mobileNavShowed}
          onClose={toggleNav(false)}
          className={classes.drawer}
          transitionDuration={theme.transitions.duration.standard}
          ModalProps={{
            BackdropProps: {
              classes: {
                root: classes.drawerBackdrop,
              },
            },
          }}
          classes={{
            paperAnchorLeft: classes.drawerPaper,
          }}
        >
          <div className={classes.mobileMenuWrap}>
            <div className={cx(classes.logoWrap, classes.mobileLogoWrap)}>
              <Toggle
                onClick={mobileNavShowed ? toggleNav(false) : toggleNav(true)}
                isActive={mobileNavShowed}
              />
              <div className={classes.logo}>
                <Logo />
              </div>
            </div>
            <div className={classes.mobileMenuContent}>
              {renderMobileMenus()}
            </div>
            <div className={classes.headerButtons}>
              <NetworkName />
            </div>
          </div>
        </Drawer>
      </div>
    );
  }, [
    cx,
    classes,
    mobileNavShowed,
    toggleNav,
    renderMobileMenus,
    theme.transitions.duration.standard,
  ]);

  return (
    <Slide
      appear={false}
      direction="down"
      in={!trigger}
      timeout={SLIDE_TIMEOUT}
    >
      <AppBar
        className={cx(
          classes.appBar,
          !triggerTouched && classes.fixed,
          IS_REACT_SNAP && classes.absolute,
          isMDDown && mobileNavShowed && classes.mobileMenuIsOpen,
        )}
        position="fixed"
      >
        <Container className={classes.container}>
          <div className={classes.toolbar}>
            <div className={classes.logoWrap}>
              {buttons && isMDDown && !IS_REACT_SNAP && (
                <Toggle
                  onClick={mobileNavShowed ? toggleNav(false) : toggleNav(true)}
                  isActive={mobileNavShowed}
                />
              )}
              <div className={classes.logo}>
                <Logo />
              </div>
            </div>
            <div className={classes.toolbarContent}>
              {buttons &&
                (isMDDown && !IS_REACT_SNAP ? renderMobile() : renderMenus())}
              <ConnectWalletButton />
            </div>
          </div>
        </Container>
      </AppBar>
    </Slide>
  );
}
