/* eslint-disable  @typescript-eslint/no-explicit-any */

import { isEmpty } from '../common/utils/_';

export const buildUrlPath = (
  path: string,
  params: Record<string, any> = {},
): string => {
  if (isEmpty(params)) {
    return path;
  }

  const filteredParams = Object.keys(params).reduce((acc, key) => {
    if (params[key] !== undefined) {
      acc[key] = params[key];
    }
    return acc;
  }, {} as Record<string, any>);

  const urlParams = new URLSearchParams(filteredParams);

  return `${path}?${urlParams.toString()}`;
};

export const promiseDelay = <T>(v: T, delay = 1000): Promise<T> => {
  return new Promise(res => {
    setTimeout(() => res(v), delay);
  });
};
