import React from 'react';
import { Navigate, Route, Routes as Switch } from 'react-router-dom';

import { LockedMMAccount } from 'modules/auth/components/LockedMMAccount';
import { UnsupportedNetwork } from 'modules/auth/components/UnsupportedNetwork';
import { WalletAccess } from 'modules/auth/components/WalletAccess';
import {
  useGetAppConfigQuery,
  useInitWalletQuery,
} from 'modules/Bas/actions/bas';

import { ConnectWallet } from './modules/auth/components/ConnectWallet';
import { WalletNotFound } from './modules/auth/components/WalletNotFound';
import { BasError } from './modules/Bas/types';
import { QueryLoadingAbsolute } from './modules/common/components/QueryLoading';
import { NETWORK_DEFAULT } from './modules/common/hooks/useCurrentNetwork';
import { getHomeRoutes, HomeRoutesConfig } from './modules/Home/HomeRoutes';
import { t } from './modules/i18n/utils/intl';
import { LayoutDefault } from './modules/layout/components/LayoutDefault';
import { PageNotFound } from './modules/PageNotFound';
import { getUiRoutes } from './modules/Ui/UiRoutes';
import { getValidatorRoutes } from './modules/Validator/ValidatorRoutes';
import { RootRoute } from './RootRoute';

export function Routes(): JSX.Element {
  const { data: appConfig } = useGetAppConfigQuery();
  const {
    error: walletInitError,
    isLoading: isWalletInitLoading,
    data: walletInitData,
  } = useInitWalletQuery();

  if (isWalletInitLoading) {
    return <QueryLoadingAbsolute />;
  }

  if (walletInitError) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    switch (walletInitError) {
      case BasError.NoProvider:
        return <WalletNotFound />;
      case BasError.NoWallet:
        return <ConnectWallet requiredChainId={appConfig!.chainId} />;
      case BasError.AccountsAccess:
        return <LockedMMAccount requiredChainId={appConfig!.chainId} />;
      case BasError.NotConfiguredNetwork:
        return (
          <UnsupportedNetwork
            currentChainId={appConfig!.chainId}
            requiredChainId={appConfig!.chainId}
            onSwitch={() => {
              console.log('onSwitch');
            }}
          />
        );
      default:
        return (
          <WalletAccess
            requiredChainId={appConfig!.chainId}
            onSwitch={() => {
              console.log('onSwitch');
            }}
          />
        );
    }
  }

  return (
    <Switch>
      <Route path="/" element={<RootRoute />}>
        <Route
          path="/"
          element={
            <Navigate
              to={HomeRoutesConfig.Home.generatePath(NETWORK_DEFAULT)}
            />
          }
        />
        <Route path="/:network">
          {getHomeRoutes()}
          {getValidatorRoutes()}
          {getUiRoutes()}
        </Route>
        <Route
          path="*"
          element={
            <LayoutDefault title={t('page-title.page-not-found')}>
              <PageNotFound />
            </LayoutDefault>
          }
        />
      </Route>
    </Switch>
  );
}
