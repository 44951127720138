import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/material';

import { NetworkSwitcher } from '../../common/components/NetworkSwitcher';
import { DEV_FEATURES_ENABLE } from '../../common/const';
import { useCurrentNetwork } from '../../common/hooks/useCurrentNetwork';
import { HomeRoutesConfig } from '../../Home/HomeRoutes';
import { PageTitle } from '../../layout/components/PageTitle';
import { UiAvatars } from '../components/UiAvatars';
import { UiButtons } from '../components/UiButtons';
import { UiChips } from '../components/UiChips';
import { UiFormControls } from '../components/UiFormControls';
import { UiNotifications } from '../components/UiNotifications';
import { UiTabs } from '../components/UiTabs';
import { UiTags } from '../components/UITags';
import { UiTypography } from '../components/UiTypography';

export function Ui() {
  const navigate = useNavigate();

  const { currentNetwork: network } = useCurrentNetwork();

  useEffect(() => {
    // Redirect to Home page if DEV_FEATURES_ENABLE === false
    if (!DEV_FEATURES_ENABLE) {
      navigate(HomeRoutesConfig.Home.generatePath(network));
    }
  }, [network, navigate]);

  return (
    <Container>
      <PageTitle title="Page title" />

      <hr />
      <NetworkSwitcher />
      <hr />

      <UiTypography />

      <UiButtons />

      <UiChips />

      <UiFormControls />

      <UiTags />

      <UiTabs />

      <UiAvatars />

      <UiNotifications />
    </Container>
  );
}
