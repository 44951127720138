import React, { MouseEvent, useCallback, useMemo, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { uid } from 'react-uid';
import {
  Box,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField,
  TextFieldProps,
  Tooltip,
} from '@mui/material';

import { EyeIcon } from '../../../common/components/Icons/EyeIcon';
import { EyeStrikeIcon } from '../../../common/components/Icons/EyeStrikeIcon';
import { ReactComponent as InfoIcon } from '../../../common/components/Icons/InfoIcon.svg';
import { QueryLoading } from '../../../common/components/QueryLoading';
import { FORM_ITEMS_MARGIN } from '../../../common/const';
import { t } from '../../../i18n/utils/intl';
import { useLocaleMemo } from '../../../i18n/utils/useLocaleMemo';
import { getErrorText } from '../../utils/getErrorText';
import { hasError } from '../../utils/hasError';
import { useInputFieldStyles } from './useInputFieldStyles';

function Loader() {
  const { classes } = useInputFieldStyles();
  return (
    <InputAdornment position="end" className={classes.adornment}>
      <i className={classes.loadingIcon}>
        <QueryLoading />
      </i>
    </InputAdornment>
  );
}

interface IFieldProps extends FieldRenderProps<string> {
  showLimitCounter?: boolean;
  isLoading?: boolean;
}

export function InputField({
  input: { name, onChange, value, type, placeholder },
  meta,
  showLimitCounter = false,
  label,
  // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
  id = uid(`${name}-${type}`),
  withBottomMargin = true,
  InputProps,
  helperText,
  helperTextInLabelTooltip = false,
  isLoading,
  className,
  boxClassName,
  errorInTooltip = false,
  sideHint,
  ...rest
}: IFieldProps & TextFieldProps) {
  const maxLength: number | null = rest.inputProps?.maxLength ?? null;

  const { classes, cx } = useInputFieldStyles();

  const errorText = useMemo(() => {
    return getErrorText(meta);
  }, [meta]);

  const getHelperString = useLocaleMemo(() => {
    if (showLimitCounter && !!maxLength) {
      return t('form.limit-counter', {
        value: value.length ?? 0,
        maxLimit: maxLength,
      });
    }
    return helperText;
  }, [value, maxLength, showLimitCounter, helperText]);

  const isPasswordField = useMemo(() => {
    return type === 'password';
  }, [type]);

  const isNumberField = useMemo(() => {
    return type === 'number';
  }, [type]);

  const handleKeyPress = useCallback(
    (event: Event) => {
      // Browsers can't limit maxLength for input fields of type number. Let's do it
      if (maxLength && maxLength <= value.length) {
        event.preventDefault();
      }
    },
    [value, maxLength],
  );

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(show => !show);
  }, []);

  const handleMouseDownPassword = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    },
    [],
  );

  const PasswordTypeSwitcher = useMemo(() => {
    return (
      <InputAdornment position="end" disablePointerEvents={false}>
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          size="small"
          edge="end"
          color="primary"
        >
          {showPassword ? <EyeStrikeIcon /> : <EyeIcon />}
        </IconButton>
      </InputAdornment>
    );
  }, [handleClickShowPassword, handleMouseDownPassword, showPassword]);

  const localInputProps = useMemo(() => {
    if (isLoading) {
      return {
        ...InputProps,
        endAdornment: <Loader />,
      };
    }

    if (isPasswordField) {
      return {
        ...InputProps,
        endAdornment: PasswordTypeSwitcher,
      };
    }
    return InputProps;
  }, [InputProps, isLoading, PasswordTypeSwitcher, isPasswordField]);

  return (
    <Box mb={withBottomMargin ? FORM_ITEMS_MARGIN : 0} className={boxClassName}>
      <Box className={classes.labelBox}>
        {label && (
          <InputLabel error={!!errorText} htmlFor={id}>
            {label}
            {helperTextInLabelTooltip && !!getHelperString && (
              <Tooltip title={getHelperString}>
                <IconButton
                  size="small"
                  color="secondary"
                  className={classes.infoBtn}
                  tabIndex={-1}
                >
                  <InfoIcon className={classes.infoIcon} />
                </IconButton>
              </Tooltip>
            )}
          </InputLabel>
        )}
        {sideHint}
      </Box>
      <Tooltip
        title={
          errorInTooltip && !!errorText ? (
            <FormHelperText error>{errorText}</FormHelperText>
          ) : (
            ''
          )
        }
        open={errorInTooltip && !!errorText}
      >
        <TextField
          id={id}
          // eslint-disable-next-line no-nested-ternary
          type={isPasswordField ? (showPassword ? 'text' : 'password') : type}
          InputProps={localInputProps}
          name={name}
          error={hasError(meta)}
          value={value}
          placeholder={placeholder}
          helperText={null}
          onChange={onChange}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          onKeyDown={isNumberField ? handleKeyPress : rest.onKeyPress}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onWheel={(event: any) => event.target.blur()}
          className={cx(className, !!errorText && 'error')}
          {...rest}
        />
      </Tooltip>
      {!!errorText && !errorInTooltip && (
        <FormHelperText error>{errorText}</FormHelperText>
      )}
      {!helperTextInLabelTooltip && !!getHelperString && (
        <FormHelperText>{getHelperString}</FormHelperText>
      )}
    </Box>
  );
}
