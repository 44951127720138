import React, {
  ReactNode,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { uid } from 'react-uid';
import { Box, Fade, Tab, Tabs, Typography } from '@mui/material';

interface ITabPanelProps {
  children?: ReactNode;
  index: number;
  value: string | number;
}

export function TabPanel({ children, value, index }: ITabPanelProps) {
  const isActive = value === index;

  return (
    <div role="tabpanel" hidden={!isActive}>
      {isActive && (
        <Fade in={isActive}>
          <Box>{children}</Box>
        </Fade>
      )}
    </div>
  );
}

export function UiTabs() {
  const tabs = useMemo(
    () => [
      {
        value: 1,
        label: 'Tab 1',
      },
      {
        value: 2,
        label: 'Tab 2',
      },
      {
        value: 3,
        label: 'Tab 3',
      },
      {
        value: 4,
        label: 'Tab 4',
      },
      {
        value: 5,
        label: 'Tab 5',
      },
      {
        value: 6,
        label: 'Tab 6',
      },
      {
        value: 7,
        label: 'Tab 7',
      },
      {
        value: 8,
        label: 'Tab 8',
      },
      {
        value: 9,
        label: 'Tab 9',
      },
      {
        value: 10,
        label: 'Tab 10',
      },
    ],
    [],
  );

  const [tab, setTab] = useState(1);

  const onTabsChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_event: any, value: SetStateAction<number>) => {
      setTab(value);
    },
    [],
  );

  return (
    <Box mt={5} mb={40}>
      <Typography variant="h1">Tabs</Typography>
      <hr />
      <Tabs value={tab} onChange={onTabsChange}>
        {tabs.map(item => (
          <Tab key={uid(item)} label={item.label} value={item.value} />
        ))}
      </Tabs>
      {tabs.map(item => (
        <TabPanel key={uid(item)} value={tab} index={item.value}>
          {item.label}
        </TabPanel>
      ))}
    </Box>
  );
}
