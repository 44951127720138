import { useMemo, useState } from 'react';
import { Box } from '@mui/material';

import { t } from 'modules/i18n/utils/intl';

import { APP_CHAIN_LOCK_PERIOD } from '../../../common/const';
import { useLocaleMemo } from '../../../i18n/utils/useLocaleMemo';
import { ProgressStatus } from '../../types';
import { InfoBlock } from '../InfoBlock';
import {
  IStakeModalContent,
  IStakeModalRenderer,
  StakingModal,
} from '../StakingModal';

interface RestakeAllProps {
  onClose: () => void;
}

export function RestakeAll({ onClose }: RestakeAllProps) {
  const [status, setStatus] = useState<ProgressStatus>(ProgressStatus.Start);

  // TODO: need real mutation
  const handleSubmit = () => {
    setStatus(ProgressStatus.Pending);
    setTimeout(() => {
      setStatus(ProgressStatus.Success);
    }, 2000);
  };

  const startContent: IStakeModalContent = useLocaleMemo(
    () => ({
      title: t('staking.restake-all-title'),
      body: (
        <InfoBlock
          items={[
            [
              {
                left: t('staking.amount-label'),
                right: t('number.accurate', { value: 10 }),
              },
              { left: t('staking.validator-label'), right: 'validator' },
            ],
            [
              {
                left: t('staking.amount-label'),
                right: t('number.accurate', { value: 10 }),
              },
              { left: t('staking.validator-label'), right: 'validator' },
            ],
            [
              {
                left: t('staking.amount-label'),
                right: t('number.accurate', { value: 10 }),
              },
              { left: t('staking.validator-label'), right: 'validator' },
            ],
          ]}
        />
      ),
      btnText: t('common.restake-all'),
      onBtnClick: handleSubmit,
    }),
    [],
  );

  const pendingContent: IStakeModalContent = useLocaleMemo(
    () => ({
      title: t('staking.restake-pending'),
      topHint: t('staking.pending-hint'),
      body: (
        <InfoBlock
          items={[
            [
              {
                left: t('staking.amount-label'),
                right: t('number.accurate', { value: 10 }),
              },
              { left: t('staking.validator-label'), right: 'validator' },
            ],
          ]}
        />
      ),
      btnText: t('staking.go-dashboard'),
      onBtnClick: onClose,
    }),
    [onClose],
  );

  const successContent: IStakeModalContent = useLocaleMemo(
    () => ({
      title: t('staking.restake-successful'),
      body: (
        <InfoBlock
          items={[
            [
              {
                left: t('staking.amount-label'),
                right: t('number.accurate', { value: 10 }),
              },
              { left: t('staking.validator-label'), right: 'validator' },
              { left: t('staking.tx-label'), right: 'validator' },
            ],
          ]}
        />
      ),
      btnText: t('staking.go-dashboard'),
      onBtnClick: onClose,
    }),
    [onClose],
  );

  const failedContent: IStakeModalContent = useLocaleMemo(
    () => ({
      title: t('staking.stake-pending'),
      topHint: t('staking.pending-hint'),
      body: <Box>Hello</Box>,
      bottomHint: t('staking.stake-hint-with-value', {
        value: t('time-unit.day', { value: APP_CHAIN_LOCK_PERIOD }),
      }),
      btnText: t('staking.go-dashboard'),
      onBtnClick: onClose,
    }),
    [onClose],
  );

  const renderer: IStakeModalRenderer = useMemo(
    () => ({
      [ProgressStatus.Start]: startContent,
      [ProgressStatus.Pending]: pendingContent,
      [ProgressStatus.Success]: successContent,
      [ProgressStatus.Failed]: failedContent,
    }),
    [startContent, pendingContent, successContent, failedContent],
  );

  return (
    <StakingModal open status={status} renderer={renderer} onClose={onClose} />
  );
}
