import { makeStyles } from 'tss-react/mui';

export const useQueryEmptyStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: 200,
    padding: theme.spacing(10),
    textAlign: 'center',
  },
}));
