import { makeStyles } from 'tss-react/mui';

export const usePendingValidatorsStyles = makeStyles()(theme => ({
  toolbarColumn: {
    flex: 2,
  },
  toolbarCell: {
    display: 'flex',
    columnGap: theme.spacing(2),
    flex: 1,
  },
  toolbarBtn: {
    flex: 1,
  },
}));
