import Web3 from 'web3';
import { Contract } from 'web3-eth-contract';
import { AbiItem } from 'web3-utils';

import { Address, Web3Uint256 } from '../../common/types';
import CHAIN_CONFIG_ABI from '../abi/ChainConfig.json';
import DEPLOYER_PROXY_ABI from '../abi/DeployerProxy.json';
import GOVERNANCE_ABI from '../abi/Governance.json';
import RUNTIME_UPGRADE_ABI from '../abi/RuntimeUpgrade.json';
import SLASHING_INDICATOR_ABI from '../abi/SlashingIndicator.json';
import STAKING_ABI from '../abi/Staking.json';
import STAKING_POOL_ABI from '../abi/StakingPool.json';
import SYSTEM_REWARD_ABI from '../abi/SystemReward.json';
import {
  IBasContractAddressRec,
  IBasContractRec,
  IDelegation,
  IHistory,
  IValidator,
  IWeb3Validator,
} from '../models';
import { BasEvent } from '../types';
import { sortEventData } from './index';
import { mapDelegation, mapHistoryList, mapValidator } from './mappers';

export const getDelegatedAmount = (
  contract: Contract,
  validator: Address,
  delegator: Address,
): Promise<IDelegation> => {
  return contract.methods
    .getValidatorDelegation(validator, delegator)
    .call()
    .then(mapDelegation);
};

export const getStakingRewards = (
  contract: Contract,
  validator: Address,
  delegator: Address,
): Promise<Web3Uint256> => {
  return contract.methods.getDelegatorFee(validator, delegator).call();
};

export const getPendingRewards = (
  contract: Contract,
  validator: Address,
  delegator: Address,
): Promise<Web3Uint256> => {
  return contract.methods.getPendingDelegatorFee(validator, delegator).call();
};

export const getContracts = (
  web3: Web3,
  addressRec: IBasContractAddressRec,
): IBasContractRec => {
  return {
    staking: new web3.eth.Contract(
      STAKING_ABI as AbiItem[],
      addressRec.staking,
    ),
    stakingPool: new web3.eth.Contract(
      STAKING_POOL_ABI as AbiItem[],
      addressRec.stakingPool,
    ),
    slashingIndicator: new web3.eth.Contract(
      SLASHING_INDICATOR_ABI as AbiItem[],
      addressRec.slashingIndicator,
    ),
    systemReward: new web3.eth.Contract(
      SYSTEM_REWARD_ABI as AbiItem[],
      addressRec.systemReward,
    ),
    governance: new web3.eth.Contract(
      GOVERNANCE_ABI as AbiItem[],
      addressRec.governance,
    ),
    chainConfig: new web3.eth.Contract(
      CHAIN_CONFIG_ABI as AbiItem[],
      addressRec.staking,
    ),
    runtimeUpgrade: new web3.eth.Contract(
      RUNTIME_UPGRADE_ABI as AbiItem[],
      addressRec.staking,
    ),
    deployerProxy: new web3.eth.Contract(
      DEPLOYER_PROXY_ABI as AbiItem[],
      addressRec.staking,
    ),
  };
};

export const getAllValidatorsAddresses = async (
  contract: Contract,
): Promise<Address[]> => {
  const [validatorAddedEvents, validatorRemovedEvents] = await Promise.all([
    contract.getPastEvents('ValidatorAdded', {
      fromBlock: 'earliest',
      toBlock: 'latest',
    }),
    contract.getPastEvents('ValidatorRemoved', {
      fromBlock: 'earliest',
      toBlock: 'latest',
    }),
  ]);
  const validators = new Set<Address>();
  for (const log of sortEventData(
    validatorAddedEvents,
    validatorRemovedEvents,
  )) {
    const { validator } = log.returnValues;
    if (log.event === 'ValidatorAdded') {
      validators.add(validator);
    } else if (log.event === 'ValidatorRemoved') {
      validators.delete(validator);
    }
  }
  return Array.from(validators);
};

export const getValidatorInfo = (
  contract: Contract,
  validatorAddress: Address,
): Promise<IValidator> => {
  return contract.methods
    .getValidatorStatus(validatorAddress)
    .call()
    .then((res: IWeb3Validator) => mapValidator(res, validatorAddress));
};

export const getAllValidators = async (contract: Contract) => {
  const addresses = await getAllValidatorsAddresses(contract);
  const validators: IValidator[] = await Promise.all(
    addresses.map(address => getValidatorInfo(contract, address)),
  );

  return validators;
};

export const getHistoryEvents = (
  contract: Contract,
  event: BasEvent,
  staker: Address,
): Promise<IHistory[]> => {
  return contract
    .getPastEvents(event, {
      fromBlock: 'earliest',
      toBlock: 'latest',
      filter: { staker },
    })
    .then(mapHistoryList);
};
