import React, { ReactNode } from 'react';
import { Box } from '@mui/material';

import { useInfoHelperBlockStyles } from './useInfoHelperBlockStyles';

interface IInfoHelperBlockProps {
  children: ReactNode;
  className?: string;
  withMarginBottom?: boolean;
}

export function InfoHelperBlock({
  children,
  className,
  withMarginBottom,
}: IInfoHelperBlockProps) {
  const { classes, cx } = useInfoHelperBlockStyles();

  return (
    <Box
      className={cx(
        classes.root,
        className,
        withMarginBottom && classes.withMarginBottom,
      )}
    >
      {children}
    </Box>
  );
}
