import { useQueryParam } from '../../../common/hooks/useQueryParam';
import { HomeSectionType, HomeUrlQueryParams } from '../../common';
import { HomeTop } from './components/HomeTop';
import { Staking } from './components/Staking';
import { Statistic } from './components/Statistic';
import { Validators } from './components/Validators';
import { useHomeStyles } from './useHomeStyles';

export function Home() {
  const { classes } = useHomeStyles();

  const [section, setSection] = useQueryParam(
    HomeUrlQueryParams.Section,
    HomeSectionType.Dashboard,
  );

  return (
    <>
      <HomeTop activeItem={section} onChange={setSection} />
      {section === HomeSectionType.Dashboard ? (
        <>
          <Statistic />
          <Validators />
        </>
      ) : (
        <Staking />
      )}
    </>
  );
}
