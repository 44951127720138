import React from 'react';
import { EEthereumNetworkId } from '@ankr.com/provider';
import { Card, Container } from '@mui/material';
import Typography from '@mui/material/Typography';

import { t } from '../../../i18n/utils/intl';
import { Button } from '../../../uiKit/Button';
import { getChainIcon } from '../../utils';
import { useWalletAccessStyles } from './useWalletAccessStyles';

interface IWalletAccessProps {
  requiredChainId: EEthereumNetworkId;
  onSwitch: () => void;
}

export function WalletAccess({
  requiredChainId,
  onSwitch,
}: IWalletAccessProps): JSX.Element {
  const { classes } = useWalletAccessStyles();

  return (
    <Container className={classes.container}>
      <Card className={classes.content}>
        <Typography variant="h2" className={classes.title}>
          {t('wallet-access.title')}
        </Typography>
        <Typography variant="body2" className={classes.hint}>
          {t('wallet-access.hint')}
          {getChainIcon(requiredChainId)}
          {t(`chain.${requiredChainId}`)}
        </Typography>
        {/* TODO: Need action */}
        <Button className={classes.btn} onClick={onSwitch}>
          {t('wallet-access.button')}
        </Button>
      </Card>
    </Container>
  );
}
