import { makeStyles } from 'tss-react/mui';

export const useIconButtonStyles = makeStyles()(theme => ({
  root: {
    position: 'relative',
  },

  loading: {
    pointerEvents: 'none',
    '&:active': {
      transform: 'none',
    },
  },

  loadingIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 18,
    color: theme.palette.text.primary,
    '& > svg': {
      display: 'block',
      width: '1em',
      height: '1em',
    },
  },
}));
