import React from 'react';
import { generatePath, Route, useParams } from 'react-router-dom';

import { createRouteConfig } from '../../utils/createRouteConfig';
import { Network } from '../common/hooks/useCurrentNetwork';
import { Address } from '../common/types';
import { t } from '../i18n/utils/intl';
import { LayoutDefault } from '../layout/components/LayoutDefault';
import { NewValidator } from './screens/NewValidator';
import { Validator } from './screens/Validator';

const PATH_VALIDATORS = 'validator';
const PATH_TO_VALIDATOR = 'validator/:address';
const PATH_TO_VALIDATOR_NEW = 'validator/new';

interface IValidatorRouteProps {
  address: Address;
  network: Network | string;
}

export const ValidatorRoutesConfig = createRouteConfig(
  {
    Validator: {
      path: PATH_TO_VALIDATOR,
      generatePath: ({ address, network }: IValidatorRouteProps) =>
        generatePath(`/${network}/${PATH_TO_VALIDATOR}`, { address }),
      useParams: () => {
        return useParams<{
          address: Address;
        }>();
      },
    },
    ValidatorNew: {
      path: PATH_TO_VALIDATOR_NEW,
      generatePath: (network: Network | string) =>
        generatePath(`/${network}/${PATH_TO_VALIDATOR_NEW}`),
    },
  },
  PATH_VALIDATORS,
);

export function getValidatorRoutes(): JSX.Element {
  return (
    <>
      <Route
        path={ValidatorRoutesConfig.Validator.path}
        element={
          <LayoutDefault title={t('page-title.validator')}>
            <Validator />
          </LayoutDefault>
        }
      />
      <Route
        path={ValidatorRoutesConfig.ValidatorNew.path}
        element={
          <LayoutDefault title={t('page-title.new-validator')}>
            <NewValidator />
          </LayoutDefault>
        }
      />
    </>
  );
}
