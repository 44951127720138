import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useQueryParam = (
  key: string,
  defaultValue = '',
): [string, (v: string) => void] => {
  const [params, setParams] = useSearchParams();
  const paramsRef = useRef(params);
  const [value, setValue] = useState(params.get(key) || defaultValue);
  const valueRef = useRef(value);

  const setQueryParam = useCallback(
    (v: string) => {
      valueRef.current = v;
      paramsRef.current.set(key, v);
      setParams(paramsRef.current);
      setValue(v);
    },
    [key, setParams],
  );

  useEffect(() => {
    paramsRef.current = params;
    const v = params.get(key);
    if (valueRef.current !== v) {
      setQueryParam(v || defaultValue);
    }
  }, [params, key, defaultValue, setQueryParam]);

  return useMemo(() => [value, setQueryParam], [setQueryParam, value]);
};
