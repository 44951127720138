import React from 'react';
import { Link, useMatch } from 'react-router-dom';

import { Button } from '../../../../../uiKit/Button';
import { useHeaderNavigationBtnStyles } from './useHeaderNavigationBtnStyles';

export interface IHeaderNavigationBtn {
  path: string;
  title: string;
}

export function HeaderNavigationBtn({ path, title }: IHeaderNavigationBtn) {
  const { classes, cx } = useHeaderNavigationBtnStyles();

  const match = useMatch(path);

  return (
    <Button
      component={Link}
      to={path}
      className={cx(classes.btn, match && classes.btnActive)}
      variant="text"
    >
      {title}
    </Button>
  );
}
