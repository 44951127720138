import React from 'react';
import { generatePath, Route } from 'react-router-dom';

import { createRouteConfig } from '../../utils/createRouteConfig';
import { Network } from '../common/hooks/useCurrentNetwork';
import { t } from '../i18n/utils/intl';
import { LayoutDefault } from '../layout/components/LayoutDefault';
import { Ui } from './screens';

const PATH = 'ui';

export const UiRoutesConfig = createRouteConfig(
  {
    Ui: {
      path: PATH,
      generatePath: (network: Network | string) =>
        generatePath(`/${network}/${PATH}`),
    },
  },
  PATH,
);

export function getUiRoutes(): JSX.Element {
  return (
    <Route
      path={UiRoutesConfig.root}
      element={
        <LayoutDefault title={t('page-title.ui')}>
          <Ui />
        </LayoutDefault>
      }
    />
  );
}
