import { makeStyles } from 'tss-react/mui';

export const useNetworkSwitcherStyles = makeStyles()(theme => ({
  root: {
    width: 260,
    color: theme.palette.text.disabled,
    height: 64,
    borderColor: 'transparent',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    '& > .MuiSelect-select': {
      fontSize: 20,
      [theme.breakpoints.down('md')]: {
        fontSize: 14,
      },
      '& svg': {
        fontSize: 40,
        height: 40,
        width: 40,
        marginRight: theme.spacing(1),
      },
    },
  },
  compact: {
    width: 'auto',
    height: 36,
    '& > .MuiSelect-select': {
      fontSize: 14,
      '& svg': {
        fontSize: 24,
        height: 24,
        width: 24,
        marginRight: 0,
      },
    },
  },
  item: {
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  itemContent: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  checkedIcon: {
    '&&': {
      width: 16,
      margin: 0,
      color: theme.palette.primary.dark,
    },
  },
}));
