export enum HomeUrlQueryParams {
  Section = 'section',
  Validators = 'validators',
  Assets = 'assets',
}

export enum HomeSectionType {
  Dashboard = 'dashboard',
  Staking = 'staking',
}

export enum ValidatorsType {
  Active = 'active',
  Pending = 'pending',
}

export enum AssetsType {
  Staked = 'staked',
  Claimable = 'claimable',
  History = 'history',
}
