import React, { useCallback } from 'react';
import { Box, Dialog, DialogContent, DialogTitle } from '@mui/material';

import { useDisconnectWalletMutation } from '../../../Bas/actions/bas';
import { ReactComponent as CrossIcon } from '../../../common/components/Icons/CrossIcon.svg';
import { Address } from '../../../common/types';
import { truncateWalletAddress } from '../../../common/utils/truncateWalletAddress';
import { t } from '../../../i18n/utils/intl';
import { Button } from '../../../uiKit/Button';
import { CopyButton } from '../../../uiKit/CopyButton';
import { IconButton } from '../../../uiKit/IconButton';
import { useAccountModalStyles } from './useAccountModalStyles';

interface IAccountModalProps {
  account: Address;
  walletIcon: JSX.Element;
  onClose: () => void;
  open?: boolean;
}

export function AccountModal({
  account,
  walletIcon,
  onClose,
  open = false,
}: IAccountModalProps): JSX.Element {
  const { classes } = useAccountModalStyles();

  const [disconnectWallet] = useDisconnectWalletMutation();

  const handleDisconnect = useCallback(() => {
    void disconnectWallet();
    onClose();
  }, [disconnectWallet, onClose]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle className={classes.title}>
        {t('common.connected-wallet')}
        <IconButton
          aria-label="close"
          onClick={onClose}
          className={classes.closeBtn}
          color="inherit"
          size="large"
        >
          <CrossIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <Box className={classes.walletWrap}>
          <Box className={classes.wallet}>
            {walletIcon}
            {truncateWalletAddress(account)}
          </Box>
          <CopyButton text={account} className={classes.copyBtn} />
        </Box>
        <Button
          className={classes.disconnectBtn}
          size="large"
          onClick={handleDisconnect}
        >
          {t('common.disconnect')}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
