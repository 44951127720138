export const MIN_LAYOUT_WIDTH = 320;
export const MAX_LAYOUT_WIDTH = 1440;
export const HEADER_HEIGHT = 70;
export const MAIN_BLOCK_PADDING_BOTTOM = 40;

export const FONTS = {
  // fontWeight: 400
  primary: '"Inter-Regular", Arial, sans-serif',
  // fontWeight: 400
  regular: '"Inter-Regular", Arial, sans-serif',
  // fontWeight: 500
  medium: '"Inter-Medium", "Inter-Regular", Arial, sans-serif',
  // fontWeight: 600
  semiBold:
    '"Inter-SemiBold", "Inter-Medium", "Inter-Regular", Arial, sans-serif',
  // fontWeight: 700
  bold: '"Inter-Bold", "Inter-SemiBold", "Inter-Medium", "Inter-Regular", Arial, sans-serif',
  // fontWeight: 700
  secondary:
    '"Inter-Bold", "Inter-SemiBold", "Inter-Medium", "Inter-Regular", Arial, sans-serif',
};
