import { useEffect } from 'react';

import { t } from '../../i18n/utils/intl';

export const usePageTitle = (title?: string) => {
  useEffect(() => {
    let titleString = title ? t('page-title-with-separator', { title }) : '';
    titleString += t('project-name');
    document.title = titleString;
  }, [title]);
};
