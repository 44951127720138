import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../../../../../themes/const';

export const useNewValidatorCompleteModalStyles = makeStyles()(theme => ({
  paper: {
    maxWidth: 700,
  },
  title: {
    fontFamily: FONTS.bold,
    fontWeight: 700,
    fontSize: 30,
    textAlign: 'center',
    padding: theme.spacing(10, 15, 5),
  },
  content: {
    padding: theme.spacing(0, 15, 10),
    textAlign: 'center',
  },
  text: {
    fontFamily: FONTS.regular,
    fontWeight: 400,
    lineHeight: 1.5,
    marginBottom: theme.spacing(6),
  },
}));
