import React, { useState } from 'react';

import { useGetAccountQuery } from '../../../Bas/actions/bas';
import { ReactComponent as MetamaskIcon } from '../../../common/components/Icons/MetamaskIcon.svg';
import { truncateWalletAddress } from '../../../common/utils/truncateWalletAddress';
import { t } from '../../../i18n/utils/intl';
import { Button } from '../../../uiKit/Button';
import { AccountModal } from '../AccountModal';
import { AuthModal } from '../AuthModal';
import { useConnectWalletButtonStyles } from './useConnectWalletButtonStyles';

interface IConnectWalletButtonProps {
  className?: string;
  onlyConnectButton?: boolean;
}

export function ConnectWalletButton({
  className,
  onlyConnectButton = false,
}: IConnectWalletButtonProps): JSX.Element {
  const [authModalOpen, setAuthModalOpen] = useState(false);
  const [accountModalOpen, setAccountModalOpen] = useState(false);
  const { classes, cx } = useConnectWalletButtonStyles();

  const { data: currentAccount } = useGetAccountQuery();

  if (currentAccount && !onlyConnectButton) {
    return (
      <>
        <Button
          className={cx(classes.btn, classes.btnConnected, className)}
          onClick={() => setAccountModalOpen(true)}
          startIcon={<MetamaskIcon />}
        >
          {truncateWalletAddress(currentAccount)}
        </Button>
        <AccountModal
          account={currentAccount}
          walletIcon={<MetamaskIcon />}
          open={accountModalOpen}
          onClose={() => setAccountModalOpen(false)}
        />
      </>
    );
  }

  return (
    <>
      <Button
        className={cx(classes.btn, classes.btnConnect, className)}
        onClick={() => setAuthModalOpen(true)}
      >
        {t('common.connect-wallet')}
      </Button>
      <AuthModal open={authModalOpen} onClose={() => setAuthModalOpen(false)} />
    </>
  );
}
