import { makeStyles } from 'tss-react/mui';

import { FONTS } from 'modules/themes/const';

export const useHomeTopStyles = makeStyles()(theme => ({
  root: {
    marginTop: theme.spacing(13),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(10),
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(9),
    [theme.breakpoints.down('md')]: {
      columnGap: theme.spacing(7),
    },
    [theme.breakpoints.down('sm')]: {
      columnGap: theme.spacing(3),
    },
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      height: theme.spacing(6),
      width: theme.spacing(6),
    },
  },
  menuItem: {
    cursor: 'pointer',
    fontFamily: FONTS.bold,
    fontSize: 27,
    fontWeight: 700,
    color: theme.palette.grey['500'],
    [theme.breakpoints.down('md')]: {
      fontSize: 16,
    },
  },
  menuItemActive: {
    color: theme.palette.grey['900'],
  },
}));
