/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useCallback, useMemo, useState } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { uid } from 'react-uid';
import {
  Box,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedTextFieldProps,
  Select,
  TextFieldProps,
} from '@mui/material';

import { ReactComponent as ChevronDownIcon } from '../../../common/components/Icons/ChevronDownIcon.svg';
import { ReactComponent as CompleteIcon } from '../../../common/components/Icons/CompleteIcon.svg';
import { QueryLoading } from '../../../common/components/QueryLoading';
import { FORM_ITEMS_MARGIN } from '../../../common/const';
import { getErrorText } from '../../utils/getErrorText';
import { hasError } from '../../utils/hasError';
import { useSelectFieldStyles } from './useSelectFieldStyles';

export interface ISelectOption {
  label: string;
  value?: string | number;
  disabled?: boolean;
  isSubheader?: boolean;
}

interface ISelectComponent
  extends OutlinedTextFieldProps,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FieldRenderProps<any> {
  options: ISelectOption[];
  helperText?: string;
  disabled?: boolean;
  loading?: boolean;
}

export function SelectField({
  input: { name, onChange, value },
  meta,
  label,
  id = uid(`${name}-select`),
  withBottomMargin = true,
  options,
  helperText,
  placeholder,
  disabled = false,
  loading = false,
  sideHint,
  ...rest
}: ISelectComponent & TextFieldProps) {
  const { classes, cx } = useSelectFieldStyles();

  const [open, setOpen] = useState(false);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const errorText = useMemo(() => {
    return getErrorText(meta);
  }, [meta]);

  const renderValue = useCallback(
    (value: string | number) => {
      const currentOption = options.find(o => o.value === value);
      return (
        <span className={classes.itemContent}>
          {currentOption ? currentOption.label : placeholder}
        </span>
      );
    },
    [classes, options, placeholder],
  );

  const items = useMemo(() => {
    if (options && options.length) {
      return options.map(item => {
        const selected = item.value === value;
        return item.isSubheader ? (
          <ListSubheader key={uid(item)}>{item.label}</ListSubheader>
        ) : (
          <MenuItem
            key={uid(item)}
            value={item.value}
            disabled={item.disabled}
            className={classes.item}
          >
            <span className={classes.itemContent}>{item.label}</span>
            {selected && <CompleteIcon className={classes.checkedIcon} />}
          </MenuItem>
        );
      });
    }
  }, [value, options, classes.checkedIcon, classes.item, classes.itemContent]);

  return (
    <Box
      mb={withBottomMargin ? FORM_ITEMS_MARGIN : 0}
      className={cx(classes.root, loading && classes.loading)}
    >
      <Box className={classes.labelBox}>
        {label && (
          <InputLabel error={!!errorText} htmlFor={id} onClick={handleOpen}>
            {label}
          </InputLabel>
        )}
        {sideHint}
      </Box>
      <Select
        id={id}
        name={name}
        error={hasError(meta)}
        renderValue={() => renderValue(value)}
        value={value}
        displayEmpty={!!placeholder}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={onChange as any}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        disabled={loading || disabled}
        IconComponent={loading ? QueryLoading : ChevronDownIcon}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(rest as any)}
      >
        {!!placeholder && (
          <MenuItem value="">
            <span className={classes.placeholder}>{placeholder}</span>
          </MenuItem>
        )}
        {items}
      </Select>
      {!!errorText && <FormHelperText error>{errorText}</FormHelperText>}
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </Box>
  );
}
