import { EWalletId, ProviderManager } from '@ankr.com/provider';
import { EthereumWeb3KeyProvider } from '@ankr.com/provider/build/providerManager/providers';
import { Web3KeyWriteProvider } from '@ankr.com/provider/build/providerManager/Web3KeyWriteProvider';
import BigNumber from 'bignumber.js';
import Web3 from 'web3';

import { promiseDelay } from '../api/utils';
import { ETH_EXPONENT } from '../common/const';
import { Address, TxId, Web3Uint256 } from '../common/types';
import { DEFAULT_BAS_CONFIG } from './const';
import {
  IBasAppConfig,
  IBasContractRec,
  IChainData,
  IDelegation,
  IHistory,
  IValidator,
} from './models';
import { BasError, BasEvent, ValidatorStatus } from './types';
import {
  getAllValidatorsAddresses,
  getContracts,
  getDelegatedAmount,
  getHistoryEvents,
  getPendingRewards,
  getStakingRewards,
  getValidatorInfo,
} from './utils/stakingUtils';
import { changeNetwork, sendWeb3Tx } from './utils/web3Utils';

export class BasApp {
  appConfig: IBasAppConfig | undefined;

  web3: Web3 | undefined;

  contractRec: IBasContractRec | undefined;

  account: string | undefined;

  balance: BigNumber | undefined;

  provider: Web3KeyWriteProvider | EthereumWeb3KeyProvider | undefined;

  chainData: any;

  // eslint-disable-next-line no-useless-constructor
  constructor(private providerManager: ProviderManager) {}

  // TODO: make dynamic
  async initApp(appName: string): Promise<void> {
    await promiseDelay(1, 100);
    this.appConfig = DEFAULT_BAS_CONFIG;
  }

  async initWallet(connectedWalletID?: EWalletId): Promise<void> {
    if (!this.appConfig) {
      throw BasError.NotConfiguredNetwork;
    }

    const walletID =
      connectedWalletID || (localStorage.getItem('walletID') as EWalletId);

    if (!walletID) {
      throw BasError.NoWallet;
    }

    let provider: Web3KeyWriteProvider | EthereumWeb3KeyProvider | undefined;

    try {
      provider = await this.providerManager.getETHWriteProvider(walletID);
    } catch (e) {
      throw BasError.Connection;
    }

    if (!provider || !provider.currentAccount) {
      throw BasError.NoWallet;
    }

    const web3 = provider.getWeb3();

    let accounts: string[];

    try {
      accounts = await web3.eth.requestAccounts();
    } catch (e) {
      // User denied access to account
      throw BasError.RequestAccount;
    }

    const account = accounts[0];

    if (!account) {
      // Unable to detect unlocked MetaMask account
      throw BasError.RequestAccount;
    }

    const remoteChainId = await web3.eth.getChainId();

    if (remoteChainId !== this.appConfig.chainId) {
      await changeNetwork(web3, this.appConfig);
    }

    const balance = await web3.eth.getBalance(accounts[0]);

    this.balance = new BigNumber(balance);
    this.account = account;
    this.contractRec = getContracts(web3, this.appConfig.addressRec);
    this.web3 = web3;
    this.provider = provider;
  }

  async connectWallet(walletID: EWalletId) {
    await this.initWallet(walletID);
    localStorage.setItem('walletID', walletID);
  }

  disconnectWallet() {
    if (!this.provider) {
      return;
    }
    this.provider.disconnect();
    localStorage.removeItem('walletID');
    this.reset();
  }

  async getBalance() {
    if (!this.web3 || !this.account) {
      throw BasError.NoWallet;
    }

    const balanceWei = await this.web3.eth.getBalance(this.account);

    return new BigNumber(balanceWei).dividedBy(ETH_EXPONENT);
  }

  async getChainData(): Promise<IChainData> {
    if (!this.contractRec || !this.account) {
      throw BasError.NoWallet;
    }

    const { staking } = this.contractRec;

    const { account } = this;

    const [validatorAddresses, currentEpoch] = await Promise.all([
      getAllValidatorsAddresses(staking),
      staking.methods.currentEpoch().call(),
    ]);

    const [validatorsInfo, userDelegations, stakingRewards, pendingRewards]: [
      IValidator[],
      IDelegation[],
      Web3Uint256[],
      Web3Uint256[],
    ] = await Promise.all([
      Promise.all(
        validatorAddresses.map(item => getValidatorInfo(staking, item)),
      ),
      Promise.all(
        validatorAddresses.map(item =>
          getDelegatedAmount(staking, item, account),
        ),
      ),
      Promise.all(
        validatorAddresses.map(item =>
          getStakingRewards(staking, item, account),
        ),
      ),
      Promise.all(
        validatorAddresses.map(item =>
          getPendingRewards(staking, item, account),
        ),
      ),
    ]);

    let totalDelegation = new BigNumber(0);
    let userTotalDelegation = new BigNumber(0);
    let userTotalRewards = new BigNumber(0);
    let userTotalPendingRewards = new BigNumber(0);

    validatorsInfo.forEach((item, index) => {
      totalDelegation = totalDelegation.plus(item.totalDelegated);
      const { delegatedAmount } = userDelegations[index];
      const stakedReward = new BigNumber(stakingRewards[index]);
      const pendingReward = new BigNumber(pendingRewards[index]);
      if (!delegatedAmount.isZero()) {
        userTotalDelegation = userTotalDelegation.plus(delegatedAmount);
        // eslint-disable-next-line no-param-reassign
        item.delegatedAmount = userDelegations[index].delegatedAmount;
        // eslint-disable-next-line no-param-reassign
        item.delegatedAmountNomination =
          item.delegatedAmount.dividedBy(ETH_EXPONENT);
      }
      if (!stakedReward.isZero()) {
        // eslint-disable-next-line no-param-reassign
        item.rewardAmount = stakedReward;
        // eslint-disable-next-line no-param-reassign
        item.rewardAmountNomination = stakedReward.dividedBy(ETH_EXPONENT);
        userTotalRewards = userTotalRewards.plus(stakedReward);
      }
      if (!pendingReward.isZero()) {
        // eslint-disable-next-line no-param-reassign
        item.pendingFee = pendingReward;
        // eslint-disable-next-line no-param-reassign
        item.pendingFeeNomination = pendingReward.dividedBy(ETH_EXPONENT);
        userTotalPendingRewards = userTotalPendingRewards.plus(pendingReward);
      }
    });

    validatorsInfo.forEach(item => {
      // eslint-disable-next-line no-param-reassign
      item.stakedShare = item.totalDelegated
        .dividedBy(totalDelegation)
        .toNumber();
    });

    const activeValidators = validatorsInfo.filter(
      item => item.status === ValidatorStatus.Active,
    );

    const pendingValidators = validatorsInfo.filter(
      item => item.status === ValidatorStatus.Pending,
    );

    const delegatedValidators = validatorsInfo.filter(
      item => !!item.delegatedAmount,
    );

    const claimableValidators = validatorsInfo.filter(
      item => !!item.rewardAmount || !!item.pendingFee,
    );

    return {
      currentEpoch,
      activeValidatorsCount: activeValidators.length,
      pendingValidatorsCount: pendingValidators.length,
      totalDelegation,
      activeValidators,
      pendingValidators,
      delegatedValidators,
      userTotalDelegation,
      claimableValidators,
      userTotalRewards,
    };
  }

  async registerValidator({
    validator,
    commissionRate,
    initialStake,
  }: {
    validator: Address;
    commissionRate: number;
    initialStake: number;
  }) {
    if (!this.account || !this.appConfig || !this.contractRec) {
      throw BasError.NoWallet;
    }
    const data = this.contractRec.staking.methods
      .registerValidator(validator, commissionRate)
      .encodeABI();

    return sendWeb3Tx(this.web3, {
      from: this.account,
      to: this.appConfig?.addressRec.staking,
      value: new BigNumber(initialStake)
        .multipliedBy(ETH_EXPONENT)
        .toString(10),
      data,
    });
  }

  async delegate(validator: Address, amount: number): Promise<TxId> {
    if (!this.account || !this.appConfig || !this.contractRec) {
      throw BasError.NoWallet;
    }
    const data = this.contractRec.staking.methods
      .delegate(validator)
      .encodeABI();

    const res = await sendWeb3Tx(this.web3, {
      from: this.account,
      to: this.appConfig?.addressRec.staking,
      value: new BigNumber(amount).multipliedBy(ETH_EXPONENT).toString(10),
      data,
    });

    await res.receipt;

    return res.transactionHash;
  }

  async unDelegate(validator: Address, amount: number): Promise<TxId> {
    if (!this.account || !this.appConfig || !this.contractRec) {
      throw BasError.NoWallet;
    }

    const unDelegateAmount = new BigNumber(amount)
      .multipliedBy(ETH_EXPONENT)
      .toString(10);

    const data = this.contractRec?.staking.methods
      .undelegate(validator, unDelegateAmount)
      .encodeABI();

    const res = await sendWeb3Tx(this.web3, {
      from: this.account,
      to: this.appConfig?.addressRec.staking,
      data,
    });

    await res.receipt;

    return res.transactionHash;
  }

  async claimDelegatorFee(validator: Address) {
    if (!this.account || !this.contractRec || !this.appConfig) {
      throw BasError.NoWallet;
    }

    const data = this.contractRec.staking.methods
      .claimDelegatorFee(validator)
      .encodeABI();

    const res = await sendWeb3Tx(this.web3, {
      from: this.account,
      to: this.appConfig.addressRec.staking,
      data,
    });

    await res.receipt;

    return res.transactionHash;
  }

  async getMinStakingAmount(): Promise<BigNumber> {
    const res = await this.contractRec?.staking.methods
      .getMinStakingAmount()
      .call();
    return new BigNumber(res);
  }

  async getHistory(): Promise<IHistory[]> {
    if (!this.account || !this.contractRec) {
      throw BasError.NoWallet;
    }

    const { staking } = this.contractRec;

    const result = await Promise.all([
      getHistoryEvents(staking, BasEvent.Claimed, this.account),
      getHistoryEvents(staking, BasEvent.Delegated, this.account),
      getHistoryEvents(staking, BasEvent.Undelegated, this.account),
    ]);

    return result.flat().sort((a, b) => a.epoch - b.epoch);
  }

  private reset() {
    this.account = undefined;
    this.balance = undefined;
    this.web3 = undefined;
    this.provider = undefined;
  }
}
