import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../../../themes/const';

export const useNumberFormatFieldStyles = makeStyles()(theme => ({
  wrap: {},
  labelBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    flexWrap: 'wrap',
  },
  input: {},
  filled: {
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.background.default,
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  controlButtons: {
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  controlButton: {
    color: theme.palette.text.disabled,
    backgroundColor: theme.palette.grey[300],
    fontFamily: FONTS.regular,
    fontWeight: 400,
    fontSize: 12,
    height: 24,
    minHeight: 'auto',
    minWidth: 'auto',
    '&:hover': {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.primary.dark,
    },
  },
  stepButton: {
    width: 24,
    height: 24,
  },
  maxButton: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
