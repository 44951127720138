import { makeStyles } from 'tss-react/mui';

import {
  HEADER_HEIGHT,
  MAIN_BLOCK_PADDING_BOTTOM,
  MIN_LAYOUT_WIDTH,
} from '../../../themes/const';

export const useLayoutDefaultStyles = makeStyles()(() => ({
  root: {
    minWidth: MIN_LAYOUT_WIDTH,
  },

  main: {
    paddingTop: HEADER_HEIGHT,
    paddingBottom: MAIN_BLOCK_PADDING_BOTTOM,
  },
}));
