import React from 'react';
import { Box } from '@mui/material';

import { useGetBalanceQuery } from '../../../Bas/actions/bas';
import { t } from '../../../i18n/utils/intl';
import { NetworkName } from '../NetworkName';
import { Spinner } from '../Spinner';
import { useYourBalanceStyles } from './useYourBalanceStyles';

interface IYourBalanceProps {
  buttonVariant?: 'text' | 'outlined' | 'contained';
  smallView?: boolean;
}

export function YourBalance({
  smallView,
  buttonVariant = 'outlined',
}: IYourBalanceProps) {
  const { classes, cx } = useYourBalanceStyles();

  const { data: balance, isLoading } = useGetBalanceQuery();

  return (
    <Box className={cx(classes.root, smallView && classes.smallView)}>
      {balance && (
        <Box className={classes.content}>
          <Box className={classes.balanceText}>{t('common.your-balance')}</Box>
          {isLoading ? (
            <Spinner />
          ) : (
            <>
              <NetworkName
                withoutText
                inlineView
                avatarClassName={classes.avatarClassName}
              />
              <Box className={classes.balanceValue}>
                {t('number.accurate-5', { value: balance })}
              </Box>
              {/* <Button
                className={classes.getBtn}
                color="primary"
                variant={buttonVariant}
                size="small"
                href={GET_TOKENS_URL}
                rel="noopener noreferrer"
                target="_blank"
              >
                {t('common.get')}
              </Button> */}
            </>
          )}
        </Box>
      )}
    </Box>
  );
}
