import React, { ReactNode, useCallback } from 'react';
import { uid } from 'react-uid';
import { Box } from '@mui/material';

import { useIsSMDown } from '../../../themes/hooks/useTheme';
import { QueryEmpty } from '../QueryEmpty';
import { QueryLoadingCentered } from '../QueryLoading';
import { useAppListStyles } from './useAppListStyles';

export interface IAppListColumn<T> {
  header: ReactNode;
  renderCell: (v: T) => ReactNode;
  className?: string;
  cellClassName?: string;
  cardHidden?: boolean;
}

interface AppListProps<T> {
  items: T[];
  columns: IAppListColumn<T>[];
  className?: string;
  isLoading?: boolean;
  compactView?: boolean;
}

export function AppList<T>({
  items,
  columns,
  className,
  isLoading,
  compactView,
}: AppListProps<T>) {
  const { classes, cx } = useAppListStyles();

  const isSMDown = useIsSMDown();

  const renderRow = useCallback(
    (item: T) => (
      <Box
        key={uid(item)}
        className={cx(classes.row, compactView && classes.rowCompact)}
      >
        {columns.map(column => (
          <Box
            key={uid(column)}
            className={cx(
              classes.column,
              column.className,
              column.cellClassName,
            )}
          >
            {column.renderCell(item)}
          </Box>
        ))}
      </Box>
    ),
    [classes, columns, cx, compactView],
  );

  const renderCard = useCallback(
    (item: T) => (
      <Box key={uid(item)} className={classes.card}>
        <Box className={classes.cardBody}>
          {columns.map(column => (
            <Box key={uid(column)} className={classes.cardRow}>
              <Box className={classes.cardLeft}>{column.header}</Box>
              <Box className={cx(classes.cardRight, column.cellClassName)}>
                {column.renderCell(item)}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    ),
    [classes, columns, cx],
  );

  const renderGrid = useCallback(() => {
    return isSMDown ? (
      <Box className={classes.cardList}>{items.map(renderCard)}</Box>
    ) : (
      <>
        <Box className={classes.header}>
          {columns.map(column => (
            <Box
              key={uid(column)}
              className={cx(classes.column, column.className)}
            >
              {column.header}
            </Box>
          ))}
        </Box>
        <Box className={classes.rowList}>{items.map(renderRow)}</Box>
      </>
    );
  }, [classes, columns, cx, isSMDown, items, renderCard, renderRow]);

  if (items && !items.length && !isLoading) {
    return <QueryEmpty />;
  }

  return (
    <Box classes={cx(classes.root, className)}>
      {isLoading ? <QueryLoadingCentered /> : renderGrid()}
    </Box>
  );
}
