import { Link } from 'react-router-dom';
import { Box, Tooltip } from '@mui/material';

import { useGetHistoryQuery } from '../../../../../../../Bas/actions/bas';
import { IHistory } from '../../../../../../../Bas/models';
import {
  AppList,
  IAppListColumn,
} from '../../../../../../../common/components/AppList';
import { BlockchainLink } from '../../../../../../../common/components/BlockchainLink';
import { useCurrentNetwork } from '../../../../../../../common/hooks/useCurrentNetwork';
import { HashType } from '../../../../../../../common/types';
import { truncateWalletAddress } from '../../../../../../../common/utils/truncateWalletAddress';
import { t } from '../../../../../../../i18n/utils/intl';
import { useLocaleMemo } from '../../../../../../../i18n/utils/useLocaleMemo';
import { CopyButton } from '../../../../../../../uiKit/CopyButton';
import { ValidatorRoutesConfig } from '../../../../../../../Validator/ValidatorRoutes';

export function History() {
  const { currentNetwork: network } = useCurrentNetwork();

  const { data, isLoading } = useGetHistoryQuery();

  const columns: IAppListColumn<IHistory>[] = useLocaleMemo(
    () => [
      {
        header: <Box>{t('dashboard.type')}</Box>,
        renderCell: item => t(`history-type.${item.event}`),
      },
      {
        header: <Box>{t('dashboard.amount')}</Box>,
        renderCell: item => t('number.accurate', { value: item.amount }),
      },
      {
        header: t('dashboard.validator'),
        renderCell: item => (
          <>
            <Tooltip title={item.validator}>
              <Link
                to={ValidatorRoutesConfig.Validator.generatePath({
                  address: item.validator,
                  network,
                })}
              >
                {truncateWalletAddress(item.validator)}
              </Link>
            </Tooltip>
            <CopyButton text={item.validator} />
          </>
        ),
      },
      {
        header: <Box>{t('dashboard.block')}</Box>,
        renderCell: item => (
          <BlockchainLink
            value={item.blockNumber.toString()}
            type={HashType.Block}
          />
        ),
      },
      {
        header: <Box>{t('dashboard.tx-hash')}</Box>,
        renderCell: item => (
          <BlockchainLink
            value={item.transactionHash}
            type={HashType.Tx}
            trim
            withCopy
          />
        ),
      },
    ],
    [network],
  );

  return <AppList items={data || []} isLoading={isLoading} columns={columns} />;
}
