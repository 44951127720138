import { makeStyles } from 'tss-react/mui';

import { HEADER_HEIGHT, MIN_LAYOUT_WIDTH } from '../../../themes/const';

export const useHeaderStyles = makeStyles()(theme => ({
  appBar: {
    '&&': {
      top: 0,
      transitionProperty:
        'box-shadow, transform, border-color, background-color !important',
      color: theme.palette.text.primary,
      height: HEADER_HEIGHT,
      borderRadius: 0,
      backgroundColor: theme.palette.background.paper,
    },
  },

  fixed: {
    boxShadow: 'none',
  },
  absolute: {
    position: 'absolute',
  },

  mobileMenuIsOpen: {},
  container: {
    height: '100%',
    '&&': {
      maxWidth: 'none',
    },
  },
  toolbar: {
    height: '100%',
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(8),
    padding: theme.spacing(3, 0),
    [theme.breakpoints.down('sm')]: {
      gap: theme.spacing(3),
    },
  },

  toolbarContent: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
  },

  logoWrap: {
    height: '100%',
    display: 'flex',
    gap: theme.spacing(3.5),
    alignItems: 'center',
  },
  mobileLogoWrap: {
    height: 'auto',
  },
  logo: {
    height: '100%',
  },

  navigation: {
    display: 'flex',
    gap: theme.spacing(8),
    alignItems: 'center',
  },
  navigationDesktop: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },

  mobileMenu: {},
  drawer: {},
  drawerPaper: {
    borderRadius: 0,
    backgroundColor: theme.palette.background.default,
    minWidth: MIN_LAYOUT_WIDTH,
  },

  drawerBackdrop: {},
  mobileMenuWrap: {
    maxHeight: '100%',
    padding: theme.spacing(7, 5),
  },
  mobileMenuContent: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    gap: theme.spacing(5),
  },
  headerButtons: {
    display: 'flex',
    gap: theme.spacing(2),
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
    },
  },
}));
