import { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { HomeRoutesConfig } from '../../Home/HomeRoutes';
import { t } from '../../i18n/utils/intl';
import { camelCaseToCapitalize } from '../utils/camelCaseToCapitalize';

export enum Network {
  default = 'default',
  dogechain = 'dogechain',
  multiverse = 'multiverse',
  metaapes = 'metaapes',
}

// It's required to replace placeholder after build
export const NETWORK_DEFAULT: Network | string =
  // eslint-disable-next-line no-template-curly-in-string
  process.env.REACT_APP_DEFAULT_NETWORK ?? '${REACT_APP_DEFAULT_NETWORK}';

export const useCurrentNetwork = () => {
  const navigate = useNavigate();
  const { network } = useParams();
  const location = useLocation();

  const currentNetwork = useMemo(() => {
    return network ?? NETWORK_DEFAULT;
  }, [network]);

  const currentNetworkName = useMemo(() => {
    if (!!network && network in Network) {
      return t(`networks.${network}`);
    }
    return camelCaseToCapitalize(network ?? NETWORK_DEFAULT);
  }, [network]);

  const setCurrentNetwork = useCallback(
    (network: Network | string) => {
      const splitLocation = location.pathname.split('/');
      if (splitLocation[1]) {
        splitLocation[1] = network;
        navigate(splitLocation.join('/'));
      } else {
        navigate(HomeRoutesConfig.Home.generatePath(network));
      }
    },
    [location.pathname, navigate],
  );

  const checkAndRedirect = useCallback(() => {
    const splitLocation = location.pathname.split('/');
    const networkInUrl = splitLocation[1];
    if (!networkInUrl) {
      navigate(HomeRoutesConfig.Home.generatePath(NETWORK_DEFAULT));
    }
  }, [location.pathname, navigate]);

  return {
    currentNetwork,
    currentNetworkName,
    setCurrentNetwork,
    checkAndRedirect,
  };
};
