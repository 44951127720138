import React, { forwardRef } from 'react';
import { ButtonProps } from '@mui/material';

import { IconButton } from '../../../../../uiKit/IconButton';
import { useToggleStyles } from './useToggleStyles';

interface IToggleProps extends ButtonProps {
  isActive?: boolean;
  className?: string;
}

// eslint-disable-next-line react/display-name
export const Toggle = forwardRef<HTMLButtonElement, IToggleProps>(
  ({ className, isActive, ...props }, ref) => {
    const { classes, cx } = useToggleStyles();
    return (
      <IconButton
        className={cx(classes.root, isActive && classes.active, className)}
        ref={ref}
        variant="text"
        aria-label="open/close"
        color="primary"
        {...props}
      >
        <i className={cx(classes.line, classes.lineTop)} />
        <i className={cx(classes.line, classes.lineMiddle)} />
        <i className={cx(classes.line, classes.lineBottom)} />
      </IconButton>
    );
  },
);
