import React from 'react';
import { Box, BoxProps } from '@mui/material';

import { Spinner } from '../Spinner';
import { useQueryLoadingStyles } from './useQueryLoadingStyles';

interface IQueryLoadingProps {
  className?: string;
  size?: number;
}

interface IQueryLoadingCenteredProps extends BoxProps {
  size?: number;
}

export function QueryLoading({ size, className }: IQueryLoadingProps) {
  return <Spinner size={size} className={className} />;
}

export function QueryLoadingAbsolute() {
  return <Spinner centered />;
}

export function QueryLoadingCentered({
  size = 40,
  ...props
}: IQueryLoadingCenteredProps) {
  const { classes } = useQueryLoadingStyles();

  return (
    <Box
      display="flex"
      justifyContent="center"
      className={classes.root}
      {...props}
    >
      <Spinner size={size} />
    </Box>
  );
}
