import { makeStyles } from 'tss-react/mui';

import { FONTS } from '../../../../../themes/const';

export const useNewValidatorFormStyles = makeStyles()(theme => ({
  root: {
    position: 'relative',
    padding: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(6),
      paddingRight: theme.spacing(4),
      paddingLeft: theme.spacing(4),
    },
  },
  closeBtn: {
    position: 'absolute',
    right: theme.spacing(5),
    top: theme.spacing(5),
  },
  title: {
    fontFamily: FONTS.bold,
    fontWeight: 700,
    fontSize: 34,
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      fontSize: 20,
    },
  },
  input: {},
}));
