import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Card, Container } from '@mui/material';

import { useCurrentNetwork } from '../common/hooks/useCurrentNetwork';
import { HomeRoutesConfig } from '../Home/HomeRoutes';
import { t } from '../i18n/utils/intl';
import { Button } from '../uiKit/Button';
import { usePageNotFoundStyles } from './usePageNotFoundStyles';

export function PageNotFound() {
  const { classes } = usePageNotFoundStyles();

  const { currentNetwork: network } = useCurrentNetwork();

  return (
    <Box className={classes.wrapper}>
      <Container>
        <Card className={classes.card}>
          <div className={classes.title}>{t('page-not-found.title')}</div>
          <div className={classes.hint}>{t('page-not-found.hint')}</div>

          <Button
            component={Link}
            to={HomeRoutesConfig.Home.generatePath(network)}
          >
            {t('page-not-found.btn')}
          </Button>
        </Card>
      </Container>
    </Box>
  );
}
