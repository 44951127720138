import { makeStyles } from 'tss-react/mui';

export const useLogoStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',
    height: '100%',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    gap: theme.spacing(2),
  },
  img: {
    width: 130,
    maxWidth: '100%',
    height: 'auto',
    color: theme.palette.primary.dark,
    [theme.breakpoints.down('sm')]: {
      width: 110,
    },
  },
}));
