import { makeStyles } from 'tss-react/mui';

export const useHeaderNavigationStyles = makeStyles()(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-end',
    gap: theme.spacing(4),
    marginRight: theme.spacing(-2.5),
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'stretch',
      justifyContent: 'flex-start',
      width: '100%',
      height: '100%',
      gap: theme.spacing(1),
      margin: 0,
    },
  },
}));
