import { makeStyles } from 'tss-react/mui';

export const useUiTagsStyles = makeStyles()(() => ({
  list: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    gap: 6,
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
}));
