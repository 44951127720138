import { useState } from 'react';
import { Box } from '@mui/material';

import { useGetChainDataQuery } from '../../../../../../../Bas/actions/bas';
import { QueryEmpty } from '../../../../../../../common/components/QueryEmpty';
import { QueryLoadingCentered } from '../../../../../../../common/components/QueryLoading';
import { ETH_EXPONENT } from '../../../../../../../common/const';
import { t } from '../../../../../../../i18n/utils/intl';
import { RestakeAll } from '../../../../../../../Staking/components/RestakeAll';
import { useStakingStatsStyles } from './useStakingStatsStyles';

export function StakingStats() {
  const { classes, cx } = useStakingStatsStyles();

  const { data: chainData, isLoading } = useGetChainDataQuery();

  const [showRestakeAll, setShowRestakeAll] = useState(false);
  const [showClaimAll, setShowClaimAll] = useState(false);

  const handleRestakeAllClick = () => {
    setShowRestakeAll(true);
  };

  const handleRestakeAllClose = () => {
    setShowRestakeAll(false);
  };

  const handleClaimAllClick = () => {
    setShowClaimAll(true);
  };

  const handleClaimAllClose = () => {
    setShowClaimAll(false);
  };

  if (isLoading) {
    return <QueryLoadingCentered />;
  }

  if (!chainData) {
    return <QueryEmpty />;
  }

  return (
    <>
      <Box className={classes.root}>
        <Box className={cx(classes.statItem, classes.statFirst)}>
          <Box className={classes.statTop}>{t('dashboard.total-staked')}</Box>
          <Box className={classes.statBottom}>
            <Box>
              <Box className={classes.statValue}>
                {t('number.styled', {
                  value: chainData.userTotalDelegation.dividedBy(ETH_EXPONENT),
                })}
              </Box>
              {/* <Box className={classes.statHint}>
                {t('number.price', { value: data.totalStakedUSD })}
              </Box> */}
            </Box>
          </Box>
        </Box>
        {/* <Box className={cx(classes.statItem, classes.statSecond)}>
          <Box className={classes.statTop}>{t('dashboard.total-rewards')}</Box>
          <Box className={classes.statBottom}>
            <Box>
              <Box className={classes.statValue}>
                {t('number.styled', { value: 18 })}
              </Box>
               <Box className={classes.statHint}>
                {t('number.price', { value: data.totalRewardsUSD })}
              </Box> 
            </Box>
            <Button onClick={handleRestakeAllClick}>
              {t('common.restake-all')}
            </Button>
          </Box>
        </Box> */}
        <Box className={cx(classes.statItem, classes.statSecond)}>
          <Box className={classes.statTop}>
            {t('dashboard.claimable-rewards')}
          </Box>
          <Box className={classes.statBottom}>
            <Box>
              <Box className={classes.statValue}>
                {t('number.styled', {
                  value: chainData.userTotalRewards.dividedBy(ETH_EXPONENT),
                })}
              </Box>
              {/* <Box className={classes.statHint}>
                {t('number.price', { value: data.claimableRewardsUSD })}
              </Box> */}
            </Box>
            {/* <Button onClick={handleClaimAllClick}>
              {t('common.claim-all')}
            </Button> */}
          </Box>
        </Box>
      </Box>
      {showRestakeAll && <RestakeAll onClose={handleRestakeAllClose} />}
    </>
  );
}
